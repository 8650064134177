import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit,Directive, HostListener,ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchasingService } from '../../services/purchasing.service';
import { VendorService } from '../../services/vendor.service';
import { Location ,DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Product } from '../../classes/orders';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-vendor-view',
	templateUrl: './vendor-view.component.html',
	styleUrls: ['./vendor-view.component.scss'],
	animations: [
	  trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
	  ]),
	  trigger('grow', [ // Note the trigger name
			transition(':enter', [
		  // :enter is alias to 'void => *'
		  style({ height: '0',width: '0' }),
		  animate(200, style({ height: '*' , width: '*' })),
		  animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
		  // :leave is alias to '* => void'
		  animate(100, style({ height: 0, width: 0 })),
		  animate('100ms', style({ opacity: 0 }))
			])
	  ]),
	  trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
	  ]),
	]
})
export class VendorViewComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	supplierid: any = [];
	vendor_data: any = [];
	closeResult: any;

	flip_button_txt = 'Transactions';
	flip = 'inactive';

	invoicing = false;
	vil = false;

	editing_vendor = false;
	editing_vendor_account = false;
	editing_vendor_addon = false;
	editing_contacts = false;
	editing_notes = false;
	editing_settings = false;

	viewing_transactions = false;
	viewing_openpos = false;
	color: any = 'blue';
	activeIndex = 0;
	pos = [];
	rgas = [];

	constructor(private route: ActivatedRoute,private purchasingService: PurchasingService, private vendorService: VendorService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService,private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.globalSearchService.mesagesallowed.next(true);
		this.route.params.subscribe(params => {
			const supplierid = params['id'];
			if(params['id'] != '') {
				this.loadVendor(supplierid)
			}
		});
	}

	updateActiveIndex(index: number) {
		this.activeIndex = index
	}

	selectItemLookup(event: number) {
		this.activeIndex = 2
	}

	selectRgaLookup(event: number) {
		this.activeIndex = 5
	}

	loadVendor(event: any) {
		this.vendorService.getVendor(event).subscribe((results:any) => {
			this.vendor_data = results;
		});
	}

	toggleVil() {
		this.vil = (this.vil) ? false : true;
	}

	toggleVendorEdit(input: any) {
		if(input != '') {
			this.editing_vendor = input;
		} else {
			this.editing_vendor = (this.editing_vendor) ? false : true;
		}
	}

	toggleVendorAddOn(input: any) {
		if(input != '') {
			this.editing_vendor_addon = input;
		} else {
			this.editing_vendor_addon = (this.editing_vendor_addon) ? false : true;
		}
	}

	toggleVendorSettings(input: any) {
		if(input != '') {
			this.editing_settings = input;
		} else {
			this.editing_settings = (this.editing_settings) ? false : true;
		}
	}

	toggleVendorNotes(input: any) {
		if(input != '') {
			this.editing_notes = input;
		} else {
			this.editing_notes = (this.editing_notes) ? false : true;
		}
	}

	toggleVendorEditAccount(input: any) {
		if(input != '') {
			this.editing_vendor_account = input;
		} else {
			this.editing_vendor_account = (this.editing_vendor_account) ? false : true;
		}
	}

	toggleContactEdit(input: any) {

		if(input != '') {
			this.editing_contacts = input;
		} else {
			this.editing_contacts = (this.editing_contacts) ? false : true;
		}
	}


	back(): void {
		this.location.back()
	}

	updateVendor(input: any) {
		this.vendor_data = input;
	}

	openModal(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	toggleTransactions(intype: any) {
		this.viewing_transactions = (this.viewing_transactions) ? false : true;
		this.flip = (this.flip == 'active') ? 'inactive' : 'active'
		this.flip_button_txt = (this.flip == 'active') ? 'Vendor Info' : 'Transactions'

	}

	toggleOpenPos(intype: any) {
		this.viewing_transactions = false;

		this.flip = (this.flip == 'active') ? 'inactive' : 'active'
		this.viewing_openpos = (this.viewing_openpos) ? false : true;
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		  return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		  return 'by clicking on a backdrop';
		} else {
		  return `with: ${reason}`;
		}
	}

	updateRgaData(event: any) {
		this.rgas = event.pos;
	}
	updatePoData(event:any) {
		this.pos = event.pos;
	}

	vendorSelected(row: any) {
		this.router.navigate(['./vendors/'+row.supplierid]);
	}

	ngAfterViewInit() {

	}
}

import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PaymentsService } from '../../services/payments.service';
import { ReportsService } from '../../services/reports.service';
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-dri',
	templateUrl: './dri.component.html',
	styleUrls: ['./dri.component.scss']
})
export class DriComponent implements OnInit {

  @ViewChild('print_table') printtable: ElementRef;
  color: any = 'blue';
  running: any = false;
  filename = 'dri';
  title = 'DRI - Register Inquiry';
  payments: any = [];
  totals: any = [];
  today = new Date();
  datefrom = new UntypedFormControl(this.today);
  branch: any = new UntypedFormControl('');
  loccode: any = '';
  branches: any = [];
  total_trans: any = 0;
  config: any = [];
  total_pay: any = 0;
  user: any = [];
  
  
  constructor(private reportsService: ReportsService, private paymentsService: PaymentsService, private printService: PrintService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder) { 
  
  	this.color = this.globalSearchService.getColor();
  
  }

  ngOnInit(): void {
  
  	this.globalSearchService.user.subscribe( result => {
  		this.user = result;
  		this.branch.value = this.user.user.defaultlocation.loccode;
  	});
  	this.reportsService.getBranches().subscribe((results: any) => {
  		this.branches = results;
  	});
  	this.globalSearchService.configsubscription.subscribe(r => {
  		this.config = r;
  	});
  }

  loadData(){
  	this.running = true;
  	const data = { from: this.datefrom.value, to: this.datefrom.value , branch: this.branch.value }
  	this.paymentsService.getDRI(data).subscribe( (results: any) => {
  		this.running = false;
  		this.payments = results;
  		this.totals = results.totals;
  		this.total_trans = results.transactions.reduce(function(accumulator: number, items: any) {
  			return accumulator + parseFloat(items.total);
  		}, 0);

  		this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
  			return accumulator + parseFloat(items.amount);
  		}, 0);

  	})
  }
  back(){
  
  }
  exportPdf() {

  	const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
  	const data = {
  		content: encoded,
  		filename: this.filename,
  	}

  	this.printService.pdf(data).subscribe((result: any) => {
  		this.globalSearchService.downloadPdf(result.content, data.filename);
  	});
  }
  exportXls() {
  	const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
  	const data = {
  		content: encoded,
  		filename: this.filename,
  	}

  	this.printService.xls(data).subscribe((result: any) => {
  		this.globalSearchService.downloadXls(result.content, data.filename);
  	});
  }
	
  updateDayPayMents(event: any){
  	this.today = event;
  }
}

import { Component, OnDestroy, SimpleChanges, OnChanges ,OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { JsonPipe, ViewportScroller } from '@angular/common';

import { Location } from '@angular/common'
import { Subject, Observable, Subscription } from 'rxjs';

import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { CreditService } from '../../services/credit.service';
import { DispatchService } from '../../services/dispatch.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { parseJSON } from 'date-fns';
import { CustomerService } from 'app/services/customer.service';
import { MatTableDataSource } from '@angular/material/table';

declare const google: any;

@Component({
	selector: 'app-dispatch-deliverysheet',
	templateUrl: './dispatch-deliverysheet.component.html',
	styleUrls: ['./dispatch-deliverysheet.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('300ms ease-in')),
			transition('active => inactive', animate('300ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class DispatchDeliverysheetComponent implements OnInit, OnDestroy {
	@ViewChild('orderdetails') orderdetailsref: ElementRef;
	@ViewChild('emailrma') emailrmaRef: ElementRef;

	@Input() selectedId: any = false;
	@Input() isdispatch: any = false;
	@Input() reloaddispatch: any = false;

	transfertruck = new UntypedFormControl('')

	selectedorder: any = false;
	loadingrun: any = false;
	loadingdata: any = false;
	active_dispatches: any = false;
	active_allpoints: any = false;
	filtereditems: any = [];
	listData: any = [];
	customer:any = false;
	dispatches:any =[];
	allpoints:any =[];

	total_capacity = 0;
	total_order_volume = 0;
	total_items = 0;
	trucks: any = [];
	alltrucks: any = [];
	user: any = [];
	modal_ref : any = false;
	credititing : any = false;
	private eventsSubscription: Subscription;
	@Input() events: Observable<void>;

	//add on for rmas
	@Input() flip: any = 'inactive';
	credit: any = false;
	overcredit: any = false;
	allowed_credit: any = true;
	overcredit_override: any = true;
	editing_order: any = false;
	order_details: any = false;
	proceed: any = true;
	total_cart_qty = 0;
	cart_totals: any = [];
	cart_items: any = [];

	fetchingTotals: any = false;
	fetchingCart : any = false;
	purchase_items: any = [];
	customerdata: any = [];
	freight_charge: any = 0.00;
	orderDetails: any = false;
	config: any = false;
	orderResults: any = false;
	customer_form: any = false;
	addNewContact = false;
	rmaEmail = new UntypedFormControl('')
	rmaNameAdd = new UntypedFormControl('')
	rmaEmailAdd = new UntypedFormControl('')
	saveContactOpt = new UntypedFormControl(true)
	review = false;
	displayedColumns: any = ['stockid', 'qty', 'price', 'EXT'];

	credit_types = [
		{ label:'Return To Stock', value:'return' },
		{ label:'Goods Written Off', value:'writeoff' },
		{ label:'Reverse Overcharge', value:'reverse' },
	];
	reviewData: MatTableDataSource<unknown>;


	constructor(private customerService: CustomerService, public creditService: CreditService, private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {


		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
			this.omsService.setupSocketConnection(this.user.user.userid);
		});
	}

	ngOnInit(): void {

		this.eventsSubscription = this.events.subscribe(() => this.loadData());

		this.dispatchService.getTrucks().subscribe((result:any) => {

			if(result.length == 1){
				this.selectedId = result[0];
				this.total_capacity = result[0].capacity
			} else {
				this.total_capacity = result[0].capacity
			}

			this.trucks = result;
			this.alltrucks = result

 			this.route.params.subscribe(params => {
				if(params['id'] && params['id'] !='') {

					const truck = this.trucks.filter(t => {
						return t.id == params['id']
					})[0];

					this.selectedId = truck.id;
					this.loadData();

				} else {
					this.loadData()
				}
			});
		});

		this.omsService.subscribeToOrderPicks((err, data) => {
			switch (data.type) {
			case 'pick':
				//this.loadData();
				break;
			}
		})

		this.omsService.subscribeToCards((err, data) => {

			switch (data.type) {
			case 'pick':
				this.loadData();
				break;
			case 'buildingroute':
				this.loadData();
				break;
			case 'dispatch':
				this.loadData();
				break;
			}
		})


	}


	toggleCredit() {
		this.credititing =  (this.credititing) ? false : true;
		if(this.credititing) {
			this.customer =false;
 			this.creditService.getCustomer(this.selectedorder.customerno, this.selectedorder.branchcode).subscribe((results: any) => {
			 	this.customer = results;
				 this.updateCart()
 			});
		} else {
			this.customer =false;
		}
	}

	updateDebtorContacts(){
		const data = {
			contacts:[{
				email: this.rmaEmailAdd.value,
				role: ['CREDIT/RMA'],
				name: this.rmaNameAdd.value.toLowerCase().split(' ').map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' '),
				debtorno: this.customer.debtorno
			}]
		}
		if(this.rmaEmail.value != ''){
			(data['contacts']).push({ email: this.rmaEmail.value, debtorno: this.customer.debtorno, role:['CREDIT/RMA'], name: '' })
		}


		this.customerService.updateDebtorContacts(JSON.stringify(data)).subscribe((res) => {
			this.creditService.getCustomer(this.selectedorder.customerno, this.selectedorder.branchcode).subscribe((results: any) => {
				this.customer = results;
				this.addNewContact = false;
			});
		})
	}

	closeModalById(id: string, val: boolean){
		this.proceed = val;
		this.rmaEmail.setValue('');
		document.getElementById(id).click();
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
	}

	removeFromDispatch(dispatch: any) {
		this.dispatchService.removeDispatch(dispatch.did).subscribe(results => {
			this.loadData();
		})
	}


	cancel(dispatch: any) {
		this.dispatchService.cancelDispatch(dispatch).subscribe(results => {
			this.modalService.dismissAll();
			this.loadData();
		})
	}

	deliverRun(truck: any) {
		const proceed = confirm('Mark All As Delivered?');
		if (proceed) {
			this.dispatchService.deliverRun(truck).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	clearRun(truck: any) {
		const proceed = confirm('Clear This Run?');
		if (proceed) {
			this.dispatchService.clearRun(truck).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	dropAtHome(dispatch: any) {
		this.dispatchService.returnDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	delivered(dispatch: any) {
		this.dispatchService.deliverDispatch(dispatch).subscribe(results => {
			this.loadData();
			if(!this.isdispatch) {
				this.modalService.dismissAll();
			} else {
				//this.modalService.dismissAll();
				//this.activeModal.close();
				this.globalSearchService.showNotification('updated', 'success', 'top', 'center')
			}
		})
	}

	altLocation() {
		if (this.transfertruck.value) {
			this.dispatchService.transferDispatch(this.selectedorder.did, this.transfertruck.value).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	viewOrder(order: any) {
		this.credititing =false;
		this.selectedorder = order
		this.modal_ref = this.modalService.open(this.orderdetailsref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});
	}

	loadData() {

		if(this.loadingrun) {
			this.loadingrun.unsubscribe();
		}

		let loadrun = this.selectedId.id;

		if (typeof this.selectedId === 'string' || !loadrun) {
			loadrun = this.selectedId;
		}
		this.loadingrun =  this.dispatchService.getActiveRun(loadrun).subscribe((results: any) => {
			this.listData = results.dispatched;
			this.active_dispatches = results.dispatched;
			this.active_allpoints = results.allpoints;
		});
		const arr = [];
		arr.includes
	}

	ngOnChanges(changes: any) {
		if(changes.reloaddispatch) {

			if(changes.reloaddispatch.currentValue == true ) {
				this.loadData();
			}
		}
	}

	identify(index, item) {
		return item.did;
	}

	//ADD NO FOR CREDIT ITEM SEARCH ----

	addItems(items: any) {
		const allow = true;
		items.forEach((item, index) => {

			const item_cart_qty = this.cart_items.reduce(function(accumulator: number, i: any) {
				let counter = 0;
				if(i.stockid ==item.stockid) {
					counter = parseFloat(i.quantity);
				}
				return accumulator + counter;
			}, 0);

			if(item_cart_qty >= item.max_credit) {
				alert('Maximum Qty Credit '+ item.max_credit+' Allowed Reached, call main office.');
				item.quantity = 0;
			}
		});

		if(allow) {
			this.creditService.addMultipleToOrder(items, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {

				if (results.success) {
					this.cart_items = results.cart;


					//this.updateTotals();

					const components = [];
					items.forEach((item, index) => {


						//max_credit
						//reset qtys and show notification
						if (parseFloat(item.quantity) != 0) {
							const color = (item.isnegative) ? 'warn' : 'success';

							this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Added', color, 'bottom', 'left');
						}


					});

					this.updateTotals();

				} else {
					this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
				}
			});
		}
	}

	updateTotals() {

		if(this.fetchingTotals) {
			this.fetchingTotals.unsubscribe();
		}

		this.fetchingTotals = this.creditService.getTotals(this.customer.debtorno, this.customer.branchcode, this.freight_charge).subscribe((results: any) => {
		// this.customer_form.get('reference').setValue(event.header.customerref);
		// this.customer_form.get('brname').setValue(event.header.deliverto);
		// this.customer_form.get('braddress1').setValue(event.header.deladd1);
		// this.customer_form.get('braddress2').setValue(event.header.deladd2);
		// this.customer_form.get('braddress3').setValue(event.header.deladd3);
		// this.customer_form.get('braddress4').setValue(event.header.deladd4);
		// this.customer_form.get('braddress5').setValue(event.header.deladd5);
		// this.customer_form.get('braddress6').setValue(event.header.deladd6);

			this.cart_totals = results;
			this.total_cart_qty = this.cart_items.reduce(function(accumulator: number, items: any) {
				let counter = 0;
				if(items.mbflag != 'F') {
					counter = parseFloat(items.quantity);
				}
				return accumulator + counter;
			}, 0);

			if(!this.total_cart_qty) {
				this.flip = 'inactive';
			}

			this.checkCreditLimit()
		});



	}

	checkCreditLimit() {
		if (!this.allowed_credit) {
			this.proceed = true;
			this.overcredit = false;
			const subtotal = this.cart_totals.filter(t => {
				return t.code == 'sub_total'
			})[0]

			if (parseFloat(subtotal.text) > this.customer.credit_avail) {
				this.proceed = false;
				this.overcredit = true;
				if (this.overcredit_override == '') {
					this.ordersService.flagCart(this.customer.debtorno, this.customer.branchcode).subscribe((result: any) => {
						this.overcredit_override = result
					})
				}
			}
		}
	}

	getTotal() {

		const total = this.cart_totals.filter(t => {
			return t.code=='total';
		})[0]

		let value = 0.00;
		if(total) {
			value = parseFloat(parseFloat(total.text).toFixed(2))
		}

		return value;

	}

	stopProp(event: any): void {
		event.stopPropagation();
	}

	removeFromOrder(cart_id) {
		this.creditService.removeFromOrder(cart_id, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.cart_items = results;
			this.globalSearchService.showNotification('Item Removed', 'danger', 'bottom', 'left');
			this.updateTotals();
			this.sendCartUpdate();
		});
	}

	updatePrice(event: any, item: any) {
		const index = this.cart_items.indexOf(item);
		this.cart_items[index].price = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateQuantity(event: any, item: any) {
		const index = this.cart_items.indexOf(item);
		this.cart_items[index].quantity = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);
	}

	updateDiscount(event: any, item: any) {
		const index = this.cart_items.indexOf(item);

		if(parseFloat(event.target.value) < 100) {
		} else {
			event.target.value = 100;
		}

		this.cart_items[index].discount = event.target.value;
		this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
		this.updateCartItem(this.cart_items[index]);

	}

	updateNote(event: any, item: any) {
		const index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item: any) {

		const data = {
			customer: this.customer.debtorno,
			branch: this.customer.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			option: item.option,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			stockid: item.stockid,
			coupon_used: item.coupon_used,
			editing_order: this.editing_order,
			order_details: this.order_details,
		};



		this.creditService.updateOrder(data).subscribe((results: any) => {
			this.updateTotals();
			//this.updateCart();
			//item.cart_id
			this.updateChildren();

		});
	}

	updateChildren() {
		this.cart_items.forEach( (item: any) => {
			if(item.parent_id) {
				const parent = this.cart_items.filter(i => i.cart_id == item.parent_id)[0]
				//will not work for pers
				item.quantity = parent.quantity;
			}
		})
	}

	sendCartUpdate() {

		if (this.user) {
			const data = [{ customer: this.customer.debtorno, user: this.user }]
			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	scanContacts(){
		if(this.customer['contacts'].length){
			(this.customer.contacts).forEach(cont => {
				if((cont.roles).includes('CREDIT/RMA')){
					this.rmaEmail.setValue(cont.email);
				}
			});
		}
	}

	placeOrder() {

		//this.checkCredit();
		//this.globalSearchService.getFormValidationErrors(this.customer_form);
		//this.customer_form.markAllAsTouched();
		this.proceed = true;
		this.allowed_credit = true;
		this.reviewData = new MatTableDataSource(this.cart_items);

		/*
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.item_details = false;
			});
		}
		*/
		//customer form is emitted in



		this.modal_ref = this.modalService.open(this.emailrmaRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false, keyboard: false, backdrop: 'static' }).result.then((result) => {}, (reason) => {

			if (this.proceed) {

				if (!this.editing_order) {


					this.customer.credit_type = 'return';
					this.customer.print = true;
					this.customer.validated = false;
					this.customer.enteredby = this.user.user.userid;
					this.customer.takenby = this.user.user.userid;

					if(this.user.user) {
						this.customer.defaultlocation = this.user.user.defaultlocation.loccode;
					}


					this.creditService.createOrder(this.customer, this.cart_items, this.cart_totals, this.user).subscribe((results: any) => {

						this.orderResults = results;
						this.flip = 'inactive';
						this.orderDetails = results;
						this.editing_order = false;
						this.creditService.clearOrder(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
							this.updateTotals();
							this.cart_items = [];
							this.total_cart_qty = 0;
							this.credititing = false;
							this.customer = false;
							this.globalSearchService.showNotification('RMA Created', 'success', 'bottom', 'right');
						});
					});
				} else {
					this.creditService.updateSalesOrder(this.customer_form.value, this.cart_items, this.cart_totals, this.order_details, this.user).subscribe((results: any) => {
						this.updateTotals();
						this.flip = 'inactive';
						this.cart_items = [];
						this.total_cart_qty = 0;
						this.credititing = false;
						this.customer = false;
						this.globalSearchService.showNotification('RMA Updated', 'success', 'bottom', 'right');
					});
				}
			}

		});


	}


	updateCart() {
		this.creditService.getCart(this.customer.debtorno, this.customer.branchcode).subscribe( (items: any) => {
			if(items.length) {
				this.flip ='active';
			}
			const hasorderno = items.map(item => { return (item.orderno != 0) ? item.orderno : false })[0];
			//todo rework this - doubles up on the calls
			if (hasorderno && !this.order_details.header) {
				this.creditService.loadRma(hasorderno).subscribe((results: any) => {
					this.editing_order = true;
					this.order_details = results;
					this.customer.editingorder = results.header
					this.order_details = results;
					this.cart_items = results.cart;
				});
			} else {
				this.cart_items = items;
			}

			this.updateTotals();
		});
	}

	removeHtmlTags(html: string): string {
		return html.replace(/<[^>]*>/g, '');
	}


}

<ng-template #ruleModal let-modal>
<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Rule</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="card">
		<div class="card-header text-center">
		</div>
		<div class="card-body">
		<form [formGroup]="ruleForm" class="m-0 p-0">
			<div class="row">
			<div class="col-3">
			<mat-form-field appearance="outline">
										<mat-label>Rule Type</mat-label>
										<mat-select formControlName="rule_type" (selectionChange)="typechange()">
											<mat-option *ngFor="let k of ruletypes | keyvalue" [value]="k.key">{{k.value}}</mat-option>
										</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3" *ngIf="editrule_type == 'ALL'">
			N/A
			</div>
			<div class="col-3" *ngIf="editrule_type == 'PRODLINE'">
			<mat-form-field appearance="outline">
										<mat-label>Product Line</mat-label>
										<mat-select formControlName="rule_value">
											<mat-option *ngFor="let k of prodlines" [value]="k.line_field">{{k.line_description}}</mat-option>
										</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3" *ngIf="editrule_type == 'CATEGORY'">
			<mat-form-field appearance="outline">
										<mat-label>Category</mat-label>
										<mat-select formControlName="rule_value">
											<mat-option *ngFor="let k of categories" [value]="k.categoryid">{{k.categorydescription}}</mat-option>
										</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3" *ngIf="editrule_type == 'CUSTTYPE'">
			<mat-form-field appearance="outline">
										<mat-label>Customer Type</mat-label>
										<mat-select formControlName="rule_value">
											<mat-option *ngFor="let k of custtypes" [value]="k.typeid">{{k.typename}}</mat-option>
										</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3" *ngIf="editrule_type == 'STOCKID'">
			<mat-form-field appearance="outline">
										<mat-label>Item Number</mat-label>
										<input matInput formControlName="rule_value" />
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
										<mat-label>Discount %</mat-label>
										<input matInput formControlName="discount_percent" />
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
										<mat-label>Priority</mat-label>
										<input matInput formControlName="rule_priority" />
			</mat-form-field>
			</div>
			</div>
		</form>
		</div>
		<div class="card-footer">
		<div class="float-right">
		<button class="btn btn-primary" material-stroked-button (click)="saveRule(currentrule)"><mat-icon>save</mat-icon> Save </button>
		</div>
		</div>
	</div>
</ng-template>
<ng-template #saleModal let-modal>
<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Sale</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="card">
		<div class="card-header text-center">
		</div>
		<div class="card-body">
		<form [formGroup]="saleForm" class="m-0 p-0">
			<div class="row">
			<div class="col-3">
			
			<mat-form-field appearance="outline">
										<mat-label>Sale Name</mat-label>
										<input matInput formControlName="salename" />
			</mat-form-field>
			</div>
			<div class="col-3">
									<mat-form-field appearance="outline">
										<mat-label>Sale Dates</mat-label>
										<mat-date-range-input [rangePicker]="picker">
											<input matStartDate formControlName="date_start" placeholder="Start date">
											<input matEndDate formControlName="date_end" placeholder="End date">
										</mat-date-range-input>
										<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
										<mat-label>Branches</mat-label>
										<mat-select formControlName="locations" multiple #branchFilter appSelectAll>
											<mat-option [value]="95"> Web Sales </mat-option>
											<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
										</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3">
				<mat-slide-toggle formControlName="enabled">Enabled</mat-slide-toggle>
			</div>
			</div>
		</form>
		</div>
		<div class="card-footer">
		<div class="float-right">
		<button class="btn btn-primary" material-stroked-button (click)="saveSale(currentsale)"><mat-icon>save</mat-icon> Save </button>
		</div>
		</div>
	</div>
</ng-template>
<ng-template #checkModal let-modal>
<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Price Check</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="card">
		<div class="card-header text-center">
		</div>
		<div class="card-body">
		<form [formGroup]="checkForm" class="m-0 p-0">
			<div class="row">
			<div class="col-3">
			
			<mat-form-field appearance="outline">
										<mat-label>Customer Number</mat-label>
										<input matInput formControlName="debtorno" />
			</mat-form-field>
			</div>
			<div class="col-3">
			
			<mat-form-field appearance="outline">
										<mat-label>Item Number</mat-label>
										<input matInput formControlName="stockid" />
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
										<mat-label>Location</mat-label>
										<mat-select formControlName="loccode">
											<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
										</mat-select>
			</mat-form-field>
			</div>
			</div>
		</form>
		</div>
		<div class="card-footer">
		<div class="float-right">
		<button class="btn btn-primary" material-stroked-button (click)="priceCheck()"><mat-icon>check</mat-icon> Check </button>
		</div>
		</div>
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title">
							<i class="material-icons">arrow_left</i> Sale Rule Builder	
							<button class="btn btn-primary float-right" material-stroked-button (click)="addNew()"><mat-icon>add</mat-icon> Add </button>
							<button class="btn btn-primary float-right" material-stroked-button (click)="pchk()"><mat-icon>search</mat-icon> Price Check </button>							
						</h4>					
					</div>
					<div class="card-body">
					<div class="row">
					<div class="col-3">
					</div>
					<div class="col-3">
					</div>
					
					</div>
					<div class="row">
					<div class="col-12">
					
					<p-accordion id="saleAcc" [(activeIndex)]="openAccordion">
					<p-accordionTab *ngFor="let sale of sales" header="{{sale.salename}}"> 
					<div class="row">
					<div class="col-sm-3 dark">
					Starting Date
					</div>
					<div class="col-sm-3 dark">
					Ending Date
					</div>
					<div class="col-sm-3 dark">
					Status
					</div>
					<div class="col-sm-3">
					 <button class='btn btn-sm' material-stroked-button (click)="editSale(sale.saleid)"><mat-icon>edit</mat-icon></button>
					 <button class='btn btn-sm btn-danger' material-stroked-button (click)="deleteSale(sale.saleid)"><mat-icon>delete</mat-icon></button>
					</div>
					</div>
					<div class="row">
					<div class="col-sm-3">
					{{ sale.date_start | date }}
					</div>
					<div class="col-sm-3">
					{{ sale.date_end | date }}
					</div>
					<div class="col-sm-3">
					{{ sale.enabled }}
					</div>
					</div>
					<div class="row">
					<div class="col-sm-3 dark">
					Locations:
					</div>
					<div class="col-sm-9">
					<div *ngFor="let locs of (sale.locations | keyvalue)" class="locbox">
					{{locs.key }} {{ locs.value }} 
					</div>
					</div>
					</div>
					<div class="row">
					<h4>Rules</h4>
					</div>
					<div class="row dark">
						<div class="col-3">
						Type
						</div>
						<div class="col-3">
						Value
						</div>
						<div class="col-2">
						Discount
						</div>
						<div class="col-2">
						Priority
						</div>
						<div class="col-2">
						<button class="btn btn-sm btn-secondary" material-stroked-button (click)="addRule(sale.saleid)"><mat-icon>add</mat-icon></button>
						</div>
					</div>
					<div class="row" *ngFor="let rule of sale.rules">
						<div class="col-3">
						{{ ruletypes[rule.rule_type] }}
						</div>
						<div class="col-3">
						{{ rule.rule_value_readable }}
						</div>
						<div class="col-2">
						{{ rule.discount_percent * 100 }} %
						</div>
						<div class="col-2">
						{{ rule.rule_priority }}
						</div>
						<div class="col-2">
						<button class="btn btn-sm" material-stroked-button (click)="editRule(sale.saleid, rule.ruleid)"><mat-icon>edit</mat-icon></button>
						<button class='btn btn-sm btn-danger' material-stroked-button (click)="deleteRule(rule.ruleid)"><mat-icon>delete</mat-icon></button>
						</div>						
					</div>
					</p-accordionTab>
					</p-accordion>
					</div>
					</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
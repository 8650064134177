<ng-template #attendees let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Attendees for {{ selected_event.name }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="table_wrapper">
			<table class="table table-sm table-striped">
			<thead><tr><th>Order ID</th><th>Customer</th><th>Amount</th><th>Seats</th><th>Date Added</th><th></th></tr></thead>
			<tbody>
			<ng-container *ngFor="let attend of selected_event.history; let i = index">
			<tr>
			<td>{{attend.order_id}}</td><td>{{attend.customer_id}}</td><td>{{attend.amount}}</td><td>{{attend.seats}}</td><td>{{attend.date_added}}</td><td><button class="btn btn-sm" (click)="toggleVis(i);"><mat-icon>visibility</mat-icon></button></td>
			</tr>
			<ng-container *ngFor="let detail of attend.details">
			<tr *ngIf="attend.show">
			<td colspan="2">{{ detail.registrant }} </td><td colspan="2"> {{detail.email}} </td><td colspan="2"> {{detail.phone}}</td>
			</tr>
			</ng-container>
			</ng-container>
			</tbody>
			</table>
		</div>
	</div>
</ng-template>
<ng-template #setupModal let-modal>
	<div class="modal-header">
		<h4 *ngIf="selected_event" class="modal-title" id="modal-title">{{ selected_event.name }}</h4>
		<h4 *ngIf="!selected_event" class="modal-title" id="modal-title">New Event</h4>
		<button type="button" mat-flat-button color="accent" (click)="saveEvent()"><mat-icon>save</mat-icon> Save Changes </button>&nbsp;
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">		
		<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" >
			<form [formGroup]="eventForm">
			<mat-tab label="Basic Info">
			<div class="container-fluid">
			<div class="row eduform">
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Name</mat-label>
						<input matInput formControlName="name" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Payment Code</mat-label>
						<input matInput formControlName="code" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>URL Alias</mat-label>
						<input matInput formControlName="alias" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			</div>
			<hr>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Start Date</mat-label>
			<input matInput formControlName="date_start" [matDatepicker]="datestart">
			<mat-datepicker-toggle matSuffix [for]="datestart"></mat-datepicker-toggle>
			<mat-datepicker #datestart></mat-datepicker>
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
			<mat-label>Start Time (HH)</mat-label>
			<mat-select formControlName="start_time_h">
			<mat-option *ngFor="let h of hours" [value]="h.value">{{h.disp}}</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
			<mat-label>Start Time (MM)</mat-label>
			<mat-select formControlName="start_time_m">
			<mat-option *ngFor="let m of minutes" [value]="m">{{m}}</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>End Date</mat-label>
			<input matInput formControlName="date_end" [matDatepicker]="dateend">
			<mat-datepicker-toggle matSuffix [for]="dateend"></mat-datepicker-toggle>
			<mat-datepicker #dateend></mat-datepicker>
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
			<mat-label>End Time (HH)</mat-label>
			<mat-select formControlName="end_time_h">
			<mat-option *ngFor="let h of hours" [value]="h.value">{{h.disp}}</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			<div class="col-3">
			<mat-form-field appearance="outline">
			<mat-label>End Time (MM)</mat-label>
			<mat-select formControlName="end_time_m">
			<mat-option *ngFor="let m of minutes" [value]="m">{{m}}</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			</div>
			<hr>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Total Seats</mat-label>
			<input matInput formControlName="uses_total">
			</mat-form-field>
			</div>
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Seats per Customer</mat-label>
			<input matInput formControlName="uses_customer">
			</mat-form-field>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Cost</mat-label>
			<input matInput formControlName="discount">
			</mat-form-field>
			</div>
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Customer Login Required</mat-label>
			<mat-select formControlName="logged">
			<mat-option value="0">No</mat-option>
			<mat-option value="1">Yes</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Region</mat-label>
			<mat-select formControlName="region">
			<mat-option value=""> </mat-option>
			<mat-option *ngFor="let r of eventregions;" value="r.id">{{r.name}}</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Open for Registration</mat-label>
			<mat-select formControlName="closed">
			<mat-option value="0">Yes</mat-option>
			<mat-option value="1">No</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Status</mat-label>
			<mat-select formControlName="status">
			<mat-option value="0">Disabled</mat-option>
			<mat-option value="1">Enabled</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			</div>
			</div>
			</mat-tab>
			<mat-tab label="More Details">
			<div class="container-fluid">
			<div class="row eduform">
			<div class="col-3">
			Image
			</div>
			<div class="col-3">
			<img [src]="selected_event.thumb">
			</div>
			<div class="col-6" style="height:250px">
			<ngx-dropzone [multiple]="false" (change)="onSelect(selected_event.events_id, $event)">
			<ngx-dropzone-label>
			<div class="col-12">
				DROP FILE HERE OR CLICK TO UPLOAD
			</div>
			</ngx-dropzone-label>
			</ngx-dropzone>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Locations</mat-label>
			<mat-select multiple="true" formControlName="locations">
			<mat-option value="0">None</mat-option>
			<mat-option *ngFor="let r of eventlocations;" [value]="r.events_locationid">{{r.location_name}}</mat-option>
			</mat-select>
			</mat-form-field>
			</div>
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Phone Number</mat-label>
			<input matInput formControlName="phone" mask="(000) 000-0000" [showMaskTyped]="true" class="margin-top">
			</mat-form-field>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Artist Name</mat-label>
			<input matInput formControlName="artist_name" class="margin-top">
			</mat-form-field>
			</div>
			<div class="col-6">
			<mat-form-field appearance="outline">
			<mat-label>Host</mat-label>
			<input matInput formControlName="host" class="margin-top">
			</mat-form-field>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Short Description</mat-label>
						<input matInput formControlName="short_description" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			</div>
			<div class="row eduform">
			<div class="col-12">
			Description		
			<angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
			</div>
			</div>
			</div>
			</mat-tab>
			</form>
			<mat-tab label="Recommended Products">
			<div class="container-fluid">
				<div class="row">
					<div class="col-2">
					<mat-form-field appearance="outline">
			<mat-label>Brands</mat-label>
			<mat-select multiple="true" [formControl]="brandCtrl">
			<mat-option *ngFor="let r of brands;" [value]="r.manufacturer_id">{{r.name}}</mat-option>
			</mat-select>
			</mat-form-field>			
					</div>
					<div class="col-2">
					<mat-form-field appearance="outline">
			<mat-label>Categories</mat-label>
			<mat-select multiple="true" [formControl]="categoryCtrl">
			<mat-option *ngFor="let r of categories;" [value]="r.categoryid">{{r.categorydescription}}</mat-option>
			</mat-select>
			</mat-form-field>
					</div>
					<div class="col-2">
					<mat-form-field appearance="outline">
			<mat-label>Product Lines</mat-label>
			<mat-select multiple="true" [formControl]="prodCtrl">
			<mat-option *ngFor="let r of productlines;" [value]="r.line_field">{{r.line_description}}</mat-option>
			</mat-select>
			</mat-form-field>
					</div>
					<div class="col-3">
					<mat-form-field appearance="outline">
			<mat-label>Description</mat-label>
			<input matInput [formControl]="descCtrl" class="margin-top">
			</mat-form-field>
					</div>
					<div class="col-3" style="margin-top:17px;">
					<button mat-raised-button color="accent" (click)="getProducts()"><mat-icon>search</mat-icon> Search</button>
					</div>
				</div>
			<div class="row" *ngIf="picklist.length > 0">
			<div class="col-12">
			<table class="table table-striped">
			<thead><tr><th class="text-center" colspan="3">Search Results</th></tr><tr><th>Item Number</th><th>Description</th><th></th></tr></thead><tbody>
			<tr *ngFor="let p of picklist;">
				<td>{{p.stockid}}</td>
				<td>{{p.description}}</td>
				<td><button mat-stroked-button color="danger" (click)="excludeProduct(p)"><mat-icon>delete</mat-icon> Exclude</button></td>
			</tr>
			</tbody>
			</table>
			</div>
			<div class="col-6"></div><div class="col-6"><button class="btn btn-secondary" (click)="addProducts()"><mat-icon>add</mat-icon> Add Products</button></div>
			</div>
			<div class="row">
			<div class="col-12">
			<table class="table table-striped">
			<thead><tr><th class="text-center" colspan="3">Recommended Products</th></tr><tr><th>Item Number</th><th>Description</th><th></th></tr></thead><tbody>
			<tr *ngFor="let p of productlist;">
				<td>{{p.stockid}}</td>
				<td>{{p.description}}</td>
				<td><button mat-stroked-button color="danger" (click)="removeProduct(p)"><mat-icon>remove</mat-icon> Remove</button></td>
			</tr>
			</tbody>
			</table>
			</div>
			</div>
			</div>
			</mat-tab>
		</mat-tab-group>
		
	</div>
</ng-template>
<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Education Events</h4>
					<p class="card-category text-right"></p>
				</div>
				<div class="card-body">
				<button mat-stroked-button (click)="addEvent()" >
												<mat-icon>add</mat-icon>
												<span>Add Event</span>
											</button>
				<div class="table-wrapper"  *ngIf="!loading && eventlist.length">
									<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
									  <table class="table table-sm table-striped">
										<thead class="thead-dark">
										  <tr>
											<th class="noprint"></th>
											<th >Event Name</th>
											<th >Views</th>
											<th class="text-center">Host</th>
											<th class="text-center">Registered</th>

											<th class="text-center">Seats</th>
											<th class="text-center"> Location </th>
											<th class="text-center"> City </th>
											<th class="text-center"> Payment Code </th>
											<th class="text-center"> Cost / Seat </th>
											<th class="text-center">Date Start</th>
											<th class="text-center">Date End</th>
											<th class="text-center">Status</th>
											<th class="text-center">Open / Closed</th>
										  </tr>
										</thead>
										<tbody>
										<tr class="datarow" *ngFor="let item of dataObs | async;let i = index">
											<td class="noprint">
											<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
											<mat-icon>more_vert</mat-icon>
											</button>
											<mat-menu #menu="matMenu">
											
											<button mat-menu-item (click)="editEvent(item.events_id)" >
												<mat-icon>edit</mat-icon>
												<span>Edit Event</span>
											</button>
											<button mat-menu-item (click)="deleteEvent(item.events_id)" *ngIf="item.registered == 0">
												<mat-icon>remove</mat-icon>
												<span>Remove Event</span>
											</button>
											<mat-divider *ngIf="item.registered > 0"></mat-divider>
											<button mat-menu-item *ngIf="item.registered > 0" (click)="viewAttendees(item.events_id)">
												<mat-icon>person</mat-icon>
												<span>View Attendees</span>
											</button>
											<mat-divider *ngIf="item.waitlisted > 0"></mat-divider>
											<button mat-menu-item *ngIf="item.waitlisted > 0" (click)="viewWaitlisted(item.events_id)">
												<mat-icon>front_hand</mat-icon>
												<span>View Waitlisted</span>
											</button>
										</mat-menu>
											
											</td>
											<td class="text-center ">
												{{ item.name }}.
											</td>
											<td>{{ item.views }}</td>
											<td>{{ item.host }}</td>
											<td class="text-center">{{ item.registered }}</td>
											<td class="text-center">{{ item.total_remaining }} / {{ item.total_seats }}</td>
											<td class="text-center">{{ item.location_name }}</td>
											<td class="text-center">{{ item.city }}</td>
											<td class="text-center">{{ item.code }}</td>
											<td class="text-center">{{ item.discount }}</td>
											<td class="text-center">{{ item.date_start }}</td>
											<td class="text-center">{{ item.date_end }}</td>
											<td class="text-center">{{ item.status }}</td>
											<td class="text-center" [ngClass]="{'red': item.closed == 1, 'green' : item.closed != 1 }">{{ item.closed == 1 ? 'Closed' : 'Open' }}</td>
										  </tr>
										</tbody>
									  </table>
									</div>
									<div class="table" *ngIf="!loading && !eventlist.length">
										<h4 class="text-center"> No Results</h4>
									</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row  mt-4">
	<div class="col-md-4 mt-3">
		<h5 class="bold">Select Items To Move
			<mat-slide-toggle
				  class="example-margin"
				  color="accent"
				  [checked]="onhandonly"
				  (change)="updateItems($event)"
				  >
				QOH > 0
			  </mat-slide-toggle></h5>
		<mat-form-field appearance="outline">
			<mat-label>Search For Items To Move</mat-label>
			<input type="text"
				placeholder="Transfer To Bin"
				aria-label="Transfer To Bin"
				matInput
				[formControl]="itemSearchForm"
				>
				<button *ngIf="itemSearchForm.value != ''" matSuffix mat-icon-button aria-label="Clear" (click)="itemSearchForm.setValue('')">
					<mat-icon>close</mat-icon>
				</button>
				<mat-hint *ngIf="filtered_items.length"><b>ITEMS</b> ({{filtered_items.length}})</mat-hint>
		</mat-form-field>
		<mat-divider></mat-divider>
		<ng-container *ngIf="searching">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</ng-container>
		<mat-list dense class="bin-box" *ngIf="filtered_items.length">
			 <mat-list-item class="mat-list-item-bordered" *ngFor="let item of filtered_items" (click)="addSelectedItem(item)">
				 <p matLine><b>{{ item.stockid }}</b> {{ item.bin }} : ({{ item.qoh }})</p>
				 <p matLine >{{ item.description }} </p>
				 <span class="ml-auto mr-0"><small> <mat-icon color="warning" class="small bold">add</mat-icon></small></span>

			 </mat-list-item>
		 </mat-list>

		 <mat-action-list class="bin-box" dense *ngIf="!filtered_items.length && !searching">
			  <mat-list-item class="text-warning bold">Search For Items</mat-list-item>
		  </mat-action-list>

	</div>
	<div class="col-md-4 mt-3">
		<h5 class="bold">Selected Items ({{tobins.length}})</h5>
		<mat-form-field appearance="outline">
			<mat-label>Search Selected Items</mat-label>
			<input type="text"
				placeholder="Transfer To Bin"
				aria-label="Transfer To Bin"
				matInput
				[formControl]="selectedItemSearch"
			>
		</mat-form-field>
		<mat-divider></mat-divider>
		<mat-list dense class="bin-box" *ngIf="tobins.length">
			 <mat-list-item *ngFor="let item of tobins" class="mat-list-item-bordered" (click)="removeSelectedItem(item)">
				 <p matLine ><b>{{ item.stockid }}</b> {{ item.bin }}: ({{ item.qoh}})</p>
				 <p matLine >{{ item.description }} </p>

				 <span class="ml-auto mr-0"><small> <mat-icon color="accent" class="small bold">remove</mat-icon></small></span>

			 </mat-list-item>
		 </mat-list>

		 <mat-list class="bin-box" dense *ngIf="!tobins.length">
			 <mat-list-item class="text-warning bold">Select Items to Move</mat-list-item>
		 </mat-list>

	</div>
	<div class="col-md-4 mt-3">
		<h5 class="bold" [ngClass]="transferBin.valid ? '' : 'text-danger'">Transfer Bin {{ neworcurrent }}</h5>
		<mat-divider></mat-divider>
		<mat-form-field appearance="outline">
			<mat-label>Transfer To Bin</mat-label>
			<input type="text"
				placeholder="Transfer To Bin"
				aria-label="Transfer To Bin"
				[maxLength]="8"
				matInput
				required
				[formControl]="transferBin"
				[matAutocomplete]="transferbin"
				getOrderDetails>
			<mat-hint>{{ transferBin.value.length }} / 8</mat-hint>
			<mat-autocomplete autoActiveFirstOption #transferbin="matAutocomplete">
				<mat-option *ngFor="let bin of filteredOptions" [value]="bin.location">
					{{bin.location }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
		<mat-divider></mat-divider>

		<div class="col-12 notification text-right">
			<h6 class="mt-2 mb-2" *ngIf="!tobins.length || transferBin.value ==''">*Issues</h6>
			<mat-list dense class="bin-box" *ngIf="!tobins.length || transferBin.value ==''">
				<mat-list-item *ngIf="!tobins.length" class="mat-list-item-bordered text-danger bold"><mat-icon>error</mat-icon> Select Items To Move</mat-list-item>
				<mat-list-item *ngIf="transferBin.value ==''"  class="mat-list-item-bordered text-danger bold"><mat-icon>error</mat-icon> Choose/Create a Transfer Bin</mat-list-item>
			</mat-list>

			<h6 class="mt-2 mb-2">{{neworcurrent}}</h6>
			<div *ngIf="!sending">
				<button *ngIf="tobins.length && transferBin.value !='' " mat-raised-button color="warn" class="mt-3 mb-3"(click)="moveItems()">Move Items</button>
			</div>
			<div *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
			<div *ngIf="affected">
				<h6 class="mt-2 mb-2">Affected</h6>
				<div class="row" *ngFor="let item of affected">
					<div class="col-12"> {{ item.stockid }} </div>
				</div>
			</div>

		</div>
	</div>

</div>

<!-- modal -->
<ng-template #detailsModal let-modal>

		<div class="modal-header">
			<h3 *ngIf="activetire"><b>{{activetire.stockid}}</b> Information</h3>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
		</div>


		<div class="modal-body">
			<div class="row">
				<table class='modal-table-row' >
					<tr style="background: #ebebeb;">
						<th>Stock ID</th>
						<th>Size</th>
						<th>Load Index</th>
						<th>Speed rating</th>
						<th>Manufacturer</th>
						<th>MFG PART#</th>
					</tr>
					<tr style="background: rgb(247, 247, 247);">
						<td>{{activetire.stockid}}</td>
						<td>{{activetire.size}}</td>
						<td>{{activetire.loadindex}}</td>
						<td>{{activetire.speedrating}}</td>
						<td>{{activetire.mfg}}</td>
						<td>{{activetire.mfgpartno}}</td>
					</tr>
				</table>
			</div>



			<div class="api-block">
				<div class='flex-column w30'>
					<div class="api-box-header" style=" background: #b1cce6; ">ATD</div>
					<div class="api-box" style="background: #6da9e2;">
						<div class="table table-responsive text-center searchlist" *ngIf="atdsearching">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div *ngIf="atdresults" style="display: grid; width: 100%;">
							<ng-container *ngFor="let spec of atdresults">
								<span (click)="updateattribute(spec.key, spec.value)" style="border-bottom: 1px solid #5e92c2;"  class="attr_hover">
									<div><b>{{spec.key}}</b> : {{spec.value}}</div>
								</span>
							</ng-container>
						</div>
					</div>
					<a mat-button (click)="populateAttributes('atd')">Populate ATD</a>
				</div>


				<div class='flex-column w30'>
					<div class="api-box-header" style="background: #d0f0ab;">TIRE LIBARY</div>
					<div class="api-box" style="background: #abe26d; ">
						<div class="table table-responsive text-center searchlist" *ngIf="tlibsearching">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div *ngIf="atdresults" style="display: grid; width: 100%;">
							<ng-container *ngFor="let spec of tlibresults">
								<span (click)="updateattribute(spec.key, spec.value)" style="border-bottom: 1px solid #96c561;"  class="attr_hover">
									<div><b>{{spec.key}}</b> : {{spec.value}}</div>
								</span>
							</ng-container>
						</div>
					</div>
					<a mat-button (click)="populateAttributes('tlib')">Populate Tire Library</a>
				</div>


				<div class='flex-column w30'>
					<div class="api-box-header" style="background: #e9db9f;">FKL</div>
					<div class="api-box" style="background: #e2cc6d;">
						<div class="table table-responsive text-center searchlist" *ngIf="mfisearching">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div *ngIf="atdresults" style="display: grid; width: 100%;">
							<ng-container *ngFor="let spec of mfiresults">
								<span (click)="updateattribute(spec.key, spec.value)" style="border-bottom: 1px solid #bdaa59;" class="attr_hover">
									<div><b>{{spec.key}}</b> : {{spec.value}}</div>
								</span>
							</ng-container>
						</div>
					</div>
					<a mat-button (click)="populateAttributes('fkl')">Populate FKL</a>
				</div>
			</div>



			<hr>


			<!-- image / search param row -->
			<div class='image-search-block flex-row'>
				<div class="flex-column w50" *ngIf="imagesFound">
					<mat-label>Remote image:</mat-label>
					<div class="flex-row">
						<ng-container *ngFor="let image of imagesFound">
							<div class="flex-column image-holder" (click)="updateattribute('image', image.image)">
								<img src="{{image.image}}" height="95px">
								<mat-label>{{image.vendor}}</mat-label>
							</div>
						</ng-container>
					</div>
				</div>

				<div class="flex-column w30" >
					<div class="search-params-border-bottom">Search Parameters</div>
					<div class="flex-row">
						<mat-form-field class="manufacturer-search-formfield">
							<mat-label>Manufacturer</mat-label>
							<input matInput placeholder="{{searchParams.mfg}}" value="{{searchParams.mfg}}" [formControl]="mfg">
						</mat-form-field>
					</div>
					<div class="flex-row">
						<mat-form-field class="mfgno-search-formfield">
							<mat-label>MFG Part#</mat-label>
							<input matInput placeholder="{{searchParams.mfgpartno}}" value="{{searchParams.mfgpartno}}" [formControl]="mfgpartno">
						</mat-form-field>
					</div>
				</div>
				<div class="flex-column">
					<mat-label>Search again</mat-label>
					<a class="search-again-button" mat-button (click)="searchApis()"><mat-icon>refresh</mat-icon></a>
				</div>
			</div>


			<hr>

			<!-- Form section (upper half) -->
			<h4>Tire Information Attributes</h4>
			<ng-container *ngIf="form_attr">
				<form [formGroup]="form_attr">

					<div class="flex-row j-center">
						<div class="flex-column w33">
							<mat-form-field class="attribute-formfield">
								<mat-label>Name</mat-label>
								<input matInput placeholder="" value="" formControlName="displayName_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>MFG Part#</mat-label>
								<input matInput placeholder="" value="" formControlName="mfgpartno_attr" class="elipse">
							</mat-form-field>
						</div>

						<div class="flex-col">
							<mat-form-field class="attribute-formfield">
								<mat-label>Size</mat-label>
								<input matInput placeholder="" value="" formControlName="size_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Load Index</mat-label>
								<input matInput placeholder="" value="" formControlName="loadindex_attr" class="elipse">
							</mat-form-field>
						</div>

						<div class="flex-column fauto">
							<div class="flex-column save-reset-box">
								<a mat-raised-button (click)="resetForm()">Reset Form</a>
								<a mat-raised-button (click)="saveAttributes()">Save Attributes</a>
							</div>
						</div>
					</div>


					<div class="flex-row j-center">
						<div class="flex-col w33">
							<mat-form-field class="attribute-formfield">
								<mat-label>Speed Rating</mat-label>
								<input matInput placeholder="" value="" formControlName="speedrating_attr" class="elipse">
							</mat-form-field>
						</div>
						<div class="flex-col w33">
							<mat-form-field class="attribute-formfield">
								<mat-label>Image</mat-label>
								<input matInput placeholder="" value="" formControlName="image_attr" class="elipse">
							</mat-form-field>
						</div>
						<div class="flex-col w33">
							<mat-form-field class="winterdes-formfield">
								<mat-label>Truck Designation</mat-label>
								<mat-select formControlName="truckdesignation_attr" name="des" value="ahh">
								<mat-option *ngFor="let d of truck_des" [value]="d.value">
									{{d.viewValue}}
								</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>


					<hr>


					<!-- bottom half of attribute form -->
					<div class="flex-row j-center">
						<div class="flex-col fauto">
							<mat-form-field class="attribute-formfield" >
								<mat-label>Tread Construction</mat-label>
								<input matInput placeholder="" value="" formControlName="treadConstruction_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield" >
								<mat-label>Measuring Rim Width</mat-label>
								<input matInput placeholder="" value="" formControlName="measuringRimWidth_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield" >
								<mat-label>Max Inflation Pressure</mat-label>
								<input matInput placeholder="" value="" formControlName="maxInflationPressure_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield" >
								<mat-label>Warranty</mat-label>
								<input matInput placeholder="" value="" formControlName="warranty_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield" >
								<mat-label>Sidewall</mat-label>
								<input matInput placeholder="" value="" formControlName="sidewall_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield" >
								<mat-label>UTGQ</mat-label>
								<input matInput placeholder="" value="" formControlName="utgq_attr" class="elipse">
							</mat-form-field>
						</div>

						<div class="flex-col fauto">
							<mat-form-field class="attribute-formfield">
								<mat-label>Section Width</mat-label>
								<input matInput placeholder="" value="" formControlName="sectionWidth_attr" class="elipse">
							</mat-form-field>
							<!-- <mat-form-field class="attribute-formfield" >
								<mat-label>Winter Designation</mat-label>
								<input matInput placeholder="" value="" formControlName="winterdesignation_attr" class="elipse">
							</mat-form-field> -->
							<mat-form-field class="winterdes-formfield">
								<mat-label>Winter Designation</mat-label>
								<mat-select formControlName="winterdesignation_attr" name="des" value="ahh">
								<mat-option *ngFor="let d of des" [value]="d.value">
									{{d.viewValue}}
								</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>UPC</mat-label>
								<input matInput placeholder="" value="" formControlName="upc_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>RPM</mat-label>
								<input matInput placeholder="" value="" formControlName="revolutionsPerMile_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Rim Width</mat-label>
								<input matInput placeholder="" value="" formControlName="approvedrimwidth_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Features</mat-label>
								<input matInput placeholder="" value="" formControlName="features_attr" class="elipse">
							</mat-form-field>

						</div>

						<div class="flex-col fauto">
							<mat-form-field class="attribute-formfield">
								<mat-label>Overall Diameter</mat-label>
								<input matInput placeholder="" value="" formControlName="overallDiameter_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Aspect Ratio</mat-label>
								<input matInput placeholder="" value="" formControlName="aspectRatio_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Overall Width</mat-label>
								<input matInput placeholder="" value="" formControlName="overallWidth_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Tread Depth</mat-label>
								<input matInput placeholder="" value="" formControlName="treadDepth_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Runflat</mat-label>
								<input matInput placeholder="" value="" formControlName="runflat_attr" class="elipse">
							</mat-form-field>
							<mat-form-field class="attribute-formfield">
								<mat-label>Description</mat-label>
								<input matInput placeholder="" value="" formControlName="description_attr" class="elipse">
							</mat-form-field>
						</div>
					</div>

					<div class="flex-row fauto">
						<mat-form-field class="attribute-formfield">
							<mat-label>Benefits</mat-label>
							<input matInput placeholder="" value="" formControlName="benefits_attr" class="elipse">
						</mat-form-field>
					</div>

				</form>
			</ng-container>
		</div>

		<div class="modal-footer"></div>

	</ng-template>


<!-- Main table -->


<div class="mtop20">
	<div class="flex-row">

		<div class="flex-col">
			<mat-form-field appearance="fill" *ngIf="mfgs">
				<mat-label>Manufacturer</mat-label>
				<mat-select (selectionChange)="filterBrands($event.value)" [formControl]="mfgSelected">
					<mat-option *ngFor="let mfgg of mfgs" [value]="mfgg.value">
						{{mfgg.viewValue}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="flex-row" >
				<form [formGroup]="form_filters">
					<mat-checkbox class="filter-checkbox" [formControl]="missingImageToggle" (change)="lookupVersionEight()">Missing Images</mat-checkbox>
					<mat-checkbox class="filter-checkbox" [formControl]="missingAttributeToggle" (change)="lookupVersionEight()">Missing Attributes</mat-checkbox>
				</form>

				<mat-checkbox class="filter-checkbox" value="after" [(ngModel)]="v4Toggle" (change)="filterDatabase(); lookupVersionEight()">V4</mat-checkbox>
				<mat-checkbox class="filter-checkbox" value="after" [(ngModel)]="v8Toggle" (change)="filterDatabase(); lookupVersionEight()">v8</mat-checkbox>
			</div>
		</div>

		<mat-form-field appearance="outline" class="searchbar">
			<input matInput [formControl]="lookup" #itemrec id="itemrec" value="" class="margin-top" autocomplete="off"
				placeholder="* Item Search  "
				(keyup.enter)="$event.stopPropagation();$event.preventDefault();lookupVersionEight();$event.target.blur();">
			<div matSuffix class="text-right">
				<button mat-icon-button type="button" color="white"
					(click)="$event.stopPropagation();$event.preventDefault();lookupVersionEight()">
					<mat-icon>search</mat-icon>
				</button>
			</div>
		</mat-form-field>

	</div>

	<!-- multiple tire select list -->
	<div class="table table-responsive text-center searchlist" *ngIf="searching">
		<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>
	<div class="table table-responsive searchlist" #searhlist [hidden]="!itemlist && !itemlist.length">
		<section class="table-container">
			<table mat-table matSort [dataSource]="datasource" class="mat-elevation-z8" [hidden]="!datasource">
				<ng-container matColumnDef="image">
					<th mat-header-cell *matHeaderCellDef> Image </th>
					<td mat-cell *matCellDef="let element">
						<!-- <ng-container *ngIf="['', null, 'https://cleve.tireserv.com/system/storage/upload/'].includes(element.image); else image"> -->
							<!-- <mat-icon class="error-color">error</mat-icon> -->
						<!-- </ng-container> -->
						<img src="{{element.image}}" (click)="loadItem(element)" width="50px">
					</td>
				</ng-container>

				<ng-container matColumnDef="stockid">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> Stock ID </th>
					<td mat-cell *matCellDef="let element"> {{element.stockid}} </td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> Description </th>
					<td mat-cell *matCellDef="let element"> {{element.description}} </td>
				</ng-container>

				<ng-container matColumnDef="size">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> Size </th>
					<td mat-cell *matCellDef="let element">
						<ng-container *ngIf="['', null].includes(element.size); else size">
							<mat-icon class="error-color">error</mat-icon>
						</ng-container>
						<ng-template #size>{{element.size}}</ng-template>
					</td>
				</ng-container>

				<ng-container matColumnDef="speedrating">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> Speed Rating </th>
					<td mat-cell *matCellDef="let element">
						<ng-container *ngIf="['', null].includes(element.speedrating); else speedrating">
							<mat-icon class="error-color">error</mat-icon>
						</ng-container>
						<ng-template #speedrating>{{element.speedrating}}</ng-template>
					</td>
				</ng-container>

				<ng-container matColumnDef="loadindex">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> Load Index </th>
					<td mat-cell *matCellDef="let element">
						<ng-container *ngIf="['', null].includes(element.loadindex); else loadindex">
							<mat-icon class="error-color">error</mat-icon>
						</ng-container>
						<ng-template #loadindex>{{element.loadindex}}</ng-template>
					</td>
				</ng-container>

				<ng-container matColumnDef="utgq">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> UTGQ </th>
					<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="['', null].includes(element.utqg); else utgq">
						<mat-icon class="soft-error-color">error</mat-icon>
					</ng-container>
					<ng-template #utgq>{{element.utqg}}</ng-template>
					</td>

				</ng-container>

				<ng-container matColumnDef="qty">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> QTY </th>
					<td mat-cell *matCellDef="let element"> {{element.qty}} </td>
				</ng-container>

				<ng-container matColumnDef="version">
					<th mat-header-cell mat-sort-header *matHeaderCellDef> Version </th>
					<td mat-cell *matCellDef="let element"> <span [ngClass]="element.version == 'V4' ? 'v4':'v8'">{{ element.version }}</span> </td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef> Action </th>
					<td mat-cell *matCellDef="let element"><a mat-raised-button (click)="openLookup(element.stockid, element.version)">Search</a></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-shadow"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			</table>
		</section>
	</div>
	<mat-paginator #paginator [pageSizeOptions]="[100,500,1000]" showFirstLastButtons ></mat-paginator>

</div>
import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms'

import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PaymentsService } from '../../services/payments.service'

@Component({
	selector: 'app-pos-order-payments-after',
	templateUrl: './pos-order-payments-after.component.html',
	styleUrls: ['./pos-order-payments-after.component.scss']
})
export class PosOrderPaymentsAfterComponent {

	paymentForm: UntypedFormGroup;

	@Input() order_header;
	@Input() items: any = [];
	@Input() totals: any = [];
	@Input() pos: any = false;
	@Input() document_type: any = '30';
	@ViewChild('amountId') amountIdRef: ElementRef;
	@Input() payments: any = [];

	//not being sent in the header. on time
	@Input() orderno: any;
	@Input() debtorno: any;
	@Input() branchcode: any;

	@Output() payments_added = new EventEmitter < any > ();
	@Output() payments_removed = new EventEmitter < any > ();

	paymentmethods: any = false
	bankaccounts: any = false;
	loadingcards = false;
	default_payment = 'Check';

	hasprofile: any = false;
	profile_cards: any = false;
	payment_card: any = false;
	card_required: any = false;
	preselected_payment: any = false;

	balance_due: any = 0.00;
	refund = new FormControl(false);
	change_due: any = false;

	constructor(private formBuilder: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private paymentsService: PaymentsService) {}

	ngOnInit(): void {
		this.globalSearchService.payment_methods.subscribe((results: any) => {
			this.paymentmethods = results;
		})

		this.globalSearchService.bankaccounts.subscribe((results: any) => {
			this.bankaccounts = results;
		});

		this.resetPayForm();

	}

	addPaymentInput() {
		this.resetPayForm();
	}

	addPayment() {
		if (this.paymentForm.valid) {
			//order 1 is pre payment
			const data = {
				payment: this.paymentForm.value,
				order: {
					orderno: (this.order_header.orderno) ? this.order_header.orderno : '1',
					debtorno: this.order_header.debtorno,
					branchcode: this.order_header.branchcode,
					document_type: this.document_type
				}
			};

			this.paymentsService.addPayment(data).subscribe((results: any[]) => {
				this.payments_added.emit(false);
				this.resetPayForm();
			});
		}
	}

	removePayment(payment_id: any) {
		//this.payments[index].splice(0,1);
		this.paymentsService.removePayment(payment_id).subscribe((results: any[]) => {
			//this.getPayments();
			this.payments_removed.emit(false);
		});

	}

	updatePaymentAmount(event: any, payment: any) {
		payment.amount = event.target.value;
		this.updatePayment(payment)
	}

	updatePaymentType(event: any) {

		const thispay = this.paymentmethods.filter(p => {
			return p.paymentname == event.value
		})[0];

		if (thispay) {
			if (thispay.cc_process != '0') {
				this.card_required = true;
			} else {
				this.card_required = false;
				this.paymentForm.get('card_selected').setValue(false);
			}
		}
	}

	updatePaymentReference(event: any, payment: any) {
		payment.reference = event.target.value;
		this.updatePayment(payment)
	}

	updatePaymentBank(event: any, payment: any) {
		payment.bankaccount = event.target.value;
		this.updatePayment(payment)
	}

	updatePayment(payment: any) {
		//this.payments[index].splice(0,1);
		this.paymentsService.updatePayment(payment).subscribe((results: any[]) => { this.payments = results });
		this.getPayments();
	}

	resetPayForm() {

		const account = (this.bankaccounts && this.bankaccounts[0]) ? this.bankaccounts[0].accountcode : '';

		this.paymentForm = this.formBuilder.group({
			reference: [''],
			amount: [this.balance_due, [Validators.required, Validators.pattern('^[0-9.]*$')]],
			type: [this.default_payment, [Validators.required]],
			bankaccount: [account, [Validators.required]],
			card_selected: [false],
			charge_card: [false],
		});
	}

	getPayments() {
		this.payments_added.emit(false);
	}

	getBalance(): number {
		if(this.totals) {
			const total = this.totals.find(t => t.code === 'total');
			const totalAmount = total ? this.financial(total.text) : 0.00;
			const balance = totalAmount - this.financial(this.getTotalPayment());

			if (balance < 0) {
				this.payments.forEach(pay => {
					if (this.openCashDroor(pay.payment_type_id) && balance < 0) {
						this.change_due = balance;
						// Additional logic related to cash payments and change can be added here if needed
					}
				});
			} else {
				this.change_due = 0;
			}

			return balance;
		}
		return 0
	}

	openCashDroor(method: any) {

		let value = false;
		if (this.paymentmethods) {
			const thispay = this.paymentmethods.filter(p => {
				return p.paymentid == method
			})[0];
			if (thispay) {
				if (thispay.opencashdrawer == '1') {
					value = true;
				}
			}
		}

		return value;
	}
	getTotalPayment() {
		let total = 0.00
		if (this.payments && this.payments.length > 0) {
			total = this.payments.reduce(function(accumulator, am) {
				return accumulator + parseFloat(am.amount);;
			}, 0);
		}

		total = this.financial(total);
		return total;
	}

	changeCard(event: any) {
		if (event && event.card_type) {
			if (this.paymentForm) {
				this.paymentForm.get('type').setValue(event.card_type);
			}
		}
	}

	lastFour(value: any) {
		return 'XX' + value.substr(value.length - 4);
	}

	isNegative() {
		return (this.financial(this.paymentForm.get('amount').value) < 0)
	}

	ngOnChanges(changes: any) {

		if (changes.debtorno) {
			this.getPayments();
			this.loadProfile(this.debtorno, this.branchcode);

			if (changes.order_header.currentValue.preselectedpay) {
				this.preselected_payment = changes.order_header.currentValue.preselectedpay;
			}
		}

		if (changes.order_header) {

		}

		if (changes.payments) {
			if (changes.payments.currentValue && changes.payments.currentValue.length) {
				const payment_total = this.payments.reduce(function(accumulator, am) {
					return accumulator + parseFloat(am.amount);
				}, 0);

				const balance = this.financial(this.totals.total) - this.financial(payment_total);
				if (this.paymentForm) {
					this.paymentForm.get('amount').setValue(this.financial(balance))
				}
			}
		}


		if (changes.totals) {
			if (this.paymentForm) {
				if (!this.payments && !this.payments.length) {
					if (changes.totals.total) {
						this.paymentForm.get('amount').setValue(this.financial(changes.totals.total))
					}
				} else {

				}
			}
		}

		if (this.paymentForm) {

			if (this.paymentForm.untouched) {
				if (this.bankaccounts) {
					this.paymentForm.get('bankaccount').setValue(this.bankaccounts[0].accountcode)
				}
				this.paymentForm.get('type').setValue(this.paymentmethods[0].paymentname)
				const paymentid = this.getPaymentIdFromName(this.paymentmethods[0].paymentname);
				this.setCardRequired(paymentid)
			}

			if (!this.profile_cards && this.preselected_payment) {

				this.loadingcards = true;
				this.paymentsService.loadProfile(this.debtorno, this.branchcode).subscribe(async (result: any) => {
					this.loadingcards = false;
					if (result.cards) {
						this.hasprofile = true;
						this.profile_cards = result.cards
						this.paymentForm.get('type').setValue(this.preselected_payment.card_type);

						const card = this.profile_cards.filter((c: any) => {
							return c.card_id === this.preselected_payment.card_id;
						})[0];

						this.paymentForm.get('card_selected').setValue(card);
						const paymentid = this.getPaymentIdFromName(this.preselected_payment.card_type);
						this.setCardRequired(paymentid)

					} else {
						this.hasprofile = false;
						this.profile_cards = [];
						this.payment_card = false;
					}
				});
			}

			if (this.payments) {
				const payment_total = this.payments.reduce(function(accumulator, am) {
					return accumulator + parseFloat(am.amount);;
				}, 0);

				const balance = this.financial(this.totals.total) - this.financial(payment_total);
				this.paymentForm.get('amount').setValue(this.financial(balance))
			}


			this.getBalance();
		}
	}

	getPaymentIdFromName(name: any) {
		const type = this.paymentmethods.filter(p => {
			return p.paymentname == name
		})[0];

		if (type) {
			return type.paymentid;
		}
		return false;

	}

	loadProfile(debtorno: any, branchcode: any) {
		this.loadingcards = true;
		this.paymentsService.loadProfile(debtorno, branchcode).subscribe(async (result: any) => {
			this.loadingcards = false;
			if (result.cards) {
				this.hasprofile = true;
				this.profile_cards = result.cards
				const card = result.cards.filter((card: any) => {
					return card.default
				})[0];
				this.paymentForm.get('card_selected').setValue(card);

			} else {
				this.hasprofile = false;
				this.profile_cards = [];
				this.payment_card = false;
				this.paymentForm.get('card_selected').setValue(false);
			}
		});
	}


	setCardRequired(method: any) {

		if (method) {
			const thispay = this.paymentmethods.filter(p => {
				return p.paymentid == method
			})[0];

			if (thispay) {
				if (thispay.cc_process != '0') {
					this.card_required = true;
				} else {
					this.card_required = false;
					this.paymentForm.get('charge_card').setValue(false);
					this.paymentForm.get('card_selected').setValue(false);
				}
			}
		}
	}

	setChargeType(event: any) {
		if (event.value) {
			this.paymentForm.get('type').setValue(event.value)
			this.updatePaymentType(event.value)
			this.setCardRequired(event.value);

			setTimeout(() => {
				this.amountIdRef.nativeElement.focus();
			}, 0);
		}
	}

	removeAllPayments() {
		// this.payments = [];
		const payload = {
			orderno: this.orderno,
			debtorno: this.debtorno,
			branchcode: this.branchcode,
		}
		this.paymentsService.removeAllPayments(payload).subscribe((results: any[]) => {
			//this.getPayments();
			this.payments_removed.emit(false);
		});
	}

	runKey(value: any) {

		switch (value) {
		case 'add':
			this.addPayment();
			break;
		case 'backspace':
			const newvalue = (this.paymentForm.get('amount').value + '').slice(0, -1);
			if (newvalue != '') {
				this.paymentForm.get('amount').setValue(newvalue);
			} else {
				this.paymentForm.get('amount').setValue(0.00);
			}

			break;
		case 'minus':

			this.makeNegativePayment()
			break;
		case '0':
		case '.':
			const periodval = this.paymentForm.get('amount').value + '' + value;
			this.paymentForm.get('amount').setValue(periodval);
			break;
		default:
			const currentvalue = this.paymentForm.get('amount').value + '' + value;
			this.paymentForm.get('amount').setValue(this.financial(currentvalue));
			break;
		}
	}

	makeNegativePayment() {
		//Math.abs()
		const newvalue = -1 * this.financial(this.paymentForm.get('amount').value);
		if (newvalue > 1) {
			this.refund.setValue(false);
		} else {
			this.refund.setValue(true);
		}

		this.paymentForm.get('amount').setValue(newvalue)
	}


	showRemoveAllButton(): boolean {
		return this.payments && this.payments.length > 0;
	}

	convertArrayToTitleText(arr: {
		[key: string]: number } []): any[] {
		return arr.map(obj =>
			Object.keys(obj).map(key => ({
				title: key,
				text: obj[key].toFixed(2)
			}))
		);
	}

	financial(x) {
		if (Number.isNaN(x)) {
			x = 0
		}
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
}

import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy, OnChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';
import { PrintService } from '../../services/print.service';

@Component({
	selector: 'app-card-manager',
	templateUrl: './card-manager.component.html',
	styleUrls: ['./card-manager.component.scss']
})
export class CardManagerComponent implements OnInit {
	@Input() customer: any = [];
	@Input() screen: any = false;
	@Output() updatePayments: EventEmitter < any > = new EventEmitter();

	@ViewChild('thirdparty') thirdpartyRef: ElementRef;
	@ViewChild('journal_details') journal_detailsRef: ElementRef;
	@ViewChild('checkno') checknoElement: ElementRef;
	flip = 'inactive';
	payment_methods: any = [];
	journal_payments: any = [];
	bankaccounts: any = [];
	today = new Date();
	day_payments: any = [];

	loadingcards : any = false;

	paymentForm: UntypedFormGroup;
	cardForm: UntypedFormGroup;
	//profile_cards: any = false;

	selectedCard = new UntypedFormControl('');
	searchByAmount = new UntypedFormControl('');
	searchByInvoice = new UntypedFormControl('');
	searchString = new UntypedFormControl('');
	customerSearchInline = new UntypedFormControl('');
	customerSearchResultsTwo: any = [];
	searchByCurrentPayments = new UntypedFormControl('');
	searchByCurrentInvoices = new UntypedFormControl('');

	selectAllPayments = new UntypedFormControl(false);
	selectAllInvoices = new UntypedFormControl(false);
	selectInvoiceToPay = new UntypedFormControl(false);

	searchByCurrentPaymentsDate = new UntypedFormControl('');
	searchByCurrentInvoicesDate = new UntypedFormControl('');
	holdReason = new UntypedFormControl('');
	journal_review = new UntypedFormControl('');

	alldebits: any = false;
	allcredits: any = false;

	//reversed
	singleusecard = new UntypedFormControl(true);
	currency: any = '';
	currencies: any = [];
	company: any = [];

	//paymentss
	datefrom = new Date()
	dateto = new Date()
	running = false;
	total_pay: any = '';
	payments: any = [];

	customersearching: any = false;
	customerSearchResults: any = [];
	amountResults: any = false;
	invoiceResults: any = false;
	customertransactions: any = [];

	hasprofile = false;
	payment_added: any = false;
	addingcard: any = false;

	allocations_from: any = [];
	allocations_to: any = [];

	allocation_from_total = 0.00;
	allocation_to_total = 0.00;
	profile_cards: any = [];

	payment_card: any = false;
	single_use: any = false;
	editingcard: any = false;
	sending: any = false;
	color = 'blue';
	charge_card = true;
	submitting = false;
	chargetype = 'existing_card';
	chargecardtype = 'no_charge';
	card_required = false;
	thirdpartylinks: any = false;
	showrefunds = false;
	urlSafe: SafeResourceUrl;
	creditsSelected: any = [];
	expandedElement: any = false;
	hidepay: any = false;
	debitsSelected: any = [];

	allpayments: any = [];
	alltransactions: any = [];
	holdreasons: any = [];
	openjournals: any = [];
	selectedJournal: any = false;
	token: any = false;

	constructor(private printService: PrintService, private modalService: NgbModal, public sanitizer: DomSanitizer, private ordersService: OrdersService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute, public router: Router) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.holdreasons.subscribe(r => {
			this.holdreasons = r;
		})


	}


	pdfJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'journal',
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	xlsJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'journal',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	ngOnInit(): void {

		this.globalSearchService.mesagesallowed.next(false);

		this.paymentForm = this.fb.group({
			debtorno: ['', Validators.required],
			branchcode: ['', Validators.required],
			payment_type: ['', Validators.required],
			bankaccount: ['', Validators.required],
			datepaid: [this.today, Validators.required],
			checkno: [''],
			reference: [''],
			description: [''],
			amount: [0.00, Validators.required],
			discount: [0, Validators.required],
			currency: ['', Validators.required],
			journalno: [''],
			searchString: [''],
			charge_card: []
		});


		this.globalSearchService.bankaccounts.subscribe((accounts: any) => {
			this.bankaccounts = accounts
			if (accounts) {
				this.paymentForm.get('bankaccount').setValue(accounts[0].accountcode);
			}
		});

		this.globalSearchService.currencies.subscribe((currencies: any) => {
			this.currencies = currencies
			if (currencies) {
				this.globalSearchService.company.subscribe((company: any) => {
					if (company) {
						this.paymentForm.get('currency').setValue(company.currencydefault);
					}
				});
			}
		});

		this.globalSearchService.payment_methods.subscribe((payment_methods: any) => {
			this.payment_methods = payment_methods
			if (payment_methods) {
				this.updatePaymentType(payment_methods[0].paymentid);
			}
		});

		this.paymentsService.getPayments(this.today.toISOString()).subscribe((results: any) => {
			this.day_payments = results;
		});

	}

	getSelectedJournal(event: any) {
		this.selectedJournal = event.value;
		this.loadSelectedJournal();
	}

	loadSelectedJournal() {
		this.paymentsService.getJournal(this.selectedJournal).subscribe(r => {
			this.journal_payments = r;
		});
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	customerBalanceReduce() {

		if (this.paymentForm.get('amount').value) {
			return this.financial(this.customer.aged.balance) - this.financial(this.paymentForm.get('amount').value)
		}
		return this.financial(this.customer.aged.balance);
	}

	setExapanded(payment: any) {
		this.expandedElement = (this.expandedElement == payment) ? false : payment;
		if (!this.expandedElement) {
			//no running variable
			const data = { from: this.paymentForm.get('datepaid').value, to: this.paymentForm.get('datepaid').value }
			this.paymentsService.getPaymentRange(data).subscribe((results: any) => {
				this.payments = results;
			});
		}
	}
	updateHoldReason() {
		const data = {
			holdreason: this.holdReason.value,
			debtorno: this.customer.debtorno,
			//branchcode: this.customer.branchcode,
			//ediinvoices: this.customer.ediinvoices,
			//emailstatement: this.customer.emailstatement,
			//arintrate: this.customer.arintrate,
			//currcode: this.customer.currcode,
			//creditlimit: this.customer.creditlimit,
			//customerpoline: this.customer.customerpoline,
		}
		this.customerService.updateDebtor(data).subscribe(r => {

		})
	}

	getJournals() {
		const data = {};
		this.paymentsService.getJournals(data).subscribe((result: any) => {
			this.openjournals = result;
			if (result.length) {
				this.paymentForm.get('journalno').setValue(result[0]);
			} else {
				this.paymentForm.get('journalno').setValue('');
			}
		});
	}

	closeJournal(event: any) {
		event.stopPropagation();
		event.preventDefault();

		const data = {
			journal: this.paymentForm.get('journalno').value
		};
		this.paymentsService.closeJournal(data).subscribe((result: any) => {
			this.openjournals = result;
			if (result.length) {
				this.paymentForm.get('journalno').setValue(result[0]);
			} else {
				this.paymentForm.get('journalno').setValue('');
			}

		});
	}
	openNewJournal(event: any) {
		event.stopPropagation();
		event.preventDefault();

		const data = {};
		this.paymentsService.openJournal(data).subscribe((result: any) => {
			this.openjournals = result;
			if (result.length) {
				this.paymentForm.get('journalno').setValue(result[0]);
			} else {
				this.paymentForm.get('journalno').setValue('');
			}
		});
	}

	loadCustomer() {

		this.paymentsService.getCustomerAccountReceivables(this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			this.customer = results;

			this.holdReason.setValue(results.holdreason);
			this.searchString.setValue(results.name);

			this.loadProfile();

			this.customertransactions = results.transactions;
			this.allcredits = results.payments
			this.alldebits = results.transactions

			this.alltransactions = results.transactions;
			this.allpayments = results.payments;

			this.paymentForm.get('debtorno').setValue(results.debtorno);
			this.paymentForm.get('branchcode').setValue(results.branchcode);
			this.paymentForm.get('amount').valueChanges.subscribe(newValue => {
				this.updateTotals();
			})

			this.customerSearchInline.setValue(this.customer.name);
			this.paymentForm.get('payment_type').valueChanges.subscribe(newValue => {

				const thispay = this.payment_methods.filter(p => {
					return p.paymentid == newValue
				})[0];

				if (thispay.refunds == '1') {
					this.showrefunds = true;
				} else {
					this.showrefunds = false;
				}

				this.setCardRequired(newValue);
			})

			this.setCardForm();
			const links = {
				debtorno: this.customer.debtorno,
				branchcode: this.customer.branchcode,
				transno: '',
			}
			this.paymentsService.getLinks(links).subscribe((result: any) => {
				this.thirdpartylinks = result
			});

			this.flip = 'inactive';

			this.loadPayments();

		});
	}

	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive' : 'active';

		switch (this.flip) {
		case 'active':
			this.loadSelectedJournal();
			break;
		}
	}

	openModal(url) {

		//url = url + '&output=embed';
		/*
		this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(url+'&embed=true');
		this.modalService.open(this.thirdpartyRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
		});
		*/
	}

	voidPayment(pay: any) {
		this.paymentsService.voidPayment(pay).subscribe((result: any) => {
			if (result.success) {
				this.loadPayments();
			} else {
				alert(result.message)
			}
		})
	}

	loadPayments() {
		this.running = true;
		const data = { from: this.paymentForm.get('datepaid').value, to: this.paymentForm.get('datepaid').value }
		this.paymentsService.getPaymentRange(data).subscribe((results: any) => {
			this.running = false;
			this.payments = results;

			this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.amount);
			}, 0);
		})
	}

	setCardForm() {
		this.cardForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			ccnumber: ['', Validators.required],
			expiry: ['', Validators.required],
			card_id: [true],
			cvv: [''],
			savetoprofile: [true, Validators.required]
		});
	}


	setCardRequired(method: any) {
		const thispay = this.payment_methods.filter(p => {
			return p.paymentid == method
		})[0];

		if (thispay.cc_process != '0') {
			this.card_required = true;
		} else {
			this.card_required = false;
			this.paymentForm.get('charge_card').setValue(false)
			this.charge_card = false;

		}

	}

	updatePaymentType(method: any) {
		this.paymentForm.get('payment_type').setValue(method);
		this.setCardRequired(method);
	}

	updateProfile(event: any) {
		this.customer.profile = event;
		this.addingcard = false;
		this.editingcard = false;
		this.sending = false;
		this.profile_cards = event.cards
		this.hasprofile = (event.cards.length) ? true : false;
		this.profile_cards = event.cards
	}

	toggleAddCard() {
		this.addingcard = (this.addingcard) ? false : true
	}

	updateTotals() {
		this.allocation_to_total = this.allocations_to.reduce(function(accumulator: number, items: any) {
			return accumulator + parseFloat(items.allocation);
		}, 0);

		this.allocation_from_total = this.allocations_from.reduce(function(accumulator: number, items: any) {
			return accumulator + parseFloat(items.allocation);
		}, 0) + (-1 * parseFloat(this.paymentForm.get('amount').value)) + (-1 * parseFloat(this.paymentForm.get('discount').value));
	}

	//update allocations
	updateAllocationTo(input, pay) {
		const index = this.allocations_to.indexOf(pay);
		if (index >= 0) {
			this.allocations_to[index].allocation = input;
			if (parseFloat(input) > this.allocations_to[index].leftto) {
				this.allocations_to[index].allocation = parseFloat(this.allocations_to[index].leftto);
			}
		}
		this.updateTotals();
	}

	//update alloc from
	updateAllocationFrom(input, pay) {
		const index = this.allocations_from.indexOf(pay);
		if (index >= 0) {
			if (parseFloat(input) > parseFloat(this.allocations_from[index].leftto)) {
				//this.allocations_from[index].allocation = parseFloat(this.allocations_from[index].leftto);
				input = parseFloat(this.allocations_from[index].leftto);
			}

			this.allcredits.forEach((r: any) => {
				if (r == pay) {
					pay.selected = true;
					r.selected = true;
				}
			});

			this.allocations_from[index].allocation = input;
		}

		this.updateTotals();
	}

	allocationTo(event: any, rec: any) {
		if (!event) {
			rec.selected = false;
			const index = this.allocations_to.indexOf(rec);
			this.allocations_to.splice(index, 1);

		} else {

			this.alldebits.forEach((r: any) => {
				if (r == rec) {
					rec.selected = true;
					r.selected = true;
				}
			});

			rec.selected = true;
			this.allocations_to.push(rec);
		}

		this.updateTotals();

		if (this.selectInvoiceToPay.value) {
			const amount = this.financial(this.allocation_to_total);
			this.paymentForm.get('amount').setValue(amount);
		}
	}

	checkAllTo(event: any) {
		if (event) {
			this.customer.transactions.forEach((r: any) => {
				r.selected = true;
				this.allocations_to.push(r);
			});
		} else {
			this.customer.transactions.forEach((r: any) => { r.selected = false; });
			this.allocations_to = [];
		}
		this.updateTotals();
	}

	checkAllFrom(event: any) {
		if (event) {
			this.allocations_from = [];
			this.customer.payments.forEach((r: any) => {
				r.selected = true;
				//this.allocations_from.push(r);
				this.allocationFrom(true, r)
			});
		} else {
			this.customer.transactions.forEach((r: any) => {
				r.selected = false;
				this.allocationFrom(false, r)
			});
			this.allocations_from = [];
		}
		this.updateTotals();
	}

	allocationFrom(event: any, rec: any) {

		if (!event) {
			rec.selected = false;
			const index = this.allocations_from.indexOf(rec);
			this.allocations_from.splice(index, 1);
		} else {
			rec.selected = true;
			this.allocations_from.push(rec);
		}

		this.updateTotals();

		/*
		if(this.selectInvoiceToPay.value) {
			let amount = this.allocation_from_total + this.allocation_to_total;

			if(amount > 0 ){
				this.paymentForm.get('amount').setValue(amount.toFixed(2));
			} else {
				this.paymentForm.get('amount').setValue(0.00);
			}
		}
		*/
	}

	addAllocations() {
		if (!this.paymentForm.get('debtorno').value) {
			alert('Please Select A Customer');
		} else {
			const data = { from: this.allocations_from, to: this.allocations_to, debtor: this.paymentForm.value }
			this.paymentsService.addAllocations(data).subscribe((result: any) => {

				this.allpayments = result.payments;
				this.alltransactions = result.transactions;

				this.customer.transactions = result.transactions
				this.customer.payments = result.payments
				this.customer.credits = result.credits


				this.allcredits = result.payments
				this.alldebits = result.transactions

				this.selectAllInvoices.setValue(false);
				this.selectAllPayments.setValue(false);

				this.checkAllTo(false);
				this.checkAllFrom(false);
				this.allocations_to = [];
				this.allocations_from = [];
				this.updateTotals();
			});
		}
	}

	doNotCharge(event: any) {
		event.preventDefault();
		this.payment_card = false;
		this.charge_card = false;
		this.paymentForm.get('charge_card').setValue(false)
		this.profile_cards.every(card => card.default = false);
	}

	addPayment(event: any) {
		event.preventDefault();
		if (!this.paymentForm.get('debtorno').value) {
			alert('Please Select A Customer');
		} else {
			if (this.paymentForm.valid && this.paymentForm.get('amount').value != 0) {

				let charge_card = false;
				switch (this.chargecardtype) {
				case 'no_charge':
					charge_card = false;
					break;
				case 'new_card':
					charge_card = this.cardForm.value
					break;
				case 'existing_card':
					charge_card = this.paymentForm.get('charge_card').value
					break;
				}

				const data = {
					payment: this.paymentForm.value,
					charge_card: charge_card,
					from: this.allocations_from,
					to: this.allocations_to,
					debtor: this.paymentForm.value
				}
				//reversed values
				this.submitting = true;

				this.paymentsService.addCashRecPayment(data).subscribe((result: any) => {
					this.submitting = false;
					if (result.success) {

						this.payment_added = result

						this.allpayments = result.payments;
						this.alltransactions = result.transactions;

						this.customer.payments = result.payments
						this.customer.credits = result.credits

						this.allcredits = result.payments
						this.alldebits = result.transactions

						this.single_use = false;
						this.globalSearchService.showNotification('Payment Added', 'success', 'bottom', 'left');
						this.paymentForm.get('amount').setValue('')
						this.paymentForm.get('discount').setValue(0)

						this.setCardForm();
						this.loadCustomer();
						this.getJournals();
						this.focusTo();
						this.selectAllInvoices.setValue(false);
						this.allocation_from_total = 0.00;
						this.allocation_to_total = 0.00;
						this.allocations_to = [];
						this.allocations_from = [];
						this.updateTotals();
						this.paymentForm.get('checkno').setValue('');
					} else {
						this.globalSearchService.showNotification('Error: ' + result.message, 'danger', 'bottom', 'left');
					}
				});
			}
		}
	}

	hidePayments() {
		this.hidepay = (this.hidepay) ? false : true;
	}

	currentPaymentsSearch(value: any) {
		if (value != '') {
			this.customer.payments = this.globalSearchService.filterItem(this.allpayments, value, 'typename,orderno,transno,total');
		} else {
			this.customer.payments = this.allpayments;
		}
	}
	currentPaymentsSearchDate(value: any) {
		if (value != '') {
			this.customer.payments = this.globalSearchService.filterItem(this.allpayments, value, 'trandate');
		} else {
			this.customer.payments = this.allpayments;
		}

	}

	currentInvoiceSearch(value: any) {
		if (value != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alltransactions, value, 'typename,orderno,total');
		} else {
			this.customer.transactions = this.alltransactions;
		}
	}

	currentInvoiceSearchDate(value: any) {

		if (value != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alltransactions, value, 'trandate');
		} else {
			this.customer.transactions = this.alltransactions;
		}

	}

	togglePaySelect(pay: any) {
		pay.selected = (pay.selected) ? false : true;
		if (pay.selected) {
			this.creditsSelected.push(pay)
		} else {
			const i = this.creditsSelected.indexOf(pay)
			this.creditsSelected.splice(i, 1);
		}
	}

	updateDayPayMents(event: any) {
		this.today = event;
		this.paymentsService.getPayments(this.today.toISOString()).subscribe((results: any) => {
			this.payments = results;
		});
	}

	selectCustomer(customer: any) {
		//todo dont do this


		this.customersearching = false;
		this.customerSearchInline.setValue(customer.name);
		this.customer = customer;
		this.loadCustomer();
		//this.router.navigate(['/receivables/payment-entry/' + customer.debtorno + '/' + customer.branchcode]);
	}

	customerSearchtwo(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		const search = {
			keywords: event
		}

		this.customersearching = this.customerService.getCustomerSearch(search).subscribe(async (results: any) => {
			this.customerSearchResultsTwo = results;

			if (results.length == 1) {
				this.selectCustomer(results[0])
				this.customerSearchResults = false;
				this.customerSearchResultsTwo = false;
			}

		});
	}

	paymentTotals(): number {

		let balance = (this.financial(this.allocation_to_total) + this.financial(this.allocation_from_total));
		if (balance >= 0) {
			balance = 0.00
		}
		return balance;
	}

	ngAfterViewInit(): void {
		this.getJournals();
	}

	singleFormSet(event: any) {


	}

	editCard(event: any, card: any) {
		event.stopPropagation();
		event.preventDefault();

		this.editingcard = true;
		this.cardForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [card.name, Validators.required],
			ccnumber: [card.number, Validators.required],
			card_id: [card.card_id, Validators.required],
			expiry: [card.expiry_month + '/' + card.expiry_year, Validators.required],
			cvv: [''],
			savetoprofile: [true, Validators.required]
		});

	}

	saveCard(event: any) {
		event.stopPropagation();
		event.preventDefault();

		if (this.cardForm.valid) {
			this.sending = true;
			this.paymentsService.addCreditCard(this.cardForm.value).subscribe((result: any) => {
				this.sending = false;
				if (!result.success) {
					alert(result.message)
				} else {

					if (result.cards) {
						this.hasprofile = true;
						this.profile_cards = result.cards
						this.payment_card = result.cards.filter((card: any) => {
							return card.default
						})[0];
					}

					this.editingcard = false;
				}
			});
		}

	}

	updateCard(event: any) {
		event.stopPropagation();
		event.preventDefault();

		if (this.cardForm.valid) {
			this.sending = true;
			this.paymentsService.editCreditCard(this.cardForm.value).subscribe((result: any) => {
				this.sending = false;
				if (!result.success) {
					alert(result.message)
				} else {

					if (result.cards) {
						this.hasprofile = true;
						this.profile_cards = result.cards
						this.payment_card = result.cards.filter((card: any) => {
							return card.default
						})[0];


					}

					this.editingcard = false;
				}
			});
		}

	}

	removeCard(card: any) {


		const remove = {
			debtorno: this.customer.debtorno,
			branchcode: this.customer.branchcode,
			name: this.customer.name,
			ccnumber: card.number,
			expiry: card.expiry_month +'/'+ card.expiry_year,
			card_id: card.card_id,
		};

		const proceed = confirm('Remove Card From Profile?');
		if(proceed) {
			this.loadingcards = true;
			this.paymentsService.removeCreditCard(remove).subscribe((result: any) => {
				this.loadingcards = false;
				this.hasprofile = (result.cards.length) ? true : false;
				this.editingcard = false;
				this.profile_cards = result.cards
				this.payment_card = result.cards.filter((card: any) => {
					return card.default
				})[0];
			});
		}
	}

	addPaymentMethod(event: any) {
		event.preventDefault();
		if (this.cardForm.valid) {
			this.sending = true;
			this.paymentsService.addCreditCard(this.cardForm.value).subscribe((result: any) => {
				this.sending = false;
				if (!result.success) {
					alert(result.message)
				} else {}
			});
		}
	}
	setChargeCardType(event: any) {
		this.chargecardtype = event.value
	}
	setChargeType(event: any) {
		this.chargetype = event.value
		this.updatePaymentType(event.value)
		this.setCardRequired(event.value);
	}

	back() {
		//this.location.back()
	}
	//todo dont do this.
	focusTo() {
		setTimeout(() => {
			this.checknoElement.nativeElement.focus();
		}, 0);
	}


	loadProfile() {
		this.loadingcards = true;
		this.addingcard = false;
		this.paymentsService.loadProfile(this.customer.debtorno, this.customer.branchcode).subscribe(async (result: any) => {
			this.loadingcards = false;
			if (result.cards) {
				this.profile_cards = result.cards;
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.customer) {
			this.loadProfile();
		}
	}
}

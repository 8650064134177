import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormBuilder, FormGroup, FormControl, ValidationErrors } from '@angular/forms';

@Component({
	selector: 'app-edit-min-max',
	templateUrl: './edit-min-max.component.html',
	styleUrls: ['./edit-min-max.component.scss']
})
export class EditMinMaxComponent implements OnInit {
	@Input() item: any = false;
	@Output() updated = new EventEmitter < any > ();

	minMaxForm: FormGroup;

	constructor(private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, private fb: FormBuilder) {}

	ngOnInit(): void {
		this.minMaxForm = this.fb.group({
			stockid: [this.item.stockid, Validators.required],
			minimum: [this.item.minmax.minimum, Validators.required],
			maximum: [this.item.minmax.maximum, Validators.required],
			loccode: ['', Validators.required],
		});
	}

	updateMinMax() {
		this.inventoryService.updateMinMax(this.minMaxForm.value).subscribe(r => {
			if(r.success) {
				this.globalSearchService.showNotification('Updated', 'success','bottom','right')
				this.updated.emit(r);
			} else {
				this.globalSearchService.showNotification('Error', 'danger','bottom','right')
			}
		});
	}

	setLocCode(event:any) {
		this.minMaxForm.get('loccode').setValue(event.loccode)
	}

	nonStockItem() {
		this.minMaxForm.get('minimum').setValue(0)
		this.minMaxForm.get('maximum').setValue(0)
	}

}

import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'


@Component({
	selector: 'app-customer-ar',
	templateUrl: './customer-ar.component.html',
	styleUrls: ['./customer-ar.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
			// :enter is alias to 'void => *'
				style({ height: '0',width: '0' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
			// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0  })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerArComponent implements OnInit {

	@Input() customer;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newcustomerdata = new EventEmitter<boolean>();

	arForm: any = false;
	areas: any = [];
	holdreasons: any = [];
	terms: any = [];
	currencies: any = [];
	disabletrans = false;
	disabledbranch = false;
	tirefee = true;
	requirepo = false;
	formEditAllowed = [
		'debtorno',
		'branchcode','disabletrans',
		'tirefee',
		'customerpoline',
		'currabrev',
		'termsindicator',
		'creditlimit',
		'arintrate',
		'currcode',
		'holdreason',
		'paymentterms',
		'emailstatement',
		'statementemailaddress',
		'ediinvoices',
		'invoiceemailaddress',
		'edireference'
	];
	config: any = false;

	constructor(public globalSearchService: GlobalSearchService, private route: ActivatedRoute, private fb: UntypedFormBuilder,private customerService: CustomerService, public router: Router, private location: Location, private globalsService: GlobalsService) {

		this.globalsService.getHoldReasons().subscribe(details => {
			this.holdreasons = details;
		});

		this.globalsService.getTerms().subscribe(details => {
			this.terms = details;
		});

		this.globalSearchService.configsubscription.subscribe(c => {
			this.config = c;
		});

		this.globalsService.getCurrencies().subscribe(details => {
			this.currencies = details;
		});
	}

	ngOnInit(): void {

		const details = this.customer.customer
		const controls = [];
		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
		Object.keys(details).forEach(key => {
			if(this.formEditAllowed.includes(key)) {
				const value = (details[key]) ? details[key] : '';
				controls.push({ name: key, control: new UntypedFormControl(value, []) })
			}
		});
		controls.forEach(f => {
				 formGroup.addControl(f.name, f.control, {})
		});
		this.arForm = formGroup;
	}

	back(): void {
		this.editing.emit(false);
	}

	onSubmit() {

		this.globalSearchService.getFormValidationErrors(this.arForm);

		const proceed = true;
		//1.18 email validation remove
		// if(this.arForm.get('emailstatement').value) {
		// 	if(!this.globalsService.isEmail(this.arForm.get('statementemailaddress').value)) {
		// 		proceed = false;
		// 	}
		// }

		// if(this.arForm.get('ediinvoices').value) {
		// 	if(!this.globalsService.isEmail(this.arForm.get('invoiceemailaddress').value)) {
		// 		proceed = false;
		// 	}
		// }

		if(proceed) {
			if(this.arForm.valid) {
				this.customerService.updateDebtorAr(this.arForm.value).subscribe( (results) => {
					this.newcustomerdata.emit(results);
					this.editing.emit(false);
				})
			}
		}
	}

}

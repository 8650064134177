import { Component, OnDestroy, OnInit, SimpleChanges, OnChanges ,ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../services/dashboard.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service';
import { InventoryService } from '../../services/inventory.service';
import * as Chartist from 'chartist';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Chart, registerables }  from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import autocolors from 'chartjs-plugin-autocolors';
Chart.register(autocolors);


@Component({
	selector: 'app-brand-report',
	templateUrl: './brand-report.component.html',
	styleUrls: ['./brand-report.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [
			transition(':enter', [
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate(100, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class BrandReportComponent implements OnInit {

	@Output() viewData = new EventEmitter < any > ();
	@Input() customer;
	@Input() vendor;
	@Input() menuapp: any = false;

	@ViewChild('brandchart') chartDetails: ElementRef;
	@ViewChild('print_items') print_itemsRef: ElementRef;
	viewing: any = false;


	data: any = false;
	title = 'Brand report';

	currentcarts: any = [];
	cartagebuckets: any = [];
	cartsettings: any = [];
	location: any = false;

	cartdetails: any = [];
	user: any = false;
	color: any = 'blue';
	expanded = false;
	alldata: any = false;

	properties: any = false;
	locations: any = false;
	todate = new Date();
	fromdate = new Date();
	today = new Date();
	sending: any = false;


	categoryid = new UntypedFormControl('');
	lineid = new UntypedFormControl('');
	defaultlocation = new UntypedFormControl('');
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	brands = new UntypedFormControl([]);
	grouplocations = new UntypedFormControl(true);
	chart: any = false;
	charttitle: any = 'Brand Sales';
	filename = 'Brand Sales';
	dataload: any = false;

	limits: any = [5,10,15,20,25,30,50,100, 'NO LIMIT']
	limit = new UntypedFormControl();
	flip = 'inactive';
	showdata: any = false;

	brandorpline = 'BRAND';
	config: any = false;

	datasub: any = false;

	constructor(private inventoryService: InventoryService, private printService: PrintService, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private dashboardService: DashboardService, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();

		this.limit.setValue(10);

		const today = new Date();
		//var priorDate = new Date(new Date().setDate(today.getDate() - 365));
		const priorDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
		this.fromdate = priorDate;
		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.globalSearchService.user.subscribe(r => {
			this.user = r;

			this.globalSearchService.locations.subscribe(async (results: any) => {
				this.locations = results;

				if (results) {
					const val = [
						this.user.user.defaultlocation.loccode
					];
				}
			});
		})

		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results;
			this.brands.valueChanges.subscribe(r => {
				this.updateProductLines();
			})
		});
	}

	ngOnInit(): void {
		this.expanded = false;
	}

	toggleExpanded() {
		this.data = false;
		if (this.expanded) {
			this.expanded = false;
		} else {
			this.expanded = true;
		}

		this.loadData();
	}

	setData(data: any = []) {

		if(this.limit.value != 'NO LIMIT') {
			var dataset = data.slice(0, this.limit.value);
		} else {
			var dataset = data;
		}

		const _labels = [];
		const _series = [];
		const datapoints = [];

		data.forEach((r, index) => {
			const value = {
				value: r.total,
				meta: r.label,
				name: r.label,
				label: r.label,
				data: r.total
			}
			_labels.push(r.label)

			if(this.config.env.package == 'tires') {
				_series.push(r.qty)
			} else {
				_series.push(r.total)
			}

			datapoints.push(value)
		});

		// 		if(this.grouplocations.value) {
		//
		// 		}

		return {
			labels: _labels,
			datasets: [{
				label: this.charttitle ,
				data: _series,
				borderWidth: 1,
				fill: false,
				// backgroundColor: [
				//   'rgba(255, 99, 132, 0.2)',
				//   'rgba(255, 159, 64, 0.2)',
				//   'rgba(255, 205, 86, 0.2)',
				//   'rgba(75, 192, 192, 0.2)',
				//   'rgba(54, 162, 235, 0.2)',
				//   'rgba(153, 102, 255, 0.2)',
				//   'rgba(201, 203, 207, 0.2)'
				// ],
			}]
		};
	}

	loadData() {

		const search = {
			limit: this.limit.value,
			location: this.defaultlocation.value,
			brands: this.brands.value,
			from: this.fromdateCtrl.value,
			to: this.todateCtrl.value,
			categories: this.categoryid.value,
			pline: this.lineid.value,
			grouplocations: this.grouplocations.value,
			debtorno: (this.customer) ? this.customer.debtorno : false,
			vendor: (this.vendor) ? this.vendor.supplierid : false,
			issalesman: (this.user.user.issalesman) ? this.user.user.salesman : false
		}

		if(this.dataload) {
			this.dataload.unsubscribe();
		}

		this.sending = true;
		this.dataload = this.dashboardService.getBrandReport(search).subscribe(async (results: any) => {

			this.sending = false;
			if (results.data) {

				this.alldata = results.data;
				this.data = results.data;
				const data = this.setData(results.data);

				if (this.chart) {
					this.chart.destroy();
				}

				Chart.register(...registerables);
				Chart.register(ChartDataLabels);

				if(!this.brands.value.length) {
					this.charttitle = 'Brand Sales';
				}

				this.chart = new Chart('brandChart', {
					type: 'bar',
					data: data,
					options: {
						indexAxis: 'y',
						elements: {
							bar: {
								borderWidth: 2,
							}
						},
						scales: {
							y: {
								beginAtZero: true
							}
						},
						responsive: true,
						plugins: {
							datalabels: {
								color: '#000',
								align: 'end',
								anchor: 'end',
								formatter: (value, context) => {
									return value; // Customize this if you want to format the value
								}
							},
							legend: {
								position: 'bottom',
							},
							title: {
								display: false,
								text: (this.expanded = true) ? 'Brand Sales' : 'Brand Sales'
							},
							autocolors: {
								enabled: true,
								offset: 5,
							}
						},
						onClick: (event, elements, chart) => {
							if (elements[0]) {
								const i = elements[0].index;
								this.expanded = true;
								this.sending = true;
								this.loadBrandSale(chart.data.labels[i]);
								this.sending = false;
							}
						}
					},
					plugins: [ChartDataLabels] // Add the plugin here
				});
			}
		});
	}

	updateProductLines() {
		const data = {
			brands: this.brands.value
		}
		this.inventoryService.getBrandProductlines(data).subscribe( (r:any) => {
			this.properties.productlines = r;

			const selected = [];
			this.properties.productlines.forEach( a => {
				selected.push(a.line_field)
			});
			this.lineid.setValue(selected)
		});
	}

	loadBrandSale(i: any) {

		const brand_focus = this.properties.brands.filter( r => {
			return i == r.name;
		})[0];

		if(brand_focus) {
			this.expanded = true;
			this.charttitle = brand_focus.name+ ' ' + 'Brand Sales';
			this.brandorpline = 'PRODUCT LINE';
			this.brands.setValue([brand_focus.manufacturer_id])
			this.loadData();
		}
	}

	getTotal() {

		const value = this.data.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.total);
			return accumulator + addon;
		}, 0);

		return value;
	}


	toggleFlip(data: any, title: string) {

		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			const dashboard_data = { data: data, title: this.title }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}

	backOne() {
		this.brands.setValue([]);
		this.lineid.setValue([]);
		this.categoryid.setValue([]);
		this.title = 'Brand Sales';
		this.charttitle = 'Brand Sales';
		this.brandorpline = 'BRAND';
		this.loadData();
	}

	exportPdf() {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(this.print_itemsRef.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename,
			title:  this.charttitle,
			subtitle: ' Locations:'+this.defaultlocation.value.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	selectAllToggle(input: MatSelect) {
		//tired coming back to
		const currentvalue = input.value;
		if(currentvalue.length) {
			input.value = [];
		} else {
			const allvalues = input.options.map(r => {
				return r.value;
			});
			input.value = allvalues;
		}
	}

	toggleData() {
		this.flip = (this.flip=='active') ? 'inactive': 'active';
	}

	exportXls() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.print_itemsRef.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename,
			title:  this.charttitle,
			subtitle: ' Locations:'+this.defaultlocation.value.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	ngOnChanges(changes: any) {
		if(changes.customer) {

			if(changes.customer.currentValue) {
				this.limit.setValue(10);
				const today = new Date();
				const priorDate = new Date(today.getFullYear(), today.getMonth() - 12, 1);
				this.fromdate = priorDate;
				this.fromdateCtrl.setValue(this.fromdate);
				this.todateCtrl.setValue(this.todate);
			}
			this.loadData();
		}

	}

	reset() {
		this.charttitle = 'Brand Sales';
		this.brands.setValue([]);
		this.lineid.setValue([]);
		this.loadData();
	}

	ngOnDestroy() {
		if(this.chart) {
			this.chart.destroy();
		}

		if(this.dataload) {
			this.dataload.unsubscribe();
		}
	}

}

import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchasingService } from '../services/purchasing.service';
import { VendorService } from '../services/vendor.service';

import { Location } from '@angular/common'

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { UsersService } from 'app/services/users.service';


@Component({
	selector: 'app-vendors',
	templateUrl: './vendors.component.html',
	styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('vendorsearch') customersearch: ElementRef;
	vendors: any = [];
	title = 'vendors';
	color: any = '';
	keywords: any = '';
	ready: boolean;
	filteredItems: any = [];
	results: any = [];
	vendosubscription: any = false;
	headercolumns: string[] = [
		'actions',
		'supplierid',
		'suppname',
		'address1',
		'telephone',
		// 'disabled'
	];
	searchForm: UntypedFormGroup;
	includeDisabled: any = false;
	inactive: any = false;
	user: any;

	constructor(private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, public vendorService: VendorService, public router: Router, public location: Location, private userService: UsersService) { }

	ngOnInit(): void {

		this.user = this.userService.getLocalUser();


		this.ready = true;
		this.color = this.globalSearchService.getColor();

		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
		formGroup.addControl('disabled', new UntypedFormControl(false, []), {})
		this.searchForm = formGroup;

		this.vendorService.getInactiveType().subscribe((res) => {
			this.inactive = res;
		})
		this.getAllVendors();

		this.searchForm.get('disabled').valueChanges.subscribe((newV) => {
			const data = { inc_disabled: newV }
			const search = this.customersearch.nativeElement.value;
			if (search == '') {
				this.getAllVendors(data);
			} else {
				this.filterVendors({ target: { value: search } });
			}
		})
	}

	getAllVendors(data?) {
		if(data == undefined){
			data = { inc_disabled: false }
		}

		if (this.vendosubscription) {
			this.vendosubscription.unsubscribe();
		}

		this.vendosubscription = this.vendorService.getVendorsPost(data).subscribe((results: any) => {
			// results = this.filterDisabled(results);
			this.vendors = new MatTableDataSource(results);
			this.results = results
			this.vendors.sort = this.sort;
			this.vendors.paginator = this.paginator;
			this.globalSearchService.vendorData.next(this.vendors);
		});
	}



	filterVendors(input: any) {
		this.ready = false;
		fromEvent(this.customersearch.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe();

		if (this.vendosubscription) {
			this.vendosubscription.unsubscribe();
		}

		this.vendosubscription = this.vendorService.getVendorSearch(input.target.value).subscribe((results: any) => {
			if (results.length > 1) {
				this.vendors = new MatTableDataSource(results);
				this.results = results
				this.vendors.sort = this.sort;
				this.vendors.paginator = this.paginator;
			} else {
				this.router.navigate(['./vendors/' + results[0].supplierid]);
			}


			this.globalSearchService.vendorData.next(this.vendors);
		});
	}

	ngOnDestroy(): void {

	}

	exportPdf() {
		// import("jspdf").then(jsPDF => {
		///import("jspdf-autotable").then(x => {
		// const doc = new jsPDF.default(0,0);
		// doc.autoTable(this.header, this.vendors);
		//doc.save('products.pdf');
		//})
		// })
	}

	exportExcel() {
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(this.vendors);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, 'products');
		});
	}

	saveAsExcelFile(buffer: any, fileName: string): void {
		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	back(): void {
		this.location.back()
	}

	selectVendor(row: any) {
		this.router.navigate(['./vendors/' + row.supplierid]);
	}


	ngAfterViewInit() {
		this.vendors.sort = this.sort;
		this.vendors.paginator = this.paginator;
	}

	announceSortChange(sortState: Sort) {
	}

	filterDisabled(input: any) {
		const out = [];
		input.map((entry) => { if (this.includeDisabled) { out.push(entry) } else { if (entry.supptype != this.inactive.typeid) { out.push(entry) } } });
		return out;
	}

}

import { Component, OnDestroy, OnInit, ViewChild, OnChanges, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { OrdersService } from '../../../services/orders.service';
import { ReportsService } from '../../../services/reports.service';
import { CardLists, Card } from '../../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { InventoryService } from 'app/services/inventory.service';
import { interval, Subscription, Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
	selector: 'app-vrpt-content',
	templateUrl: './vrpt-content.component.html',
	styleUrls: ['./vrpt-content.component.scss']
})
export class VrptContentComponent implements OnInit {

	@Input() vendor;

	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('print_tablexlsx') printtablexlsx: ElementRef;
	@ViewChild('print_tablepdf') printtablepdf: ElementRef;
	@ViewChild('salesFilter') salesmanRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;
	@ViewChild('categoryFilter') categoriesRef: MatSelect;
	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');
	sortByRef = new UntypedFormControl('name');
	categoryRef = new UntypedFormControl('');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		todate: '',
		locs: [],
		salesperson: [],
		conbr: '',
		placedfrom: '',
		sortby: '',
		categories: [],
		supplierid: ''
	};

	@Input() name: string;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	filename: any = 'Vendor_sales_by_branch_';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	sending: any = false;
	branches: any = [];
	salespeople: any = [];
	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	sortby: any = [{ label: 'Name', value: 'name' }, { label: 'Totals', value: 'totals' }];
	categories: any = [];
	dowloading = false;

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 15, 500];
	pagesizedefault = 5;

	ngOnInit(): void {
		this.setPagination([]);
	}

	constructor(private _changeDetectorRef: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal, private inventoryService: InventoryService) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.mesagesallowed.next(false);
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
			// const all = results.map(branch => branch.loccode);
			// this.branchRef.setValue(all);
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
			// const all = results.map(salesman => salesman.salesmancode);
			// this.salespersonRef.setValue(all);
		});

		this.inventoryService.getItemCategories().subscribe((results: any) => {
			this.categories = results;
			// const all = results.map(cats => cats.categoryid);
			// this.categoryRef.setValue(all);
		});

		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)



	}

	public getCRPT() {
		if(!this.vendor){
			this.vendor = [];
		}
		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			locs: this.branchRef.value,
			conbr: this.conBrRef.value,
			placedfrom: this.placedFromRef.value,
			salesperson: this.salespersonRef.value,
			sortby: this.sortByRef.value,
			categories: this.categoryRef.value,
			supplierid: this.vendor.supplierid == undefined ? '' : this.vendor.supplierid
		};

		this.orderResults = false;

		this.sending = true;
		this.reportsService.getVRPT(this.reportCriteria).subscribe(r => {
			this.orderResults = r;
			this.sending = false;
			this.setPagination(r.lines);
		})

	}

	viewCustomer(sp: any, loccode: any) {
		const drilldown = {
			vendor: sp,
			loc: loccode,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr,
			placedfrom: this.reportCriteria.placedfrom,
			salesperson: this.reportCriteria.salesperson,
			supplierid: this.vendor.supplierid
		};
		this.reportsService.getVRPTDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
		case 'Credit':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((r: any) => {
				this.displaydetails = r;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case 'Invoice':
			this.globalsService.getInvoice(transaction.id, display).subscribe((r: any) => {
				this.displaydetails = r;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})

			break;
		}
	}
	/*
	exportPdf() {
		const d = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtablepdf.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + d.getMonth() + d.getDate() + d.getFullYear(),
			title: `Vendor Sales By Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate}`,
			subtitle: this.getSubTitle() + ' Created ' + d.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const d = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtablexlsx.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + d.getMonth() + d.getDate() + d.getFullYear(),
			title: `Vendor Sales By Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate}`,
			subtitle: this.getSubTitle() + ' Created ' + d.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	*/
	
	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
	
	exportPdf() {
		this.dowloading = true;
		const d = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateAndEncodeHTML());
		const data = {
			content: encoded,
			filename: this.filename + d.getMonth() + d.getDate() + d.getFullYear(),
			title: `Vendor Sales By Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate}`,
			subtitle: this.getSubTitle() + ' Created ' + d.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.dowloading = false;
		});
	}
	
	exportXls() {
		this.dowloading = true;
		const d = new Date();
		const html = this.generateAndEncodeHTML();

		const encoded: string = this.globalSearchService.base64encode(html);
		const data = {
			content: encoded,
			filename: this.filename + d.getMonth() + d.getDate() + d.getFullYear(),
			title: `Vendor Sales By Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate}`,
			subtitle: this.getSubTitle() + ' Created ' + d.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.dowloading = false;
		});
	}
	getSubTitle() {
		let out = '';
		this.reportCriteria.conbr == 'C' ? out += 'Consolidated Report' : out += 'Branch Report';
		if (this.reportCriteria.locs.length == this.branches.length || this.reportCriteria.locs.length == 0) {
			out += ' For All Locations'
		} else {
			out += ' For Locations: ';
			(this.reportCriteria.locs).forEach((loc) => {
				out += loc + ', ';
			});
			out = out.slice(0, -2);
		}
		if (this.reportCriteria.salesperson.length == this.salespeople.length || this.reportCriteria.salesperson.length == 0) {
			out += ' and All Salespeople'
		} else {
			out += ' and Salesperson(s): ';
			(this.reportCriteria.salesperson).forEach((person) => {
				out += person + ', ';
			});
			out = out.slice(0, -2);
		}
		if (this.reportCriteria.placedfrom == '') {
			out += ' Including Web Orders';
		}
		if (this.reportCriteria.placedfrom == '1') {
			out += ' Excluding Web Orders ';
		}
		if (this.reportCriteria.placedfrom == '2') {
			out += ' Only Web Orders';
		}
		return out;
	}

	back(): void {
		this.location.back()
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch (filter_name) {
		case 'salesman':
			filter = this.salesmanRef;
			break;
		case 'branch':
			filter = this.branchesRef;
			break;
		case 'category':
			filter = this.categoriesRef;
			break;
		default:
			return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}

	getCatInfo(id: any) {
		let out = '';
		this.categories.forEach(i => {
			if (id == i.categoryid) {
				out = `(${id}) ${i.categorydescription}`;
			}
		});
		return out;
	}

	getBranchName(loc: any) {
		if (loc == 99) { return 'Branch 99' }
		let out = ''
		this.branches.forEach(b => {
			if (b.loccode == loc) {
				out = b.locationname;
			}
		});
		return out;
	}

	ngOnChanges(changes: any) {

		if (changes.vendor) {

		}
	}
	generateAndEncodeHTML(): string {
	  let htmlString = '<table class="table table-sm table-hover"><thead><tr><th style="background-color: #183d78; color: white;">BR</th><th style="background-color: #183d78; color: white;">Vendor</th><th style="background-color: #183d78; color: white;">Vendor Name</th><th style="background-color: #183d78; color: white;" class="text-right">Sales</th></tr></thead><tbody>';
	  
	  this.orderResults.lines.forEach((o: any) => {
			htmlString += `<tr><th colspan="4"> Branch: ${this.getBranchName(o.details[0].BR)} </th></tr>`;
			o.details.forEach((line: any) => {
		  htmlString += `<tr><td><b>${line.BR}</b></td><td><b>${line.Vendor}</b></td><td>${line.Vendorname}</td><td class="text-right">${this.financial(line.Sales)}</td></tr>`;
			});
			if (o.name !== 'ALL') {
		  htmlString += `<tr class="pl-rollup"><th></th><th></th><th class="text-center">${this.getBranchName(o.details[0].BR)} Total: </th><th class="text-right">${this.financial(o.total)}</th></tr>`;
			}
	  });

	  htmlString += `<tr><th></th><th class="text-center">Count : ${this.orderResults.totalrecords}</th><th class="text-center">Avg: ${this.financial(this.orderResults.avg)}</th><th class="text-right">Total: ${this.financial(this.orderResults.total)}</th></tr></tbody></table>`;
		//Category Listing
	htmlString += '<table class="table table-sm table-hover w-100"><thead><tr><th colspan="9"><h4>Categories Selected</h4></th></tr></thead><tbody>';
	
		if(this.reportCriteria.categories.length > 0){
		
		this.reportCriteria.categories.forEach((c: any) => {
		htmlString+= `<tr><td>${this.getCatInfo(c)}</td></tr>`;
		});
		} else if (this.reportCriteria.categories.length == 0){
		this.categories.forEach((c: any) => {
		htmlString+= `<tr><td>${c.categoryid}&nbsp;${c.categorydescription}</td></tr>`;
		});
		}
		
		htmlString+= '</tbody></table>';
		
	  return htmlString;
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}
}

<ng-template #monthDetails let-modal>
	<div class="modal-header">
		<h4> {{ monthtitle }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="table mt-3" *ngIf="results">
			<div class="table">
				<table class="table table-striped table-hover" *ngIf="monthdetails">
					<tr>
						<th>DOCUMENT#</th>
						<th>SOURCE</th>
						<th>TYPE</th>
						<th>DATE</th>
						<th>ITEM#</th>
						<th>DESC</th>
						<th class="text-center">DISCOUNT</th>
						<th class="text-center">QTY</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of monthdetails">
						<td>
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>
								<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10'">
									<mat-icon>history</mat-icon>
									<span>Credit</span>
								</button>
							</mat-menu>
							{{ item.transno }}
						</td>
						<td>
							<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'beauty'">
								<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
								<ng-container *ngSwitchCase="2">POS</ng-container>
								<ng-container *ngSwitchCase="3">SALES PERSON</ng-container>
								<ng-container *ngSwitchCase="4">WEB</ng-container>
								<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
							</ng-container>
							<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'tires'">
								<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
								<ng-container *ngSwitchCase="2">AMI</ng-container>
								<ng-container *ngSwitchCase="4">WEB</ng-container>
								<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
							</ng-container>
						</td>
						<td>{{ item.typename }}</td>
						<td>{{ item.trandate }}</td>
						<td><a [routerLink]="'/inventory/view/'+item.stockid">{{ item.stockid }}</a></td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.discountpercent | percent }}</td>
						<td class="text-center">{{ item.qty }}</td>
						<td class="text-right">{{item.price | currency }}</td>
						<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
					</tr>
				</table>
				<h4 class="text-center" *ngIf="!results.items">
					No Results
				</h4>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<mat-tab-group (selectedTabChange)="loadData()" >
	<mat-tab label="Item History">
		<ng-template matTabContent>
		<h3> Item History </h3>
		<ng-container >
			<button class="btn btn-success btn-sm" [disabled]="sending || (!results)" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
			&nbsp;&nbsp;
			<button class="btn btn-danger btn-sm" [disabled]="sending || (!results && !results?.length)" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
		</ng-container>
		<div class="row m-0 p-0">
			<div class="col-md-2">
				<mat-form-field appearance="outline">
					<mat-label>Warehouse</mat-label>
					<mat-select [formControl]="defaultlocation" multiple appSelectAll>
						<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
							{{ loc.locationname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="">
					<mat-label>Brand</mat-label>
					<mat-select [formControl]="brands" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="">
					<mat-label>Category</mat-label>
					<mat-select [formControl]="categoryid" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.categories" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="">
					<mat-label>Product Line</mat-label>
					<mat-select [formControl]="lineid" multiple appSelectAll>
						<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3 mr-0">
				<mat-form-field appearance="outline" class="text-right">
					<mat-label>Transaction Dates</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
						<input matEndDate [formControl]="todateCtrl" placeholder="End date">
					</mat-date-range-input>
					<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
			<div class="col-md-4 ">
				<mat-form-field appearance="outline">
					<input type="text" class="text-right p-0 m-0" placeholder="Search Items " aria-label="Notes" #itemsearch [formControl]="search" (keyup.enter)="loadData()" matInput />
					<span matSuffix>
						<button tabindex="-1" mat-icon-button color="white">
							<mat-icon (click)="loadData()">search</mat-icon>
						</button>
					</span>
				</mat-form-field>
			</div>
			<div class="col-md-2 ml-auto mr-0">
				<span *ngIf="sending">
					<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</span>
				<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="loadData()" *ngIf="!sending">
					<mat-icon>refresh</mat-icon>
				</button>
			</div>
		</div>
		<mat-paginator
		  [pageSizeOptions]="items_per_page"
		  [pageSize]="100"
		></mat-paginator>

		<div class="table mt-2" #print_items>
			<table class="table table-striped table-fixed table-hover" *ngIf="results">
				<thead>
				<tr>
					<th>DOCUMENT#</th>
					<th>TYPE</th>
					<th>LOCATION#</th>
					<th>DATE</th>
					<th>ITEM#</th>
					<th>DESC</th>
					<th class="text-center">DISCOUNT</th>
					<th class="text-center">QTY ORDERED</th>
					<th class="text-center">QTY REC</th>
					<th class="text-right">PRICE</th>
					<th class="text-right">TOTAL</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of dataObs | async">
					<td>
						<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
								<mat-icon>visibility</mat-icon>
								<span>View HTML</span>
							</button>
							<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
								<mat-icon>visibility</mat-icon>
								<span>View PDF</span>
							</button>
							<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10'">
								<mat-icon>history</mat-icon>
								<span>RMA/Credit</span>
							</button>
						</mat-menu>
						{{ item.transno }}
					</td>
					<td>{{ item.typename }}</td>
					<td>{{ item.loccode }}</td>
					<td>{{ item.trandate }}</td>
					<td>{{ item.stockid }}</td>
					<td>{{item.description}}</td>
					<td class="text-center">{{item.discountpercent | percent }}</td>
					<td class="text-center">{{ item.quantityord }}</td>
					<td class="text-center">{{ item.quantityrecd }}</td>
					<td class="text-right">{{item.price | currency }}</td>
					<td class="text-right">{{ getLineTotal(item) | currency }}</td>
				</tr>
				<tr>
					<td>TOTAL</td>
					<td></td>
					<td></td>

					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td ></td>
					<td class="text-center">{{ getItemTotals() }} </td>
					<td></td>
					<td class="text-right">{{ getDollarTotals() | currency }}</td>
				</tr>
				</tbody>
			</table>
			<h4 class="text-center" *ngIf="!results">
				No Results
			</h4>
		</div>
	</ng-template></mat-tab>
	<mat-tab label="Brands">
		<ng-template matTabContent>
			<app-brand-report [vendor]="vendordata" [menuapp]="false"></app-brand-report>
		</ng-template>
	</mat-tab>
	<mat-tab label="CVSLS">
		<ng-template matTabContent>
			<app-cvsls-content [vendor]="vendordata"></app-cvsls-content>
		</ng-template>
	</mat-tab>
	<mat-tab label="VRTP">
		<ng-template matTabContent>
			<app-vrpt-content [vendor]="vendordata"></app-vrpt-content>
		</ng-template>
	</mat-tab>

</mat-tab-group>

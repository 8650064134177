<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 " *ngIf="vendor_data">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title">
							<i class="material-icons" (click)="back()">arrow_left</i>
							&nbsp;<button mat-icon-button [cdkCopyToClipboard]="vendor_data.supplierid"><small><mat-icon>content_copy</mat-icon></small> </button>
							<span class="bold">{{ vendor_data.supplierid }}: {{ vendor_data.suppname }}</span>
						</h4>
					</div>
					<div class="card-body">
						<app-vendor-lookup (vendor_selected)="vendorSelected($event)" [nonstock]="false"></app-vendor-lookup>
						<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" [selectedIndex]="activeIndex" (selectedIndexChange)="updateActiveIndex($event)">

							<mat-tab label="Vendor Info">
								<ng-container>
									<div class="row m-0 p-0">
										<div class="card col-md-4 mr-md-0 card-editor ">
											<div class="card-header card-header-rose card-header-icon" (click)="toggleVendorEdit('')">
												<div class="card-icon">
													<i class="material-icons">credit_card</i>
												</div>
												<h4 class="card-title">Pay To</h4>
											</div>
											<div class="card-body" *ngIf="vendor_data">
												<p [innerHTML]="vendor_data.address" *ngIf="!editing_vendor"  class="bolder-to"></p>
												<app-vendor-edit [vendor]="vendor_data" *ngIf="editing_vendor" (newvendordata)="updateVendor($event)" (editing)="toggleVendorEdit($event)"></app-vendor-edit>
											</div>
										</div>
										<div class="card col-md-4  mr-md-0 card-editor " [ngClass]="vendor_data.typename == 'Inactive' ? 'inactive' : ''">
											<div class="card-header card-header-success card-header-icon" (click)="toggleVendorAddOn('')">
												<div class="card-icon">
													<i class="material-icons">local_shipping</i>
												</div>
												<h4 class="card-title">Details</h4>
											</div>
											<div class="card-body" *ngIf="vendor_data" >

												<app-vendor-addon [vendor]="vendor_data" *ngIf="editing_vendor_addon" (newvendordata)="updateVendor($event)" (editing)="toggleVendorAddOn($event)"></app-vendor-addon>

												<ul class="entry-list p-0 m-0" *ngIf="!editing_vendor_addon">
													<li>
														<div class="spec-label bg-white">
															Phone:
														</div>
														<div class="spec-value">
															{{ vendor_data.telephone }}
														</div>
													</li>
													<li>
														<div class="spec-label bg-white">
															Tax Id:
														</div>
														<div class="spec-value">
															{{ vendor_data.taxref }}
														</div>
													</li>
													<li>
														<div class="spec-label bg-white">
															Email:
														</div>
														<div class="spec-value">
															{{ vendor_data.email }}
														</div>
													</li>
													<li>
														<div class="spec-label bg-white">
															Fax:
														</div>
														<div class="spec-value">
															{{ vendor_data.fax }}
														</div>
													</li>
													<li>
														<div class="spec-label bg-white">
															url:
														</div>
														<div class="spec-value">
															{{ vendor_data.url }}
														</div>
													</li>
												</ul>

											</div>
										</div>
										<div class="card col-md-4  mr-md-0 card-editor ">
											<div class="card-header card-header-danger card-header-icon" (click)="toggleVendorEditAccount('')">
												<div class="card-icon">
													<i class="material-icons">account_circle</i>
												</div>
												<h4 class="card-title">Account Payable</h4>
											</div>
											<div class="card-body" *ngIf="vendor_data">
												<p *ngIf="!editing_vendor_account">
													Terms: {{ vendor_data.terms }}<br>
													Bank Account:{{ vendor_data.bankaccountname }}<br />
													Currency:{{ vendor_data.currcode }} <br />
													Tax Group:{{ vendor_data.taxgroupdescription }}

												</p>


												<app-vendor-aging [aged]="vendor_data.aged" class="w-100"></app-vendor-aging>

												<app-vendor-editaccount [vendor]="vendor_data" *ngIf="editing_vendor_account" (newvendordata)="updateVendor($event)" (editing)="toggleVendorEditAccount($event)"></app-vendor-editaccount>
											</div>
										</div>
										<div class="card col-md-4  card-editor" [ngClass]="editing_contacts ? 'col-md-12':'col-md-4 card-editor'">
											<div class="card-header card-header-warning card-header-icon">
												<div class="card-icon" (click)="toggleContactEdit('')">
													<i class="material-icons">analytics</i>
												</div>
												<h4 class="card-title">Contacts & Info:</h4>
											</div>
											<div class="card-body">
												<app-vendor-contacts [vendor]="vendor_data" *ngIf="editing_contacts" (newvendordata)=updateVendor($event) (editing)="toggleContactEdit($value)"></app-vendor-contacts>

												<ng-container *ngIf="!editing_contacts">
													<ul class="entry-list p-0 m-0" *ngIf="!editing_vendor_addon">
														<li>
															<div class="spec-label bg-white">
																Supplier Since:
															</div>
															<div class="spec-value">
																{{ vendor_data.suppliersince }}
															</div>
														</li>
														<li>
															<div class="spec-label bg-white">
																Last Paid:
															</div>
															<div class="spec-value">
																{{ vendor_data.lastpaid | currency}}
															</div>
														</li>
														<li>
															<div class="spec-label bg-white">
																Last Paid Date:
															</div>
															<div class="spec-value">
																{{ vendor_data.lastpaiddate }}
															</div>
														</li>
													</ul>

													<span *ngIf="!vendor_data.contacts">N/A</span>
													<ul @openClose class="list-group border-1 " *ngIf="vendor_data.contacts">
														<li class="list-group-item m-0 p-0" *ngFor="let contact of vendor_data.contacts ">
															<span>{{ contact.contactname }}, {{ contact.role }}, {{contact.email}} , {{ contact.phoneno }} {{contact.notes}}</span>
														</li>
													</ul>
												</ng-container>
											</div>
										</div>
										<div class="card col-md-4  card-editor" [ngClass]="editing_settings ? 'col-md-4':'col-md-4'">
											<div class="card-header card-header-info card-header-icon">
												<div class="card-icon" (click)="toggleVendorSettings('')">
													<i class="material-icons">phonelink_setup</i>
												</div>
												<h4 class="card-title">Setup:</h4>
											</div>
											<div class="card-body">
												<ng-container *ngIf="!editing_settings">
													<ul class="entry-list p-0 m-0" *ngIf="!editing_vendor_addon">
														<li>
															<div class="spec-label bg-white">
																Margin:
															</div>
															<div class="spec-value">
																{{ vendor_data.gp_margin | percent}}
															</div>
														</li>
														<li>
															<div class="spec-label bg-white">
																Supplier Type:
															</div>
															<div class="spec-value">
																{{ vendor_data.typename }}
															</div>
														</li>
													</ul>
												</ng-container>
												<app-vendor-settings [vendor]="vendor_data" *ngIf="editing_settings" (newvendordata)=updateVendor($event) (editing)="toggleVendorSettings($value)"></app-vendor-settings>
											</div>
										</div>
										<div class="card col-md-4  card-editor" [ngClass]="editing_notes ? 'col-md-4':'col-md-4'">
											<div class="card-header card-header-secondary card-header-icon">
												<div class="card-icon" (click)="toggleVendorNotes('')">
													<i class="material-icons">notes</i>
												</div>
												<h4 class="card-title">Notes:</h4>
											</div>
											<div class="card-body">
												<app-vendor-notes [vendor]="vendor_data" *ngIf="editing_notes" (newvendordata)=updateVendor($event) (editing)="toggleVendorNotes($value)"></app-vendor-notes>

												<ul class="entry-list p-0 m-0" *ngIf="!editing_notes">
													<li *ngFor="let note of vendor_data.notes">
														<div class="spec-label bg-white">
															{{ note.userid }} {{ note.notedate }}:
														</div>
														<div class="spec-value">
															{{ note.note }}
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</ng-container>
							</mat-tab>
							<mat-tab label="Transactions">
								<ng-template matTabContent>
									<app-vendor-transactions [vendor]="vendor_data" (newvendordata)=updateVendor($event) (editing)="toggleVendorNotes($value)"></app-vendor-transactions>
								</ng-template>
							</mat-tab>
							<mat-tab label="Vendor Item Lookup / PO">
								<ng-template matTabContent preserveContent="true">
									<app-shared-purchase-order-entry [vendor]="vendor_data" [flip]="'inactive'" [invoicing]="invoicing" (reloadVendor)="loadVendor($event.header.supplierno)" class="w-100 no-overflow"></app-shared-purchase-order-entry>

								</ng-template>
							</mat-tab>
							<mat-tab label="Reports">
								<ng-template matTabContent preserveContent="true">
									<app-vendor-reports [vendordata]="vendor_data" *ngIf="vendor_data"></app-vendor-reports>
								</ng-template>
							</mat-tab>
							<mat-tab label="Purchase Orders ({{pos.length}})">
								<!--<ng-template matTabContent> -->
									<!--(newvendordata)=updateVendor($event) (editing)="toggleVendorNotes($value)" -->
									<app-open-purchase-orders [vendor]="vendor_data"  (orderloaded)="selectItemLookup($event)" (po_data)="updatePoData($event)"></app-open-purchase-orders>
								<!--</ng-template>-->
							</mat-tab>

							<mat-tab label="Open RGAs ({{rgas.length}})">
								<!--<ng-template matTabContent> -->
									<!--(newvendordata)=updateVendor($event) (editing)="toggleVendorNotes($value)" -->
									<app-open-rgas [vendor]="vendor_data"  (orderloaded)="selectRgaLookup($event)" (po_data)="updateRgaData($event)" ></app-open-rgas>
								<!--</ng-template>-->
							</mat-tab>

							<mat-tab label="Add Vendor Return">
								<ng-template matTabContent>
									<app-vendor-return [vendor]="vendor_data"  (orderloaded)="selectItemLookup($event)" (po_data)="updatePoData($event)" (reloadVendor)="loadVendor($event)"></app-vendor-return>
								</ng-template>
							</mat-tab>
							<mat-tab label="Add Vendor Invoice">
								<ng-template matTabContent>
									<app-vendor-invoice [vendor_data]="vendor_data"></app-vendor-invoice>
								</ng-template>
							</mat-tab>
							<mat-tab label="Add Vendor Payment">
								<ng-template matTabContent>
									<app-vendor-payment-entry [vendor]="vendor_data"></app-vendor-payment-entry>
								</ng-template>
							</mat-tab>



							<mat-tab label="Add Vendor Credit">
								<ng-template matTabContent>
									<app-vendor-credit [vendor]="vendor_data"></app-vendor-credit>
								</ng-template>
							</mat-tab>


							<!--
							<mat-tab label="Add Vendor Return">
								<ng-template matTabContent>
									<app-vendor-return [vendor]="vendor_data"></app-vendor-return>
								</ng-template>
							</mat-tab>
							-->

						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

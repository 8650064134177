import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef ,AfterViewInit,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { InventoryService } from '../../services/inventory.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import * as moment from 'moment';


@Component({
	selector: 'app-warehouse-bins',
	templateUrl: './warehouse-bins.component.html',
	styleUrls: ['./warehouse-bins.component.scss']
})
export class WarehouseBinsComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('stockid') stockidField: ElementRef;

	itemForm: UntypedFormGroup;
	itemlist : any;
	bins: any = [];
	item : any;
	ordertitle : any;
	header : any;
	binitems : any;
	orderdetails: any;
	showlist: boolean;
	showorder: boolean;
	showbin: any = false;
	editing_bin = false;
	bin_data: any = [];
	headercolumns: any = [
		  'location',
		  'count',
		  'totalitems'
	  ];
	binsearch: any = [];

	current_application: any = {
		editing: false,
		merging: false,
		creating: false,
		listing: true,
		history: false,
	}

	color = '';


	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private globalSearchService: GlobalSearchService) {

		this.color = this.globalSearchService.getColor();
	}



	ngOnInit(): void {
		this.showbin = false;
		this.showlist = false;
		this.itemForm = this.formBuilder.group({
			stockid: [''],
		});

		this.inventoryService.getBins().subscribe((bins: any) => {

			this.showlist = true;
			this.bin_data = bins;
			this.bins = new MatTableDataSource(bins);
			this.bins.sort = this.sort;
			this.bins.paginator = this.paginator;
		});

		//this.toggleEditing();
	}

	toggleHistory() {
		this.current_application.history = (this.current_application.history) ? false: true;
		this.current_application.merging = false;
		this.current_application.creating = false
		this.current_application.editing = false;
		this.current_application.listing = (this.current_application.history) ? false :  true;
	}

	toggleMerging() {
		this.current_application.merging = (this.current_application.merging) ? false: true;
		this.current_application.history = false;
		this.current_application.creating = false
		this.current_application.editing = false;
		this.current_application.listing = (this.current_application.merging) ? false :  true;
	}

	toggleEditing() {
		this.current_application.creating = false;
		this.current_application.history = false;
		this.current_application.editing = (this.current_application.editing) ? false: true;


		this.current_application.merging = false;

		this.current_application.listing = (this.current_application.editing) ? false :  true;

	}

	toggleCreating() {
		this.current_application.history = false;
		this.current_application.creating = (this.current_application.creating) ? false: true;
		this.current_application.editing = false;
		this.current_application.listing = (this.current_application.creating) ? false :  true;
		this.current_application.merging = false;
	}

	changingBin(bin: any) {
		const index = this.bin_data.indexOf(bin);
		this.bin_data[index].editing = true;

	}

	changeBinName(event: any, bin :any) {
		//rename local and push to api
		const index = this.bin_data.indexOf(bin);
		const original_bin = this.bin_data[index].location;
		this.bin_data[index].location = event.target.value;

		this.inventoryService.changeBin(original_bin, event.target.value ).subscribe((result: any) => {
			this.bin_data = result;
			this.bins = new MatTableDataSource(result);
			this.bins.sort = this.sort;
			this.bins.paginator = this.paginator;
		});
	}

	moveItem(event: any) {

	}

	itemSearch(event: any) {

	}

	binSearch(event: any) {

		const results = this.filterItem(this.bin_data, event.target.value, 'location');
		this.bins = new MatTableDataSource(results);
		this.bins.sort = this.sort;
		this.bins.paginator = this.paginator;
	}

	back(): void {
		this.location.back()
	}

	showBin(bin) {
		this.binitems = [];
		this.inventoryService.getBinItems(bin).subscribe((results: any) => {
			this.binitems = results
			this.showbin = bin;

		});

	}
	
	loadItem(item) {
		this.inventoryService.lookup({ stockid: item }).subscribe((ritem: any) => {
			this.item = ritem
			this.showlist = false;
		});
	}

	setFiltered(results) {
		this.binsearch = results;
	}

	assignCopy(input){
		this.binsearch = Object.assign([], input);
	}

	filterItem(input, term , keys ){
		if(!term){
			this.assignCopy(input);
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	announceSortChange(event: any) {

	}

	ngAfterViewInit() {
		  this.bins.sort = this.sort;
		  this.bins.paginator = this.paginator;
	  }
}

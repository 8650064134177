<ng-container>
<form @grow [formGroup]="itemForm"  (ngSubmit)="onSubmit()" class="needs-validation product-add"  *ngIf="itemForm">
	<div class="card-header">
		<div class="col-12 text-right">
			<button class="ml-auto mr-0" mat-button
			mat-raised-button [color]="itemForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>

		</div>
	</div>
	<div class="row">
		<div class="row mt-2">
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Description</mat-label>
					<input matInput required value="" formControlName="description">
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Long Description</mat-label>
					<input matInput required value="" formControlName="longdescription">
				</mat-form-field>
				<ng-container *ngIf="config.env.package == 'tires'">
					<mat-form-field appearance="outline" class="">
						<mat-label>Designation</mat-label>
						<mat-select formControlName="designation">
							<mat-option *ngFor="let k of properties.designations" [value]="k.designation_id">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline" class="">
						<mat-label>Season</mat-label>
						<mat-select formControlName="winter">
							<mat-option *ngFor="let k of properties.seasons" [value]="k.value">
								{{ k.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-container>
				<mat-form-field appearance="outline" class="">
					<mat-label>Brand</mat-label>
					<mat-select formControlName="manufacturer_id">
						<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="">
					<mat-label>Category</mat-label>
					<mat-select formControlName="categoryid">
						<mat-option *ngFor="let k of properties.categories" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="">
					<mat-label>Product Line</mat-label>
					<mat-select formControlName="lineid">
						<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="">
					<mat-label>Product Type</mat-label>
					<mat-select formControlName="mbflag">
						<mat-option *ngFor="let k of properties.product_types" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="">
					<mat-label>GL Class</mat-label>
					<mat-select formControlName="glclass">
						<mat-option *ngFor="let k of properties.gl_classes" [value]="k.code">
							{{k.code}} - {{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="">
					<mat-label>Unit Type</mat-label>
					<mat-select formControlName="units">
						<mat-option *ngFor="let k of properties.units" [value]="k.label">
							{{ k.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="">
					<mat-label>Tax Category</mat-label>
					<mat-select formControlName="taxcatid">
						<mat-option *ngFor="let k of properties.tax_categories" [value]="k.value">
							{{ k.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

		</div>
	</div>
</form>
</ng-container>

<div class="main-content">
  <div class="container-fluid">
	<div class="card">
		<div class="card-header card-header-{{color}}-5">
			<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Bin Lookup </h4>
					<ng-container *ngIf="bin_data.length">



						<!--
						<button mat-button (click)="toggleCreating()">Move Items To Bin</button>
						<button mat-button (click)="toggleMerging()">Merge/Change Bins</button>
						<button mat-button (click)="toggleEditing()">Move Bin Items</button>
						<button mat-button (click)="toggleHistory()">Bin Movements <mat-icon>visibility</mat-icon></button>
						-->
					</ng-container>
			<p class="card-category"></p>
		</div>
		<div class="card-body">


			<div id="typography" >
				<div class="card-title">
					<div class="table table-responsive text-center" *ngIf="!showlist">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</div>
					<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" *ngIf="showlist">
					<mat-tab label="Bins">
						<ng-template matTabContent>
							<app-bin-list [(bins)]="bin_data"></app-bin-list>
						</ng-template>
					</mat-tab>
					<mat-tab label="Scan To Bin">
						<ng-template matTabContent>
							<app-bin-item-scan [(bins)]="bin_data"></app-bin-item-scan>
						</ng-template>
					</mat-tab>
					<mat-tab label="Label Maker">

						<ng-template matTabContent>
							<app-bin-labels [(bins)]="bin_data"></app-bin-labels>
						</ng-template>
					</mat-tab>
					<mat-tab label="Merge Bins">
						<ng-template matTabContent>
							<app-bin-merge [(bins)]="bin_data"></app-bin-merge>
						</ng-template>
					</mat-tab>
					<mat-tab label="Move Items">
						<ng-template matTabContent>
							<mat-tab-group mat-align-tabs="start">
								<mat-tab label="By Item">
									<ng-template matTabContent>
										<app-bin-items [(bins)]="bin_data"></app-bin-items>
									</ng-template></mat-tab>
								<mat-tab label="By Bin">
								<ng-template matTabContent>
									<app-bin-edit [(bins)]="bin_data" ></app-bin-edit>
								</ng-template>
							</mat-tab>
							</mat-tab-group>
						</ng-template>
					  </mat-tab>
					  <mat-tab label="Bin Movements">
						  <ng-template matTabContent>
							  <app-bin-history [(bins)]="bin_data"></app-bin-history>
						  </ng-template>
					  </mat-tab>
					   <mat-tab label="Storage Bins">
						  <ng-template matTabContent>
							<app-bin-storage></app-bin-storage>
						  </ng-template>
					  </mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
  </div>
</div>

<div class="row">
	<div class="ml-auto mr-0">
		<ng-container *ngIf="!sending">
			<button class="btn btn-sm btn-success" (click)="exportXls();" *ngIf="data"><i class="fa fa-file-excel-o"></i></button>
			&nbsp;&nbsp;
			<button class="btn btn-sm btn-danger" (click)="exportPdf();" *ngIf="data"><i class="fa fa-file-pdf-o"></i></button>
			&nbsp;&nbsp;
		</ng-container>
		<ng-container *ngIf="sending">
			<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</ng-container>
	</div>
</div>
<div class="table table-responsive table-fixed ">
	<mat-paginator [ngClass]="tabledata ? '': 'd-none'" [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
	<table mat-table class="table table-fixed table-hover" [dataSource]="tabledata" matSort>
		<ng-container matColumnDef="stockid">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Item#</th>
			<td mat-cell *matCellDef="let d">
				<a [routerLink]="'/inventory/view/'+d.stockid">{{ d.stockid }}</a>
			</td>
		</ng-container>
		<ng-container matColumnDef="categoryid">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Category</th>
			<td mat-cell *matCellDef="let d">
				{{ d.categoryid }}
			</td>
		</ng-container>
		<ng-container matColumnDef="lineid">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> ProductLine</th>
			<td mat-cell *matCellDef="let d">
				{{ d.lineid }}
			</td>
		</ng-container>
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Description</th>
			<td mat-cell *matCellDef="let d">
				{{ d.description }}
			</td>
		</ng-container>
		<ng-container matColumnDef="materialcost">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> MaterialCost</th>
			<td mat-cell *matCellDef="let d">
				{{ d.materialcost }}
			</td>
		</ng-container>
		<ng-container matColumnDef="discontinued">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Discontinued</th>
			<td mat-cell *matCellDef="let d">
				{{ d.discontinued }}
			</td>
		</ng-container>
		<ng-container matColumnDef="barcode">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Barcode</th>
			<td mat-cell *matCellDef="let d">
				{{ d.barcode }}
			</td>
		</ng-container>
		<ng-container matColumnDef="image">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Image</th>
			<td mat-cell *matCellDef="let d">
				{{ d.image }}
			</td>
		</ng-container>
		<ng-container matColumnDef="enabled">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled</th>
			<td mat-cell *matCellDef="let d">
				{{ d.enabled }}
			</td>
		</ng-container>
		<ng-container matColumnDef="dwo">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> DWO</th>
			<td mat-cell *matCellDef="let d">
				{{ d.dwo}}
			</td>
		</ng-container>
		<ng-container matColumnDef="sort">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Sort</th>
			<td mat-cell *matCellDef="let d">
				{{ d.sort }}
			</td>
		</ng-container>
		<ng-container matColumnDef="sort2">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> sort2</th>
			<td mat-cell *matCellDef="let d">
				{{ d.sort2 }}
			</td>
		</ng-container>
		<ng-container matColumnDef="date_created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Created</th>
			<td mat-cell *matCellDef="let d">
				{{ d.date_created }}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
	</table>
</div>

<ng-template #salesEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="salesForm">
	<div class="row mt-2">
		<div class="col-md-6 card-group">
			<div class="card m-0">
				<div class="card-header">
					Contact Info
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">

							<mat-slide-toggle formControlName="ismanager">Is Manager?</mat-slide-toggle>

							<mat-form-field appearance="outline">
								<mat-label>Salesperson Code</mat-label>
								<input matInput required value="" formControlName="salesmancode">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Salesperson Name</mat-label>
								<input matInput required value="" formControlName="salesmanname">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Address</mat-label>
								<input matInput value="" formControlName="smanaddr">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>City</mat-label>
								<input matInput value="" formControlName="smancity">
							</mat-form-field>
						</div>
						<div class="col-md-4" *ngIf="zones">
							<mat-form-field appearance="outline">
								<mat-label>Region</mat-label>
								<mat-select formControlName="smanstate">
									<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
										{{zone.code }}
									</mat-option>
								</mat-select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Zip</mat-label>
								<input matInput value="" formControlName="smanzip">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Tablet Number</mat-label>
								<input matInput value="" formControlName="smantab">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Fax Number</mat-label>
								<input matInput value="" formControlName="smanfax">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Cell Number</mat-label>
								<input matInput value="" formControlName="smancell">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Email Address</mat-label>
								<input matInput required value="" formControlName="smanemail">
							</mat-form-field>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="col-md-6 card-group">
			<div class="card m-0">
				<div class="card-header">
					Commission Info
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<div class="col-md-12">
								<mat-form-field appearance="outline">
									<mat-label>Commission Rate 1</mat-label>
									<input matInput value="" formControlName="commissionrate1">
								</mat-form-field>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="col-md-12">
								<mat-form-field appearance="outline">
									<mat-label>Commission Rate 2</mat-label>
									<input matInput value="" formControlName="commissionrate2">
								</mat-form-field>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="col-md-12">
								<mat-form-field appearance="outline">
									<mat-label>Commission Break Point</mat-label>
									<input matInput value="" formControlName="breakpoint">
								</mat-form-field>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="col-md-12">
							<mat-form-field appearance="outline">
									<mat-label>Commission Lock</mat-label>
								<mat-select formControlName="commlock">
									<mat-option value="0">
										Not Locked
									</mat-option>
									<mat-option value="1">
										Locked to Salesman Rates (No Item/Customer Rates)
									</mat-option>
								</mat-select>
							</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6 card-group">
			<div class="card m-0">
				<div class="card-header">
					Control Info
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Include in Reports</mat-label>
								<mat-select formControlName="group_id">
									<mat-option value="1">
										Yes
									</mat-option>
									<mat-option value="2">
										No
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Enabled</mat-label>
								<mat-select formControlName="current">
									<mat-option value="1">
										Yes
									</mat-option>
									<mat-option value="0">
										No
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<div class="row mt-2">
		<button mat-stroked-button color="success" aria-label="" (click)="saveSalesPerson()" class="mt-2">
			<mat-icon>save</mat-icon> Save
		</button>
	</div>

</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<button mat-stroked-button color="success" aria-label="" (click)="addSalesPerson()" class="mt-2 mb-2">
	<mat-icon>add</mat-icon> Add Salesperon
</button>

<table class="table table-striped">
  <thead class="thead-dark">
	<tr>
	  <th>Salesperson#</th>
	  <th>Salesperson Name</th>
	  <th>Address</th>
	  <th>City</th>
	  <th>State</th>
	  <th>Zip</th>
	  <th>Cell</th>
	  <th>Email</th>
	  <th>Active</th>
	  <th>Show in Reports</th>
	  <th>Actions</th>
	</tr>
  </thead>
  <tbody>
	<tr *ngFor="let s of salespeople">
	  <td>{{ s.salesmancode }}</td>
	  <td>{{ s.salesmanname }}</td>
	  <td>{{ s.smanaddr }}</td>
	  <td>{{ s.smancity }}</td>
	  <td>{{ s.smanstate }}</td>
	  <td>{{ s.smanzip }}</td>
	  <td>{{ s.smancell }}</td>
	  <td>{{ s.smanemail }}</td>
	  <td>
		<span *ngIf="s.current == '1'" class="text-success">Yes</span>
		<span *ngIf="s.current != '1'" class="text-danger">No</span>
	  </td>
	  <td>
		<span *ngIf="s.group_id == '1'" class="text-success">Yes</span>
		<span *ngIf="s.group_id != '1'" class="text-danger">No</span>
	  </td>
	  <td>
		<button mat-stroked-button color="primary" (click)="editSalesPerson(s)" class="mr-2">
		  <mat-icon>edit</mat-icon>
		</button>
		<button mat-stroked-button color="warn" (click)="removeSalesPerson(s)">
		  <mat-icon>delete</mat-icon>
		</button>
	  </td>
	</tr>
  </tbody>
</table>

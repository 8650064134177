<div class="table fixTableHead" #print_table>
	<table class="table table-sm table-hover">
		<thead>
		<tr>
			<th>ID#</th>
			<th>Description</th>
			<th>AR GL</th>
			<th>AP GL</th>
			<th>Bank acc</th>
			<th>Always Exempt</th>
			<th>Exempt w/cert</th>
			<th>Tax Freight</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let auth of data">
			<td>{{ auth.taxid }}</td>
			<td>{{ auth.description }}</td>
			<td>{{ auth.taxglcode }}</td>
			<td>{{ auth.purchtaxglaccount }}</td>
			<td>{{ auth.bank }}</td>
			<td>{{ yesno(auth.always_taxable) }}</td>
			<td>{{ yesno(auth.tax_exempt_cert) }}</td>
			<td>{{ yesno(auth.freight) }}</td>
		</tr>
		</tbody>
	</table>
</div>

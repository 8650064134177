import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy, OnChanges, ViewEncapsulation,TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';
import { PrintService } from '../../services/print.service';

import { MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';


@Component({
	selector: 'app-receivables-byshipvia',
	templateUrl: './receivables-byshipvia.component.html',
	styleUrls: ['./receivables-byshipvia.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-359deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(359deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class ReceivablesByshipviaComponent implements OnInit {
	@ViewChild('thirdparty') thirdpartyRef: ElementRef;
	
	@ViewChild('journal_details') journal_detailsRef: ElementRef;
	@ViewChild('confirmPdf', { static: true }) confirmPdfRef: TemplateRef<any>;
	
	@ViewChild('checkno') checknoElement: ElementRef;
	
	flip = 'inactive';
	payment_methods: any = [];
	bankaccounts: any = [];
	today = new Date();
	day_payments: any = [];
	paymentForm: UntypedFormGroup;
	cardForm: UntypedFormGroup;
	selectedCard = new UntypedFormControl('');
	searchByAmount = new UntypedFormControl('');
	searchByInvoice = new UntypedFormControl('');
	searchString = new UntypedFormControl('');
	termscodes = new UntypedFormControl('UPS');
	customerSearchInline = new UntypedFormControl('');
	customerSearchResultsTwo: any = [];
	terms: any = [];
	hidepay: any = true;
	searchByCurrentPayments = new UntypedFormControl('');
	searchByCurrentInvoices = new UntypedFormControl('');
	searchByCurrentInvoicesCust = new UntypedFormControl('');
	holdReason = new UntypedFormControl('');

	searchByCurrentPaymentsDate = new UntypedFormControl('');
	searchByCurrentInvoicesDate = new UntypedFormControl('');
	
	selectAllPayments = new UntypedFormControl(false);
	selectAllInvoices = new UntypedFormControl(false);
	selectInvoiceToPay = new UntypedFormControl(false);
	
	journal_review = new UntypedFormControl('');

	alldebits: any = false;
	allcredits: any = false;

	selected_card = new UntypedFormControl(false);

	//reversed
	singleusecard = new UntypedFormControl(true);
	currency: any = '';
	currencies: any = [];
	company: any = [];

	//paymentss
	datefrom = new Date()
	dateto = new Date()
	running = false;
	total_pay: any = '';
	payments: any = [];
	journal_payments: any = [];
	
	customersearching: any = false;
	customerSearchResults: any = [];
	amountResults: any = false;
	invoiceResults: any = false;
	customer: any = false;
	customertransactions: any = [];

	hasprofile = false;
	payment_added: any = false;
	addingcard: any = false;

	allocations_from: any = [];
	allocations_to: any = [];

	allocation_from_total = 0.00;
	allocation_to_total = 0.00;
	profile_cards: any = [];

	payment_card: any = false;
	single_use: any = false;
	editingcard: any = false;
	sending: any = false;
	color = 'blue';
	charge_card = true;
	submitting = false;
	chargetype = 'existing_card';
	chargecardtype = 'no_charge';
	card_required = false;
	thirdpartylinks: any = false;
	showrefunds = false;
	urlSafe: SafeResourceUrl;
	creditsSelected: any = [];
	debitsSelected: any = [];
	transactions: any = [];
	aged: any = [];
	
	allpayments: any = [];
	alltransactions: any = [];
	holdreasons: any = [];
	openjournals: any = [];
	selectedJournal: any = false;
	journalaction = false;
	allopenjournals: any = [];
	loadingjournal:any = false;

	jounral_sub: any = false;
	
	config: any = false;
	
	constructor(public dialog: MatDialog, private modalService: NgbModal, public printService: PrintService, public sanitizer: DomSanitizer, private ordersService: OrdersService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute, public router: Router) {

		this.color = this.globalSearchService.getColor();
		
		this.globalSearchService.configsubscription.subscribe(s => {
			this.config = s;
		});
		
		this.globalsService.getTerms().subscribe(details => {
			this.terms = details;
		});

	}
	confirmPdfDialog() {
		this.dialog.open(this.confirmPdfRef);
	}

	pdfJournalClose() {

		if(this.journal_review.value.journal_no) {

			const input = false;

			this.sending = true;

			const named = '';

			const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
			const data = {
				content: encoded,
				filename: 'journal_'+this.journal_review.value.journal_no,
			}

			this.printService.pdf(data).subscribe((result: any) => {
				this.globalSearchService.downloadPdf(result.content, data.filename);
				this.sending = false;
				const inputdata = {
					journal: this.journal_review.value
				}

				this.paymentsService.closeJournal(inputdata).subscribe((result: any) => {
					this.openjournals = result;
					this.journalaction = false;

					if (result.length) {
						this.paymentForm.get('journalno').setValue(result[0]);
					} else {
						this.paymentForm.get('journalno').setValue('');
					}

					this.getAllUserCurrentOpenJounrals();

				});
			});

		} else {
			alert('No Journal Selected');
		}
	}

	pdfJournal() {

		if(this.journal_review.value.journal_no) {
			this.sending = true;
			let named = '';
			if(this.journal_review.value.desc1) {
				named = ' '+this.journal_review.value.journal_no+' '+this.journal_review.value.desc1;
			}

			const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
			const data = {
				content: encoded,
				filename: 'journal',
			}

			this.printService.pdf(data).subscribe((result: any) => {
				this.globalSearchService.downloadPdf(result.content, data.filename);
				this.sending = false;
			});
		} else {
			alert('No Journal Selected');
		}
	}

	xlsJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.journal_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'journal',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}
	ngOnInit(): void {
	
		this.paymentForm = this.fb.group({
			debtorno: [''],
			branchcode: [''],
			payment_type: ['', Validators.required],
			bankaccount: ['', Validators.required],
			datepaid: [this.today, Validators.required],
			checkno: [''],
			reference: [''],
			description: [''],
			amount: [0.00, Validators.required],
			currency: ['', Validators.required],
			journalno: [''],
			searchString: [''],
			process_transaction: [false],
			charge_card: [],
			trigger_refund: [false],
		});

		
		this.globalSearchService.bankaccounts.subscribe((accounts: any) => {
			this.bankaccounts = accounts
			if (accounts) {
				this.paymentForm.get('bankaccount').setValue(accounts[0].accountcode);
			}
		});
		
		this.globalSearchService.currencies.subscribe((currencies: any) => {
			this.currencies = currencies
			if (currencies) {
				this.globalSearchService.company.subscribe((company: any) => {
					if (company) {
						this.paymentForm.get('currency').setValue(company.currencydefault);
					}
				});
			}
		});

		this.globalSearchService.payment_methods.subscribe((payment_methods: any) => {
			this.payment_methods = payment_methods
			if (payment_methods) {
				//this.updatePaymentType(payment_methods[0].paymentid);
			}
		});
		this.loadData()
	}

	
	toggleFlip() {
		this.flip = (this.flip == 'active') ? 'inactive' : 'active';
	}
	openNewJournal(event: any) {
		if(event) {
			event.stopPropagation();
			event.preventDefault();
		}

		const data = {};
		this.journalaction = true;
		this.paymentsService.openJournal(data).subscribe((result: any) => {
			this.journalaction = false;
			this.openjournals = result;
			if (result.length) {
				this.paymentForm.get('journalno').setValue(result[0]);
			} else {
				this.paymentForm.get('journalno').setValue('');
			}
			this.getAllUserCurrentOpenJounrals();
		});
	}
	
	changeTerm(){
	
		this.paymentForm.get('amount').setValue('')
		this.paymentForm.get('checkno').setValue('');
		this.paymentForm.get('trigger_refund').setValue(false);
		this.selected_card.setValue(false);

		this.setCardForm();
		this.loadData();
		this.getJournals();
		this.focusTo();

		this.selectAllInvoices.setValue(false);
		this.allocation_from_total = 0.00;
		this.allocation_to_total = 0.00;
		this.allocations_to = [];
		this.allocations_from = [];

		this.updateTotals();
		this.getAllUserCurrentOpenJounrals();
	
	
	
	}
	
	
	loadData() {
		const data = this.termscodes.value;
		this.paymentsService.getCustomerAccountReceivablesByTerm(data).subscribe((results: any) => {
			this.customer = results;
			this.transactions = results.transactions;
			this.aged = results.aged;
			this.allcredits = results.payments
			this.alldebits = results.transactions
			//this.searchString.setValue(results.name);
			this.flip = 'inactive';
		});
	}

	selectCustomer(customer: any) {
		//todo dont do this

		this.customersearching = false;

		this.searchString.setValue(customer.name);
		this.customerSearchInline.setValue(customer.name);
		this.searchByAmount.setValue('');
		this.searchByInvoice.setValue('');
		this.customer = customer;
		//this.router.navigate(['/receivables/payment-entry/' + customer.debtorno + '/' + customer.branchcode]);
	}

	amountSearch(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		this.customersearching = this.paymentsService.getAmountSearch(event).subscribe(async (results: any) => {

			this.amountResults = results;

		});
	}
	hidePayments() {
		this.hidepay = (this.hidepay) ? false : true;
	}
	
	
	currentInvoiceSearchCustomer(event: any) {
		if (event != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alldebits, event, 'debtorno');
			this.transactions = this.customer.transactions;
		} else {
			this.customer.transactions = this.alldebits;
			this.transactions = this.alldebits;
		}
	}
	
	currentInvoiceSearchDate(event: any) {
		if (event != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alldebits, event, 'trandate');
			this.transactions = this.customer.transactions;
		} else {
			this.customer.transactions = this.alldebits;
			this.transactions = this.alldebits;
		}
	}

	currentInvoiceSearch(event: any) {
		if (event != '') {
			this.customer.transactions = this.globalSearchService.filterItem(this.alldebits, event, 'trandate,total,transno');
			this.transactions = this.customer.transactions;
		} else {
			this.customer.transactions = this.alldebits;
			this.transactions = this.alldebits;
		}
	}

	currentPaymentsSearchDate(event: any) {
		if (event != '') {
			this.customer.payments = this.globalSearchService.filterItem(this.allcredits, event, 'trandate');
		} else {
			this.customer.payments = this.allcredits;
		}
	}

	currentPaymentsSearch(event: any) {
		if (event != '') {
			this.customer.payments = this.globalSearchService.filterItem(this.allcredits, event, 'trandate,total,transno');
		} else {
			this.customer.payments = this.allcredits
		}
	}


	invoiceSearch(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		this.customersearching = this.paymentsService.getInvoiceSearch(event).subscribe(async (results: any) => {
			this.invoiceResults = results;
		});
	}

	customerSearch(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		const search = {
			keywords: event
		}

		this.customersearching = this.customerService.getCustomerSearch(search).subscribe(async (results: any) => {
			this.customerSearchResults = results;

			if (results.length == 1) {
				this.selectCustomer(results[0])
				this.customerSearchResults = false;
				this.customerSearchResultsTwo = false;
			}

		});
	}

	customerSearchtwo(event: any) {

		if (this.customersearching) {
			this.customersearching.unsubscribe()
		}

		const search = {
			keywords: event
		}

		this.customersearching = this.customerService.getCustomerSearch(search).subscribe(async (results: any) => {
			this.customerSearchResultsTwo = results;

			if (results.length == 1) {
				this.selectCustomer(results[0])
				this.customerSearchResults = false;
				this.customerSearchResultsTwo = false;
			}

		});
	}
	
	getSelectedJournal(event: any) {
		this.selectedJournal = event.value;
		this.loadSelectedJournal();
	}

	loadSelectedJournal() {
		this.loadingjournal = true;
		if(this.jounral_sub) {
			this.jounral_sub.unsubscribe();
		}
		this.jounral_sub = this.paymentsService.getJournal(this.selectedJournal).subscribe(r => {
			this.loadingjournal = false;
			this.journal_payments = r;
		});
	}
	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
	customerBalanceReduce() {

		if (this.paymentForm.get('amount').value) {
			return this.financial(this.aged.balance) - this.financial(this.paymentForm.get('amount').value)
		}
		return this.financial(this.aged.balance);
	}
	overShort(): number {
		const balance = (this.financial(this.allocation_to_total) + this.financial(this.allocation_from_total));
		return balance;
	}

	applyAmount(): number {

		let balance = (this.financial(this.allocation_to_total) + this.financial(this.allocation_from_total));

		if(balance > 0) {
			balance = this.financial(this.allocation_to_total)
		}
		// if (balance >= 0) {
		// 	balance = 0.00
		// }
		return balance;
	}

	getPaymentTypeName() {
		let name = '';
		if(this.paymentForm.get('payment_type').value != '' && this.paymentForm.get('payment_type').value ) {
			if(this.payment_methods) {
				name = this.payment_methods.filter( p => {
					return p.paymentid == this.paymentForm.get('payment_type').value;
				})[0].paymentname;
			}
		}
		return name;
	}
	paymentTotals(): number {

		let balance = (this.financial(this.allocation_to_total) + this.financial(this.allocation_from_total));

		if(balance > 0) {
			balance = this.financial(this.allocation_from_total)
		}
		// if (balance >= 0) {
		// 	balance = 0.00
		// }
		return balance;
	}
	
	addPaymentMethod(event: any) {
		event.preventDefault();
		if (this.cardForm.valid) {
			this.sending = true;
			this.paymentsService.addCreditCard(this.cardForm.value).subscribe((result: any) => {
				this.sending = false;
				if (!result.success) {
					alert(result.message)
				} else {}
			});
		}
	}
	
	addPayment(event: any) {
		event.preventDefault();
		if (this.paymentForm.valid && this.paymentForm.get('amount').value != 0) {

			let charge_card = false;
			switch (this.chargecardtype) {
			case 'no_charge':
				charge_card = false;
				break;
			case 'new_card':
				charge_card = this.cardForm.value
				break;
			case 'existing_card':
				charge_card = this.selected_card.value
				if(!this.selected_card.value) {
					alert('Please Select a card');
					return false;
				}
				break;
			}

			const data = {
				payment: this.paymentForm.value,
				charge_card: charge_card,
				from: this.allocations_from,
				to: this.allocations_to,
				terms: this.termscodes.value,
				//debtor: this.paymentForm.value
			}
			//reversed values
			this.submitting = true;

			this.paymentsService.addShipViaPayment(data).subscribe((result: any) => {
				this.submitting = false;
				if (result.success) {

					this.single_use = false;
					this.globalSearchService.showNotification('Payment Added', 'success', 'bottom', 'left');
					this.paymentForm.get('amount').setValue('')
					this.paymentForm.get('checkno').setValue('');
					this.paymentForm.get('trigger_refund').setValue(false);
					this.selected_card.setValue(false);

					this.setCardForm();
					this.loadData();
					this.getJournals();
					this.focusTo();

					this.selectAllInvoices.setValue(false);
					this.allocation_from_total = 0.00;
					this.allocation_to_total = 0.00;
					this.allocations_to = [];
					this.allocations_from = [];

					this.updateTotals();
					this.getAllUserCurrentOpenJounrals();

				} else {
					this.globalSearchService.showNotification('Error: ' + result.message, 'danger', 'bottom', 'left');
				}
			});
		} else {
			this.globalSearchService.getFormValidationErrors(this.paymentForm);
		}
	}
	
	setChargeCardType(event: any) {
		if(event.value) {
			this.chargecardtype = event.value
		}
	}

	setChargeType(event: any) {
		if(event.value) {
			this.chargetype = event.value
			this.updatePaymentType(event.value)
			this.setCardRequired(event.value);
		}

	}
	setCardForm() {
		this.cardForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			ccnumber: ['', Validators.required],
			expiry: ['', Validators.required],
			card_id: [true],
			cvv: [''],
			savetoprofile: [true, Validators.required]
		});


	}
	updateHoldReason() {
		const data = {
			holdreason: this.holdReason.value,
			debtorno: this.customer.debtorno,
			//branchcode: this.customer.branchcode,
			//ediinvoices: this.customer.ediinvoices,
			//emailstatement: this.customer.emailstatement,
			//arintrate: this.customer.arintrate,
			//currcode: this.customer.currcode,
			//creditlimit: this.customer.creditlimit,
			//customerpoline: this.customer.customerpoline,
		}
		this.customerService.updateDebtor(data).subscribe(r => {

		})
	}
	
	setCardRequired(method: any) {

		if(method) {
			const thispay = this.payment_methods.filter(p => {
				return p.paymentid == method
			})[0];

			if (thispay.cc_process != '0') {
				this.card_required = true;
				this.paymentForm.get('trigger_refund').setValue(false);
			} else {
				this.card_required = false;
				this.selected_card.setValue(false);
				this.paymentForm.get('charge_card').setValue(false)
				this.paymentForm.get('trigger_refund').setValue(false);
				this.charge_card = false;
			}
		}
	}
	updatePaymentType(method: any) {
		this.paymentForm.get('payment_type').setValue(method);
		this.setCardRequired(method);

		const ptype = this.payment_methods.filter( (type:any) => {
			return type.paymentid == method
		})[0];

		this.getPaymentTypeJournal(ptype);
	}
	
	updateProfile(event: any) {
		this.customer.profile = event;
		this.addingcard = false;
		this.editingcard = false;
		this.sending = false;
		this.profile_cards = event.cards
		this.hasprofile = (event.cards.length) ? true : false;
		this.profile_cards = event.cards
	}

	toggleAddCard() {
		this.addingcard = (this.addingcard) ? false : true
	}

	getPaymentTypeJournal(method: any) {

		this.paymentsService.getJournals(method).subscribe((result: any) => {
			this.openjournals = result;
			//not firing in ogetOPenJounrls?
			this.getAllUserCurrentOpenJounrals();

			if (result.length) {
				this.paymentForm.get('journalno').setValue(result[0]);
			} else {
				this.paymentForm.get('journalno').setValue('');
			}
		});


	}
	
	getAllUserCurrentOpenJounrals() {
		const data = {};
		this.paymentsService.getAllUserOpenJounrals(data).subscribe((result: any) => {
			this.allopenjournals = result;
		});
	}

	getJournals() {

		const data = {
			type: this.getPaymentTypeName()
		};

		this.paymentsService.getJournals(data).subscribe((result: any) => {
			this.openjournals = result;
			this.getAllUserCurrentOpenJounrals();
			if (result.length) {
				this.paymentForm.get('journalno').setValue(result[0]);
			} else {
				this.paymentForm.get('journalno').setValue('');
			}
		});


	}
	updateTotals() {
		this.allocation_to_total = this.allocations_to.reduce(function(accumulator: number, items: any) {
			return accumulator + parseFloat(items.allocation);
		}, 0);
		
		this.allocation_from_total = this.allocations_from.reduce(function(accumulator: number, items: any) {
			return accumulator + parseFloat(items.allocation);
		}, 0) + (-1 * parseFloat(this.paymentForm.get('amount').value));
		
	}

	//update allocations
	updateAllocationTo(input, pay) {
		const index = this.allocations_to.indexOf(pay);
		const index2 = this.alldebits.indexOf(pay);
		if (index >= 0) {
			this.allocations_to[index].allocation = input;
			if (parseFloat(input) > this.allocations_to[index].leftto) {
				this.allocations_to[index].allocation = parseFloat(this.allocations_to[index].leftto);
			}
		}
		if (index2 >= 0) {
			this.alldebits[index2].allocation = input;
			if (parseFloat(input) > this.alldebits[index2].leftto) {
				this.alldebits[index2].allocation = parseFloat(this.alldebits[index2].leftto);
			}
		}
		this.updateTotals();
		
		if (this.selectInvoiceToPay.value) {
			const amount = this.financial(this.allocation_to_total);
			this.paymentForm.get('amount').setValue(amount);
		}
		
		this.updateTotals();
		
		if(Math.abs(pay.total) < (parseFloat(this.alldebits[index2].allocation) + parseFloat(this.alldebits[index2].discount))){
		
			this.globalSearchService.showNotification('Over allocating', 'warning', 'top', 'left');
		
		}
	}
	//update allocations
	updateAllocationToDiscount(input, pay) {
		const index = this.allocations_to.indexOf(pay);
		const index2 = this.alldebits.indexOf(pay);
		if (index >= 0) {
			this.allocations_to[index].discount = input;
			
		}
		if (index2 >= 0) {
			this.alldebits[index2].discount = input;
			
		}
		this.updateTotals();
		
		if (this.selectInvoiceToPay.value) {
			const amount = this.financial(this.allocation_to_total);
			this.paymentForm.get('amount').setValue(amount);
		}
		
		this.updateTotals();
		
		if(Math.abs(pay.total) < (parseFloat(this.alldebits[index2].allocation) + parseFloat(this.alldebits[index2].discount))){
		
			this.globalSearchService.showNotification('Over allocating', 'warning', 'top', 'left');
		
		}
	}
	
	updateAllocationToCheckNo(input, pay) {
		const index = this.allocations_to.indexOf(pay);
		const index2 = this.alldebits.indexOf(pay);
		if (index >= 0) {
			this.allocations_to[index].checkno = input;
			
		}
		if (index2 >= 0) {
			this.alldebits[index2].checkno = input;
			
		}
		this.updateTotals();
	}

	//update alloc from
	updateAllocationFrom(input, pay) {


		const index = this.allocations_from.indexOf(pay);
		if (index >= 0) {
			if (parseFloat(input) > parseFloat(this.allocations_from[index].leftto)) {
				//this.allocations_from[index].allocation = parseFloat(this.allocations_from[index].leftto);
				input = parseFloat(this.allocations_from[index].leftto);
			}

			this.allcredits.forEach((r: any) => {
				if (r == pay) {
					pay.selected = true;
					r.selected = true;
				}
			});

			this.allocations_from[index].allocation = input;
		}
		
		this.updateTotals();
	}

	allocationTo(event: any, rec: any) {
		if (!event) {
			rec.selected = false;
			const index = this.allocations_to.indexOf(rec);
			this.allocations_to.splice(index, 1);
		} else {
			this.alldebits.forEach((r: any) => {
				if (r == rec) {
					rec.selected = true;
					r.selected = true;
				}
			});

			rec.selected = true;
			this.allocations_to.push(rec);
		}
		this.updateTotals();
				
		if (this.selectInvoiceToPay.value) {
			const amount = this.financial(this.allocation_to_total);
			this.paymentForm.get('amount').setValue(amount);
		}
		
		this.updateTotals();
		
	}

	checkAllTo(event: any) {
		if (event) {
			this.customer.transactions.forEach((r: any) => {
				r.selected = true;
				this.allocations_to.push(r);
			});
		} else {
			this.customer.transactions.forEach((r: any) => { r.selected = false; });
			this.allocations_to = [];
		}
		this.updateTotals();
	}

	checkAllFrom(event: any) {
		if (event) {
			this.allocations_from = [];
			this.customer.payments.forEach((r: any) => {
				r.selected = true;
				//this.allocations_from.push(r);
				this.allocationFrom(true, r)
			});
		} else {
			this.customer.transactions.forEach((r: any) => {
				r.selected = false;
				this.allocationFrom(false, r)
			});
			this.allocations_from = [];
		}
		this.updateTotals();
	}

	allocationFrom(event: any, rec: any) {
		if (!event) {
			rec.selected = false;
			const index = this.allocations_from.indexOf(rec);
			if(rec.cctrans && rec.payby_comments != '') {
				this.paymentForm.get('checkno').setValue('');
			}

			this.allocations_from.splice(index, 1);
		} else {

			if(rec.cctrans && rec.payby_comments != '') {
				this.paymentForm.get('checkno').setValue(rec.payby_comments);
			}
			rec.selected = true;
			this.allocations_from.push(rec);
		}

		this.updateTotals();

		/*
		if(this.selectInvoiceToPay.value) {
			let amount = this.allocation_from_total + this.allocation_to_total;

			if(amount > 0 ){
				this.paymentForm.get('amount').setValue(amount.toFixed(2));
			} else {
				this.paymentForm.get('amount').setValue(0.00);
			}
		}
		*/
	}

	addAllocations() {
		if (!this.paymentForm.get('debtorno').value) {
			alert('Please Select A Customer');
		} else {
			const data = { from: this.allocations_from, to: this.allocations_to, debtor: this.paymentForm.value }
			this.paymentsService.addAllocations(data).subscribe((result: any) => {

				this.allpayments = result.payments;
				this.alltransactions = result.transactions;

				this.customer.transactions = result.transactions
				this.customer.payments = result.payments
				this.customer.credits = result.credits
				this.allcredits = result.payments
				this.alldebits = result.transactions
				this.selectAllInvoices.setValue(false);
				this.selectAllPayments.setValue(false);
				this.checkAllTo(false);
				this.checkAllFrom(false);
				this.allocations_to = [];
				this.allocations_from = [];
				this.updateTotals();
			});
		}
	}

	doNotCharge(event: any) {
		event.preventDefault();
		this.payment_card = false;
		this.charge_card = false;
		this.paymentForm.get('charge_card').setValue(false)
		this.profile_cards.every(card => card.default = false);
	}

	togglePaySelect(pay: any) {
		pay.selected = (pay.selected) ? false : true;
		if (pay.selected) {
			this.creditsSelected.push(pay)
		} else {
			const i = this.creditsSelected.indexOf(pay)
			this.creditsSelected.splice(i, 1);
		}
	}

	updateDayPayMents(event: any) {
		this.today = event;
		this.paymentsService.getPayments(this.today.toISOString()).subscribe((results: any) => {
			this.payments = results;
		});
	}
	ngAfterViewInit(): void {
		
	}

	focusTo() {
		setTimeout(() => {
			this.checknoElement.nativeElement.focus();
		}, 0);
	}
	
	back() {
		//this.location.back()
	}
}


<span *ngIf="getting"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
</span>

<span *ngIf="profile_cards && !profile_cards.length && !getting">No Cards On File</span>
<div class="col-12 mt-2 text-center" *ngIf="hasprofile">
	<mat-radio-group aria-label="Select a card on file" class="text-left w-100" [formControl]="selected_card">

		<mat-radio-button *ngFor="let card of profile_cards" [value]="card" [color]="'accent'" (change)="updatePaymentType(card.payment_method)" class="ml-2 mr-2" (click)="selectCard(card)">
			<div class="card m-0 p-0" (click)="selectCard(card)">
				<div class="col-12 mb-1 mt-1">
					<b><span [innerHTML]="card.name"></span></b><br>{{ card.number }} Exp: {{ card.expiry_month}}/{{ card.expiry_year}}
				</div>
			</div>
		</mat-radio-button>
	</mat-radio-group>
</div>

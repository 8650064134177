import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy, OnChanges, ViewEncapsulation,TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../../services/reports.service';
import { GlobalsService } from '../../services/globals.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { SystemService } from '../../services/system.service';
import { MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-sale-builder',
	templateUrl: './sale-builder.component.html',
	providers: [MessageService],
	styleUrls: ['./sale-builder.component.scss']
})
export class SaleBuilderComponent implements OnInit {

	color = 'blue';
	sales: any = [];
	saleForm: UntypedFormGroup; 
	ruleForm: UntypedFormGroup;
	checkForm: UntypedFormGroup;
	@ViewChild('saleModal') saleRef: ElementRef;
	@ViewChild('ruleModal') ruleRef: ElementRef;
	@ViewChild('checkModal') checkRef: ElementRef;
	currentsale: any = '';
	currentrule: any = '';
	branches: any = [];
	editrule_type: any = '';
	ruletypes: any = { 'ALL': 'All Items',
					  'CUSTTYPE': 'Customer Type',
					  'PRODLINE': 'Product Line',
					  'CATEGORY': 'Item Category',
					  'STOCKID': 'Specific Item' };
	custtypes: any = [];
	prodlines: any = [];
	categories: any = [];
	openAccordion = -1;
	reopenAccordion = -1;
	
	constructor(private systemService: SystemService, private messageService: MessageService, private modalService: NgbModal, public inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private reportsService: ReportsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder) { }

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.systemService.getStoreSales().subscribe(r => {
			this.sales = r;
		});
		
		
		
		this.saleForm = this.fb.group({
			salename: ['', Validators.required],
			date_start: ['', Validators.required],
			date_end: ['', Validators.required],
			enabled: [1],
			locations:[[]]
		});
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		this.inventoryService.getItemCategories().subscribe((res) => {
			Object(res).sort((a,b) => (a.categorydescription > b.categorydescription) ? 1 : ((b.categorydescription > a.categorydescription) ? -1 : 0))
			this.categories = res;
		});
		this.inventoryService.getItemProductlines().subscribe(p => {
			this.prodlines = p
		});
		this.globalSearchService.customertypes.subscribe(results => {
			if(results){
				const sorted = results.sort((a,b) => a.typename > b.typename ? 1 : 0);
				this.custtypes = sorted;
			}
		});
	}
  
	addNew(){
		this.currentsale = '';
		this.saleForm = this.fb.group({
			salename: ['', Validators.required],
			date_start: ['', Validators.required],
			date_end: ['', Validators.required],
			enabled: [1],
			locations:[[]]
		});
		this.modalService.open(this.saleRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
	}
	addRule(saleid: any){
		this.currentsale = saleid;
		this.currentrule = ''
		this.ruleForm = this.fb.group({
			rule_type: ['ALL', Validators.required],
			rule_value: ['', Validators.required],
			discount_percent: ['', Validators.required],
			rule_priority: [99]
		});
		this.editrule_type = 'ALL';
		this.modalService.open(this.ruleRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
	}
	pchk(){
		this.checkForm = this.fb.group({
			debtorno: ['', Validators.required],
			stockid: ['', Validators.required],
			loccode: ['', Validators.required]
		});
		this.modalService.open(this.checkRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
	}
  
	priceCheck(){
  
		const data = { 
						 'stockid' : this.checkForm.get('stockid').value,
						 'debtorno': this.checkForm.get('debtorno').value,
						 'loccode': this.checkForm.get('loccode').value
						 };
						 
		this.systemService.pcStoreSale(data).subscribe(r => {
			
			let message = 'Price Check - ' + this.checkForm.get('stockid').value + ': ' + r.price;
			if(r.baseprice != r.price){
				message = message + ' ' + (r.discount * 100) + ' % discount off ' + r.baseprice + ' from ' + r.salename;
			}
			this.globalSearchService.showNotification(message, 'success', 'bottom', 'left');
		});
  
  
	}
	typechange(){
	
		this.editrule_type = this.ruleForm.get('rule_type').value;
		if(this.editrule_type == 'ALL'){
			this.ruleForm.get('rule_priority').setValue('99');
		}
	}
	editRule(saleid: any, ruleid: any){
		this.currentsale = saleid;
			
		const data = { ruleid: ruleid };
		this.systemService.getStoreSaleRule(data).subscribe(r => {
			
			if(r){
				this.editrule_type = r.rule_type;
				this.currentrule = ruleid;
				this.ruleForm = this.fb.group({
					rule_type: [r.rule_type, Validators.required],
					rule_value: [r.rule_value, Validators.required],
					discount_percent: [r.discount_percent, Validators.required],
					rule_priority: [r.rule_priority]
				});		
				this.modalService.open(this.ruleRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
			}
			
		});
	}
	deleteRule(ruleid: any){
  
		const data = { ruleid: ruleid };
		this.systemService.getStoreSaleRule(data).subscribe(r => {
			
			if(r){
			
				const confirmed = confirm('Delete Rule?');
				if (confirmed) {
					const d = {
						'ruleid': r.ruleid
					}
					this.systemService.deleteStoreSaleRule(d).subscribe(rs => {
						this.reopenAccordion = this.openAccordion;
						this.sales = rs;
						this.openAccordion = this.reopenAccordion;
					});
				} else {
					return false;
				}
			}
			
		});
	}
	saveRule(ruleid: any){
  
		const data = { 'saleid': this.currentsale,
						 'ruleid': ruleid,
						 'rule_type' : this.ruleForm.get('rule_type').value,
						 'rule_value': this.ruleForm.get('rule_value').value,
						 'discount_percent' : this.ruleForm.get('discount_percent').value,
						 'rule_priority' : this.ruleForm.get('rule_priority').value
						 };
						 
		this.systemService.saveStoreSaleRule(data).subscribe(r => {
			this.reopenAccordion = this.openAccordion;
			this.sales = r;
			this.openAccordion = this.reopenAccordion;
			this.modalService.dismissAll();
		});
	}
	saveSale(saleid: any){
  
		const data = { 'saleid': saleid,
						 'salename' : this.saleForm.get('salename').value,
						 'date_start': this.saleForm.get('date_start').value,
						 'date_end' : this.saleForm.get('date_end').value,
						 'enabled' : this.saleForm.get('enabled').value,
						 'locations': { 'locations': this.saleForm.get('locations').value }
						 };
						 
		this.systemService.saveStoreSale(data).subscribe(r => {
			this.reopenAccordion = this.openAccordion;
			this.sales = r;
			this.openAccordion = this.reopenAccordion;
			this.modalService.dismissAll();
		});
	}
	editSale(saleid: any){
  
		const data = { saleid: saleid };
		this.systemService.getStoreSale(data).subscribe(r => {
			
			if(r){
				this.currentsale = r.saleid
				this.saleForm = this.fb.group({
					salename: [r.salename, Validators.required],
					date_start: [r.date_start, Validators.required],
					date_end: [r.date_end, Validators.required],
					enabled: [r.enabled],
					locations:[r.locations.locations]
				});
				this.modalService.open(this.saleRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
			}
			
		});
  
  
	}
	deleteSale(saleid: any){
  
		const data = { saleid: saleid };
		this.systemService.getStoreSale(data).subscribe(r => {
			
			if(r){
			
				const confirmed = confirm('Delete '+r.salename+'?');
				if (confirmed) {
					const data = {
						'saleid': r.saleid
					}
					this.systemService.deleteStoreSale(data).subscribe(rs => {
						this.reopenAccordion = this.openAccordion;
						this.sales = rs;
						this.openAccordion = this.reopenAccordion;
					});
				} else {
					return false;
				}
			}
			
		});
  
			
	}

}

<ng-template #monthDetails let-modal>
  <div class="modal-header">
	<h4 class="modal-title" id="modal-title">Brand Details</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
	  <i class="fa fa-close"></i>
	</button>
  </div>
  <div class="modal-body text-dark">
	<div class="table-responsive table-fluid mt-3" *ngIf="brand_details && brand_details.items.length">
	  <table class="table table-striped table-hover table-fixed w-100">
		<thead class="thead-dark">
		  <tr>
			<th>DOCUMENT#</th>
			<th>TYPE</th>
			<th>LOCATION#</th>
			<th>DATE</th>
			<th>ITEM#</th>
			<th>DESC</th>
			<th class="text-center">DISCOUNT</th>
			<th class="text-center">QTY</th>
			<th class="text-right">PRICE</th>
			<th class="text-right">TOTAL</th>
		  </tr>
		</thead>
		<tbody>
		  <tr *ngFor="let item of brand_details.items">
			<td>{{ item.transno }}</td>
			<td>{{ item.typename }}</td>
			<td>{{ item.loccode }}</td>
			<td>{{ item.trandate | date:'shortDate' }}</td>
			<td>{{ item.stockid }}</td>
			<td>{{ item.description }}</td>
			<td class="text-center">{{ item.discountpercent | percent }}</td>
			<td class="text-center">{{ item.qty }}</td>
			<td class="text-right">{{ item.price | currency }}</td>
			<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
		  </tr>
		</tbody>
	  </table>
	</div>
	<h4 class="text-center mt-3" *ngIf="!brand_details || !brand_details.items.length">
	  No Results
	</h4>
  </div>
  <div class="modal-footer"></div>
</ng-template>

<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back w-100" *ngIf="flip == 'active'">
		<div class="card w-100" >
			<div class="card-header card-header-info card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">subtitles_off</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="row">
				<!-- <div class="col-md-3 ml-auto mr-auto">
					<mat-form-field appearance="outline">
						<mat-label>Number Of Days</mat-label>
						<span matPrefix>#</span>
						<input matInput type="number" value="{{ days | number }}"  (input)="updateDays($event)" class="text-right">
					</mat-form-field>
				</div>
				<div class="col-md-3 ml-auto mr-auto">
					<mat-form-field appearance="outline">
						<mat-label>Number Of Days Since</mat-label>
						<span matPrefix>#</span>
						<input matInput type="number" value="{{ daysmax | number }}"  (input)="updateDaysMax($event)" class="text-right">
					</mat-form-field>
				</div> -->
				<div class="col-md-3 ml-auto mr-auto">
					<button tabindex="-1" mat-stroked-button color="accent" class="mt-3" (click)="loadData()" *ngIf="!sending">
						<i class="material-icons">refresh</i>
					</button>

					&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="data" >
						<i class="fa fa-file-excel-o"></i> XLS
					</button>
					&nbsp;&nbsp;
					<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="data" >
						<i class="fa fa-file-pdf-o"></i> PDF
					</button>


					<span *ngIf="sending">
						<svg class="spinner" width="55px" height="55px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
							</circle>
						</svg>
					</span>
				</div>
			</div>
			<div class="row m-0 p-0">
				<div class="col-6">
					<!-- <mat-form-field appearance="outline">
						<mat-label>Search</mat-label>
						<input type="text" class="text-left" matInput [formControl]="searcher" />
					</mat-form-field> -->
				</div>
				<div class="col-6">
					<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
				</div>
			</div>
			<div class="card-body"  *ngIf="viewing" #print_table>
				<h5>#Customers {{total}}</h5>
				<div class="table table-responsive table-fluid ">
					<table class="table table-fixed table-striped table-hover">
						<thead  class="thead-dark">
							<tr>
								<th>Customer#</th>
								<th>Name</th>
								<th>Address1</th>
								<th>City</th>
								<th>State</th>
								<th>Postal</th>
								<th>SalesPerson</th>
								<th>Last Seen</th>
								<th>Total Spend</th>
								<th>Avg Spend</th>
								<th>Brand 1</th>
								<th>Brand 2</th>
								<th>Brand 3</th>
								<!-- <th>Last Transaction</th> -->
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let d of dataObs | async">
								<td> <a [routerLink]="'/customers/view/'+d.debtorno">{{d.debtorno}}</a></td>
								<td>{{d.name}}</td>
								<td>{{d.address1}}</td>
								<td>{{d.address3}}</td>
								<td>{{d.address4}}</td>
								<td>{{d.address5}}</td>
								<td><span *ngIf="d.salesmanname">{{d.salesmanname.name}}</span></td>
								<td>{{d.lastseen}}</td>
								<td>{{d.totalspend | currency}}</td>
								<td>{{d.avgspend | currency}}</td>
								<td matRipple class="pointer"><span *ngIf="d.topthree[0]" (click)="getBrandSales(d.debtorno, d.topthree[0].manufacturer_id)">{{d.topthree[0].name}} ({{d.topthree[0].brandcount}})</span></td>
								<td matRipple class="pointer"><span *ngIf="d.topthree[1]" (click)="getBrandSales(d.debtorno, d.topthree[1].manufacturer_id)">{{d.topthree[1].name}} ({{d.topthree[1].brandcount}})</span></td>
								<td matRipple class="pointer"><span *ngIf="d.topthree[2]" (click)="getBrandSales(d.debtorno, d.topthree[2].manufacturer_id)">{{d.topthree[2].name}} ({{d.topthree[2].brandcount}})</span></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-info card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(data, title)">
					<i class="material-icons">subtitles_off</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="total">
						{{ total }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>
					<p class="card-category text-info text-justify mt-3 text-right">
						&nbsp;
					</p>
				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>

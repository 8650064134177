<ng-container>
	<form @grow [formGroup]="arForm" (ngSubmit)="onSubmit()" class="needs-validation product-add" *ngIf="arForm">
		<div class="card-header">
			<div class="col-12 text-right">
				<button class="ml-auto mr-0" mat-raised-button color="accent">
					<mat-icon>save</mat-icon> Save
				</button>
			</div>
		</div>
		<div class="row">

			<div class="col-md-12 text-center" [ngClass]="!config.fet ? 'w-100 ml-auto mr-auto': ''">
				<mat-slide-toggle formControlName="tirefee" [ngClass]="!config.fet ? 'd-none': ''">Tire Fee</mat-slide-toggle>
				&nbsp;&nbsp;
				<mat-slide-toggle formControlName="customerpoline">Require PO#</mat-slide-toggle>
			</div>

			<div class="col-md-6">
				<mat-form-field appearance="outline">
					<mat-label>Credit Limit</mat-label>
					<input matInput required value="" formControlName="creditlimit">
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field appearance="outline">
					<mat-label>Credit Status</mat-label>
					<mat-select formControlName="holdreason">
						<mat-option *ngFor="let va of holdreasons" [value]="va?.reasoncode">
							{{va.reasondescription}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field appearance="outline">
					<mat-label>Payment Terms</mat-label>
					<mat-select formControlName="termsindicator">
						<mat-option *ngFor="let va of terms" [value]="va?.termsindicator">
							{{va.terms}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field appearance="outline">
					<mat-label>Currency</mat-label>
					<mat-select formControlName="currcode">
						<mat-option *ngFor="let currency of currencies" [value]="currency?.currabrev">
							{{currency.currency}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Print Statement</mat-label>
					<mat-select formControlName="edireference" required>

						<mat-option [value]="'Y'">Yes</mat-option>
						<mat-option [value]="'N'">No</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-checkbox formControlName="emailstatement">Email Statement</mat-checkbox>
				<div [ngClass]="(arForm.get('emailstatement').value  == false || arForm.get('emailstatement').value  ==''  ) ? 'd-none': '' ">
					<mat-form-field appearance="outline">
						<mat-label>Statement Email Address</mat-label>
						<input matInput value="" formControlName="statementemailaddress">
					</mat-form-field>
				</div>
			</div>
			<div class="col-md-6">
				<mat-checkbox formControlName="ediinvoices">Email Invoices</mat-checkbox>
				<div [ngClass]="(arForm.get('ediinvoices').value  == false || arForm.get('ediinvoices').value  ==''  ) ? 'd-none': '' ">
					<mat-form-field appearance="outline">
						<mat-label>Invoice Email Address</mat-label>
						<input matInput value="" formControlName="invoiceemailaddress">
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
</ng-container>

import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable({
	providedIn: 'root'
})

export class UsersService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};



	public removeGroup(data: any) {
		const method = 'users/removegroup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}


	public addGroup(data: any) {
		const method = 'users/addgroup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public updateGroup(data: any) {
		const method = 'users/updategroup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public addUserToGroup(data: any) {
		const method = 'users/addusertogroup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public removeUserFromGroup(data: any) {
		const method = 'users/removeuserfromgroup'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public updateUserLocations(data: any) {
		const method = 'users/updatelocations'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public isPosUser(){
		const method = 'users/isposuser';
		return this.http.get(this.setEndPoint(method));
	}

	public getNavState() {

		const navstate = JSON.parse(localStorage.getItem('navstate'));
		if (navstate == 'true' || navstate == true) {
			var tf = true;
		} else {
			var tf = false;
		}

		return tf;
	}
	public setNavState(inbound: boolean) {

		localStorage.setItem('navstate', JSON.stringify(inbound))
		return inbound;
	}

	public getLocalUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	public getUser(user) {
		const method = 'users/getuser&uid=' + user;
		return this.http.get(this.setEndPoint(method));
	}

	public getUserDevices(data: any) {
		const method = 'globals/devices/getuserdevices'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public addUserDevice(data: any) {
		const method = 'globals/devices/saveuserdevice'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}


	// Create or update a print setting for a device
	setPrintSetting(user: string, machineUser: string, machineName: string, deviceID: string, deviceTypes: string[]) {
		const settings = JSON.parse(localStorage.getItem('printSettings')) || {};
		if (!settings[user]) {
			settings[user] = [];
		}
		let existingDeviceIndex = -1;
		settings[user].forEach((device: any, index: number) => {
			if (device.deviceID === deviceID) {
				existingDeviceIndex = index;
			}
		});
		if (existingDeviceIndex !== -1) {
			settings[user][existingDeviceIndex] = { machineUser, machineName, deviceID, deviceTypes };
		} else {
			settings[user].push({ machineUser, machineName, deviceID, deviceTypes });
		}
		localStorage.setItem('printSettings', JSON.stringify(settings));

	}

	// Read print settings for a specific user
	getPrintSettings(user: string) {
		const settings = JSON.parse(localStorage.getItem('printSettings')) || {};
		return settings[user] || [];
	}

	// Delete a print setting for a specific device of a user
	deletePrintSetting(user: string, deviceID: string) {
		const settings = JSON.parse(localStorage.getItem('printSettings')) || {};
		if (settings[user]) {
			const updatedSettings = settings[user].filter((device: any) => device.deviceID !== deviceID);
			settings[user] = updatedSettings;
			localStorage.setItem('printSettings', JSON.stringify(settings));
			return true;
		}
		return false; // User not found
	}

	// Get devices by print type for a specific user
	getDevicesByType(user: string, printType: string): { deviceID: string, printType: string } [] {
		const settings = JSON.parse(localStorage.getItem('printSettings')) || {};

		const userSettings = settings[user] || [];
		const deviceIDs = new Set < string > ();

		// Iterate through each user setting
		userSettings.forEach((setting: any) => {
			// Check if the device has the specified print type
			if (setting.deviceTypes && setting.deviceTypes.includes(printType)) {
				deviceIDs.add(setting.deviceID+':'+setting.machineName);
			}
		});

		// Convert Set to an array of objects
		const devices: { deviceID: string, printType: string } [] = [];
		deviceIDs.forEach(deviceID => {
			devices.push({ deviceID: deviceID, printType: printType });
		});

		return devices;
	}

	public getCompanyDevices(data: any) {
		const method = 'globals/devices/getcompanydevices'
		return this.http.post(this.setEndPoint(method), { data }, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public removeUserDevice(data: any) {
		const method = 'globals/devices/removeuserdevice'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public updateUser(userid: any, data: any) {
		const method = 'users/update'
		return this.http.post(this.setEndPoint(method), { userid, data }, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public createUser(userid: any, data: any) {
		const method = 'users/create'
		return this.http.post(this.setEndPoint(method), { userid, data }, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public getUsers() {
		const method = 'users';
		return this.http.get(this.setEndPoint(method));
	}

	public getUserGroups() {
		const method = 'users/getusergroups';
		return this.http.get(this.setEndPoint(method));
	}

	public getUserGroup(id) {
		const method = 'users/getusergroup&gid=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getAllReports(id) {
		const method = 'reports/allreports&gid=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public addReport(data: any) {
		const method = 'reports/add'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}

	public removeReport(data: any) {
		const method = 'reports/remove'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('users', data))
		);
	}


	public getGroupReports(id) {
		const method = 'reports/groupreports&gid=' + id;
		return this.http.get(this.setEndPoint(method));
	}


	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if (p) {
			return '&package=' + p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}
}

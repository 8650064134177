<ng-container *ngIf="vendorForm && vendor">
	<form [formGroup]="vendorForm" *ngIf="vendor">
		<div class="row mb-2">
			<div class="col-md-12">
				&nbsp;
			</div>
		</div>
			<div class="row mb-2 card-deck">
				<div class="card  m-0 p-0 col-md-4">
					<div class="card-header card-header-rose card-header-icon">
						<div class="card-icon pointer" (click)="toggleEditBilling()">
							<i class="material-icons">credit_card</i>
						</div>
						<h5 class="card-title bold">Warehouse:</h5>
					</div>
					<ng-container *ngIf="!editbilling">
						<div class="card-body text-dark form-inputs">
							<div class="row">
								<div class="col-12 ">
									<span [innerHTML]="location_selected.address"></span><br />
									Contact: {{ location_selected.contact }}<br />
									Email: {{ location_selected.email }}
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="editbilling">
						<div class="card-body text-dark form-inputs" @grow>
							<mat-form-field appearance="standard">
								<mat-label>Warehouse</mat-label>
								<mat-select formControlName="warehouse">
									<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
										{{loc.locationname}}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<div *ngIf="locations">
								<mat-form-field class="full-width" appearance="standard">
									<mat-label>Address 1</mat-label>
									<input matInput type="text" placeholder="Address 1" formControlName="deladd1">
									<mat-error>
									</mat-error>
								</mat-form-field>
								<mat-form-field class="full-width" appearance="standard">
									<mat-label>Address 2</mat-label>
									<input matInput type="text" placeholder="Address 2" formControlName="deladd2">
									<mat-error>
									</mat-error>
								</mat-form-field>
								<div class="row">
									<div class="col-4 m-0">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>CITY</mat-label>
											<input matInput type="text" placeholder="City" formControlName="deladd3">
											<mat-error>
											</mat-error>
										</mat-form-field>

									</div>
									<div class="col-4 m-0">
										<mat-form-field appearance="standard" class="ml-2 mr-2">
											<mat-label>Region</mat-label>
											<mat-select [(value)]="location.deladd4" formControlName="deladd4">
												<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
													{{zone.code }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-4 m-0">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>POSTAL</mat-label>
											<input matInput type="text" placeholder="Zip" [value]="location.deladd5" name="location.deladd5" formControlName="deladd5">
											<mat-error>
											</mat-error>
										</mat-form-field>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div class="card col-md-4 m-0 p-0">
					<div class="card-header card-header-danger card-header-icon" >
						<div class="card-icon pointer" (click)="toggleEditShipping()">
							<i class="material-icons">account_circle</i>
						</div>
						<h5 class="card-title bold">Vendor Info:</h5>
					</div>
					<ng-container *ngIf="!editshipping">
						<div class="card-body text-dark form-inputs">
							<div class="row">
								<div class="col-12 ">
									<span [innerHTML]="vendor.address"></span>
									<br />
									Phone#: {{ vendor.telephone  }}<br>
									Email: {{ vendor.email }}
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="editshipping">
						<div class="card-body text-dark form-inputs" @grow>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Name</mat-label>
										<input matInput formControlName="suppname">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Address 1</mat-label>
										<input matInput formControlName="address1">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Address 2</mat-label>
										<input matInput formControlName="address2">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 m-0">
									<mat-form-field appearance="standard">
										<mat-label>City</mat-label>
										<input matInput formControlName="address3">
									</mat-form-field>
								</div>
								<div class="col-md-4 m-0">
									<mat-form-field appearance="standard" class="ml-2 mr-2">
										<mat-label>Region</mat-label>
										<mat-select formControlName="address4">
											<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
												{{zone.code }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4 m-0">
									<mat-form-field appearance="standard">
										<mat-label>Postal</mat-label>
										<input matInput formControlName="address5">
									</mat-form-field>
								</div>
							</div>
						</div>
					</ng-container>

				</div>
				<div class="card col-md-4 m-0 p-0">
					<div class="card-header card-header-success card-header-icon">
						<div class="card-icon" (click)="toggleEditSettings()">
							<i class="material-icons">settings</i>
						</div>
						<h5 class="card-title bold">Initiator:</h5>
					</div>
					<div class="card-body text-dark form-inputs" @grow>
						<mat-label>P.O. Date</mat-label>
						{{ today | date: 'shortDate' }}
						<mat-form-field appearance="standard">
							<mat-label>Delivery Date</mat-label>
							<input matInput [matDatepicker]="picker" formControlName="deliverydate">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>

						<mat-form-field class="full-width" appearance="standard">
							<mat-label>Initiated By:</mat-label>
							<input matInput type="text" placeholder="initiator" formControlName="initiator">
							<mat-error>
							</mat-error>
						</mat-form-field>

						<mat-form-field class="full-width" appearance="standard">
							<mat-label>Reference #:</mat-label>
							<input matInput type="text" placeholder="Reference Number" formControlName="refrenceno">
							<mat-error>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>

			<div class="row m-0 p-0">
				<div class="col-md-12 m-0 p-0">
					<mat-form-field class="ml-1 w-100 full-width" appearance="fill">
						<mat-label>Purchase Order Note</mat-label>
						<textarea matInput formControlName="comments" placeholder="Notes..."></textarea>
					</mat-form-field>
				</div>
			</div>

	</form>
	<ng-container class="w-100">
		<div class="container-fluid text-right" id="rightcart-content" *ngIf="!cart_items.length">
			<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
				<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
					<h4>No Current Items</h4>
				</div>
			</div>
		</div>
		<div class="table w-100" *ngIf="cart_items.length">
			<table class="w-100 table smaller table-fixed table-sm">
				<thead>
					<tr>
						<th class="text-left">Item</th>
						<th class="text-left">Note</th>
						<th class="text-right small-td" *ngIf="config.discounts">Discount</th>
						<th class="text-right small-td w-25">Price</th>
						<th class="text-center w-25">Qty</th>
						<th class="text-center">Availability</th>
						<th class="text-right">Total</th>
						<th class="text-right"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of cart_items">
						<td class="text-left">{{item.stockid}}&nbsp;&nbsp;<span class="bold">{{item.description}}</span></td>
						<td class="text-left ">
							<mat-form-field appearance="standard" class="m-0 p-0" *ngIf="item.mbflag != 'F'">
								<mat-label>Line Notes</mat-label>
								<input matInput maxlength="64" placeholder="Line Notes." [value]="item.notes" (input)="updateNote($event,item)">
							</mat-form-field>
						</td>
						<td class="text-right w-25" *ngIf="config.discounts">
							<mat-form-field appearance="standard" class="text-right" *ngIf="item.mbflag != 'F'">
								<mat-label>Discount</mat-label>
								<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (input)="updateDiscount($event,item)" appSelectOnFocus>
								<span matSuffix>%</span>
							</mat-form-field>
						</td>
						<td class="text-right w-25">
							<mat-form-field appearance="standard" class="m-0 p-0 text-right">
								<mat-label>Price</mat-label>
								<span matPrefix>$</span>
								<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)" appSelectOnFocus>
								<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
									Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
								</mat-hint>
							</mat-form-field>
						</td>
						<td class="text-center w-25">
							<mat-form-field appearance="standard" class="m-0 p-0 ">
								<mat-label>Qty</mat-label>
								<span matPrefix>#</span>
								<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)" appSelectOnFocus>
							</mat-form-field>
						</td>
						<td class="text-center">
							<div [ngClass]="{ 'bg-warning': item.porequired == '1', 'bg-success': item.porequired == '0' }" *ngIf="!item.parent_id">
								<p class="text-center text-white mt-3 bolder" *ngIf="item.porequired == '0'"> Available</p>
								<p class="text-center text-dark mt-3 bolder" *ngIf="item.porequired == '1'"> PO Required</p>
							</div>
						</td>
						<td class="text-right">
							{{ getDiscountTotal(item) | currency }}
						</td>
						<td class="text-right">
							<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
								<mat-icon>remove</mat-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="cart_items.length">
			<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
				{{total.title }}: {{ total.text | currency }}
			</div>
		</div>
	</ng-container>
</ng-container>

import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder,UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service'
import { PrintService } from '../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-special-pricing',
	templateUrl: './special-pricing.component.html',
	styleUrls: ['./special-pricing.component.scss']
})
export class SpecialPricingComponent implements OnInit {
	@ViewChild('specEdit') specEditRef: ElementRef;
	@ViewChild('specUp') specUpload: ElementRef;


	searchSpecials = new UntypedFormControl('')
	blanket = new UntypedFormControl('');
	itemdetails: any = false;
	imsearch: any= false;
	color = 'blue';
	active: any = [];
	inactive: any = [];
	stockid: any = null;
	pricelvs: any = [];
	prices_array: any = [];
	original_prices_array: any = [];
	allitems: any = [];
	filteredOptions: any = [];
	files: any = [];
	uploaddata: any = [];
	specialForm: FormGroup;
	mapcols: any = [{ 'field': 'stockid', label: 'Item Number' },
		{ 'field': 'startdate', label: 'Start Date' },
		{ 'field': 'enddate', 'label': 'End Date' },
		{ 'field': 'limit', 'label': 'Qty Limit' },
		{ 'field': 'baseprice', 'label': 'Blanket Price' },
		{ 'field': 'qoh', 'label': 'Use QOH' }
	];
	mapping: any = {};
	selectedItem: any = false;
	editing = false;
	allactive: any = false;
	constructor(public printService: PrintService, private fb: FormBuilder, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private location: Location, public cdr: ChangeDetectorRef, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.globalSearchService.all_system_types.subscribe((alltypes: any) => {
			if (alltypes) {
				this.pricelvs = alltypes.salestypes;
				for (let x = 0; x < this.pricelvs.length; x++) {
					this.mapcols.push({ 'field': 'price_' + this.pricelvs[x].typeabbrev, 'label': this.pricelvs[x].sales_type })
				}
			}
		});

		this.blanket.valueChanges.subscribe( newValue => {
			if(this.prices_array.length) {
				this.prices_array.forEach(p => {
					p.price = newValue;
				})
			}
		})
	}

	selectItem(stockid: any) {
		this.inventoryService.loadItem(stockid).subscribe((results: any) => {
			this.itemdetails = results;
		});
	}


	itemSearch() {
		if(this.searchSpecials.value != '') {
			this.active = this.globalSearchService.filterItem(this.allactive,this.searchSpecials.value,'stockid,description')
		} else {
			this.active = this.allactive;
		}
	}

	addSpecial() {
		this.itemdetails = false;
		this.editing = false;
		this.prices_array = [];
		this.pricelvs.forEach(x => this.addList(x, 0));

		this.specialForm = this.fb.group({
			specialid: [''],
			stockid: ['', Validators.required],
			startdate: ['', Validators.required],
			enddate: ['', Validators.required],
			qtylimit: [0, Validators.required],
			qtysold: [0],
			andor: ['2'],
			clearsold: [0],
		});

		this.setValiditiy();

		this.autocompleteFilter();
		this.modalService.open(this.specEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.itemdetails = false;
		});
	}

	autocompleteFilter() {

		if(this.imsearch) {
			this.imsearch.unsubscribe()
		}

		const data = {
			stockid: this.specialForm.get('stockid').value
		};

		this.imsearch = this.inventoryService.itemSearch(data).subscribe(results => {

			const no_zero = results.filter( r => {
				return parseFloat(r.qty) > 0
			})
			this.allitems = no_zero;
			this.filteredOptions = no_zero;
		});

	}
	setValiditiy() {

		switch(this.specialForm.get('andor').value) {
		case '1':
			this.specialForm.get('startdate').setValidators([Validators.required])
			this.specialForm.get('startdate').updateValueAndValidity();

			this.specialForm.get('enddate').setValidators([Validators.required])
			this.specialForm.get('enddate').updateValueAndValidity();

			break;
		default:
			this.specialForm.get('startdate').setValidators([])
			this.specialForm.get('startdate').updateValueAndValidity();

			this.specialForm.get('enddate').setValidators([])
			this.specialForm.get('enddate').updateValueAndValidity();
			break;
		}
	}
	addList(x: any, y: any) {

		if (!y) {
			y = 0;
		}

		const price = {
			typeabbrev: x.typeabbrev,
			sales_type: x.sales_type,
			price: y,
		}

		this.prices_array.push(price);

	}

	setForm() {

	}

	validate() {
		let proc = true;

		if(this.specialForm.get('andor').value == '1') {
			if(parseFloat(this.specialForm.get('qtylimit').value) > 0 ) {

			} else {
				alert('Qty Can not be zero while using QTY & Date');
				proc = false;
			}
		}

		return proc;
	}

	exportSpecials() {
		for (const val in this.active) {
			const line = this.active[val];
			for (const [key,value] of Object.entries(line.prices)) {
				this.active[val][key] = value
			}
		}

		this.globalSearchService.exportJsonToExcel(this.active,'Specials.xlsx');
	}

	editSpecial(spec: any) {
		this.editing = true;
		this.prices_array = [];
		this.inventoryService.getItemSpecial(spec).subscribe((results: any) => {
			this.selectItem(results.stockid);

			this.pricelvs.forEach(x => this.addList(x, results.prices[x.typeabbrev]));

			this.specialForm = this.fb.group({
				specialid: [results.specialid],
				stockid: [results.stockid, Validators.required],
				startdate: [results.startdate, Validators.required],
				enddate: [results.enddate, Validators.required],
				qtylimit: [results.qtylimit, Validators.required],
				qtysold: [results.qtysold],
				clearsold: [0],
				andor: [results.andor],
			});
			this.setValiditiy();
			this.autocompleteFilter();
			//this.original_prices_array = results.prices;
			this.modalService.open(this.specEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});
		});
	}

	specialByStockID() {


		const data = { stockid: this.specialForm.get('stockid').value };
		this.inventoryService.getStockItemSpecial(data).subscribe((results: any) => {
			if (results) {
				this.prices_array = [];

				this.pricelvs.forEach(x => this.addList(x, results.prices[x.typeabbrev]));

				this.specialForm = this.fb.group({
					specialid: [results.specialid],
					stockid: [results.stockid, Validators.required],
					startdate: [results.startdate, Validators.required],
					enddate: [results.enddate, Validators.required],
					qtylimit: [results.qtylimit, Validators.required],
					qtysold: [results.qtysold],
					clearsold: [0],
					andor: ['2'],
				});
				this.setValiditiy();
			}
		});

	}
	saveSpecial() {

		//let data = this.specialForm.value;
		if(this.specialForm.valid && this.validate()) {
			const data = {
				specialid: this.specialForm.get('specialid').value,
				stockid: this.specialForm.get('stockid').value,
				startdate: this.specialForm.get('startdate').value,
				enddate: this.specialForm.get('enddate').value,
				qtylimit: this.specialForm.get('qtylimit').value,
				clearsold: this.specialForm.get('clearsold').value,
				andor: this.specialForm.get('andor').value,
				prices: this.prices_array
			};

			this.inventoryService.saveItemSpecial(data).subscribe((results: any) => {
				this.editing = false;
				this.active = results.active;
				this.allactive= results.active;
				this.inactive = results.inactive;
				this.modalService.dismissAll();
			});
		}
	}

	removeSpecial(data: any) {
		this.inventoryService.removeItemSpecial(data).subscribe((results: any) => {
			this.active = results.active;
			this.allactive= results.active;
			this.inactive = results.inactive;
			this.modalService.dismissAll();
		});
	}

	uploadSpecial() {
		this.modalService.open(this.specUpload, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	sendSpecials() {
		const data = { tabledata: this.uploaddata, mapping: this.mapping };
		this.inventoryService.sendItemSpecials(data).subscribe((results: any) => {
			this.active = results.active;
			this.allactive= results.active;
			this.inactive = results.inactive;
			this.modalService.dismissAll();
		});
	}
	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}
	onSelect(event: any) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.inventoryService.uploadSpecials(upload).subscribe((results: any) => {
					this.uploaddata = results;

					this.files = [];
				});

			});
		}
	}
	ngAfterViewInit() {
		this.inventoryService.getSpecialsData().subscribe((results: any) => {
			this.active = results.active;
			this.allactive= results.active;
			this.inactive = results.inactive;
		});
	}

	back(): void {
		this.location.back()
	}

	filterItem(input: any, term: any, keys: any) {
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-customer-aging',
	templateUrl: './customer-aging.component.html',
	styleUrls: ['./customer-aging.component.scss']
})
export class CustomerAgingComponent implements OnInit {
	@Input() aged;

	@Output() columnzero = new EventEmitter < boolean > ();
	@Output() columnone = new EventEmitter < boolean > ();
	@Output() columntwo = new EventEmitter < boolean > ();
	@Output() columnthree = new EventEmitter < boolean > ();
	@Output() columnfour = new EventEmitter < boolean > ();
	@Output() columnfive = new EventEmitter < boolean > ();
	@Output() columnsix = new EventEmitter < boolean > ();

	constructor() {}

	ngOnInit(): void {}


	emitZero() {
		this.columnzero.emit(true);
	}

	emitOne() {
		this.columnone.emit(true);
	}
	emitTwo() {
		this.columntwo.emit(true);
	}
	emitThree() {
		this.columnthree.emit(true);
	}
	emitFour() {
		this.columnfour.emit(true);
	}
	emitFive() {
		this.columnfour.emit(true);
	}
	emitSix() {
		this.columnfour.emit(true);
	}

}

import { Component, OnInit, Input,OnChanges, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
	selector: 'app-inventory-export',
	templateUrl: './inventory-export.component.html',
	styleUrls: ['./inventory-export.component.scss']
})
export class InventoryExportComponent implements OnInit {
	@Input() data: any = false;
	filename = 'inventory_export_all_items';
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	sending: any = false;
	config: any = [];
	user: any = false;
	tabledata: any = [];
	rawdata: any = false;
	headercolumns: string[] = [
		'stockid',
		'categoryid',
		'lineid',
		'description',
		'materialcost',
		'discontinued',
		'barcode',
		'image',
		'enabled',
		'dwo',
		'sort',
		'sort2',
		'date_created',
	];

	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
				if(u.user.issalesman) {
					this.editsallowed = r.salesmanAllowed
				}
			})
		});
	}

	ngOnInit(): void {
		this.tabledata = new MatTableDataSource(this.data);
		this.tabledata.sort = this.sort;
		setTimeout(() => this.tabledata.paginator = this.paginator);
	}

	setData() {
		//allItems Set in Dashboard component
		this.globalSearchService.allItems.subscribe(async(results:any) => {
			if(results && results.length) {
				this.tabledata = new MatTableDataSource(results);
				this.tabledata.sort = this.sort;
				setTimeout(() => this.tabledata.paginator = this.paginator);
			}
		})
	}


	exportPdf() {

		const encoded: string = btoa(this.buildHtmlForExport());
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename,
			title: 'All Item',
			subtitle: ' created '+today.toLocaleString(),
		}


		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		const encoded: string = btoa(this.buildHtmlForExport());
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename,
			title: 'All Item',
			subtitle: ' created '+today.toLocaleString(),
		}
		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	buildHtmlForExport() {
		let html = '';
		if(this.data) {
			html = '<table class="table table-fixed table-sm" #print_table>';
			html += '<thead>';
			html += '<tr>';
			html += '<th>Item#</th>';
			html += '<th>Category</th>';
			html += '<th>ProductLine</th>';
			html += '<th>Description</th>';
			html += '<th>Material Cost</th>';
			html += '<th>Discontinued</th>';
			html += '<th>Barcode</th>';
			html += '<th>Image</th>';
			html += '<th>Enabled</th>';
			html += '<th>DWO</th>';
			html += '<th>Sort</th>';
			html += '<th>Sort2</th>';
			html += '<th>Created</th>';
			html += '</tr>';
			html += '</thead>';
			html += '<tbody>';

			this.data.forEach( (d:any) => {
				html += '<tr *ngFor="let d of data" class="donotshow">'
				html += '<td>'
				html += d.stockid
				html += '</td>'
				html += '<td>' +d.categoryid+'</td>'
				html += '<td>' +d.lineid+'</td>'
				html += '<td>' +d.description+'</td>'
				html += '<td>' +d.materialcost+'</td>'
				html += '<td>' +d.discontinued+'</td>'
				html += '<td>' +d.barcode+'</td>'
				html += '<td>' +d.image+'</td>'
				html += '<td>' +d.enabled+'</td>'
				html += '<td>' +d.dwo+'</td>'
				html += '<td>' +d.sort+'</td>'
				html += '<td>' +d.sort2+'</td>'
				html += '<td>' +d.date_created+'</td>'
				html += '</tr>'
			});

			html += '</tbody>'
			html += '</table>'
		}
		return html;
	}

	ngOnChanges(changes: any) {
		if(changes.data) {
			if(changes.data.currentValue && changes.data.currentValue.length) {
				this.globalSearchService.allItems.next(changes.data.currentValue);
				this.setData()
			}
		}
	}
}

import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Location } from '@angular/common'
import { OmsService } from '../../services/oms.service';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { OrdersService } from '../../services/orders.service';
import { Order } from '../../classes/orders';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as XLSX from 'xlsx';
import * as jQuery from 'jquery';


@Component({
	selector: 'app-orders-dashboard',
	templateUrl: './orders-dashboard.component.html',
	styleUrls: ['./orders-dashboard.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class OrdersDashboardComponent implements OnInit {

	picking: any = [];
	adrs: any = [];
	flip = 'inactive';
	header: any;
	results: any;
	fliptitle: any;
	billable: any;
	viewing_title = '';
	viewing_data: any = false;
	picklateone = [];
	picklatetwo = [];
	picklatethree = [];

	notfillableone = [];
	notfillabletwo = [];
	notfillablethree = [];
	credits: any = [];

	allorders;
	allone = [];
	alltwo = [];
	allthree = [];
	returns = [];
	refused: any = [];
	notready: any;
	status: any;
	carddata: any;
	data: any;
	ready: any;


	fileName;
	public type: string;
	public error;
	color: any = 'blue'
	config: any = [];

	quotes: any = [];
	overdues: any = [];
	overdue1: any = [];
	overdue2: any = [];
	overdue3: any = [];

	overdues_length: any;
	overdue1_length: any;
	overdue2_length: any;
	overdue3_length: any;

	constructor(private route: ActivatedRoute, private globalSearchService: GlobalSearchService, private ordersService: OrdersService, private omsService: OmsService, public cdr: ChangeDetectorRef, private location: Location, public router: Router, private formBuilder: UntypedFormBuilder) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

	}



	ngOnInit(): void {
		this.error = '';

		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToCards((err, data) => {
				if(data) {
					this.loadData()
				}
			});
		}



		this.route.params.subscribe(params => {
			if (params['type']) {
				this.toggleFlip(params['type'], 'inactive')
			}
		});


		this.loadData()
	}

	randomString(length, chars) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

	loadData() {

		this.ordersService.getPicking().subscribe((results: any) => {
			this.picking = results;
			this.globalSearchService.pickingOrderData.next(results);
		});

		this.ordersService.getRefused().subscribe((results: any) => {
			this.refused = results;
		});

		this.ordersService.getADRSCount().subscribe((results: any) => {
			this.adrs = results;
		});

		this.ordersService.getOpenQuotes().subscribe((results: any) => {
			this.quotes = results;
		});

		this.globalSearchService.pickingOrderData.subscribe((results: any) => {
			this.picking = results;

			if (this.picking) {

				this.allorders = [...this.picking.notfillable, ...this.picking.fillable, ...this.picking.partialpicked]

				this.allone = this.getFilteredLates(this.allorders, 5, 3)
				this.alltwo = this.getFilteredLates(this.allorders, 7, 5)
				this.allthree = this.getFilteredLates(this.allorders, 30, 7)
				this.billable = this.getFilteredLates(this.picking.billable, 5, 3)
				this.notfillableone = this.getFilteredLates(this.picking.notfillable, 5, 3)
				this.notfillabletwo = this.getFilteredLates(this.picking.notfillable, 7, 5)
				this.notfillablethree = this.getFilteredLates(this.picking.notfillable, 30, 7)
				this.picklateone = this.getFilteredLates(this.picking.fillable, 5, 3)
				this.picklatetwo = this.getFilteredLates(this.picking.fillable, 7, 5)
				this.picklatethree = this.getFilteredLatesForced(this.picking.fillable, 90, 7);
				this.returns = this.getReturns(this.picking.fillable);
				this.credits = results.opencredits;

				this.overdues = this.getOverdues(this.allorders);
				this.overdue1 = this.getOverdues(this.allorders, 1, 5);
				this.overdue2 = this.getOverdues(this.allorders, 5, 10);
				this.overdue3 = this.getOverdues(this.allorders, 10, 60);




			}
		});
	}

	getReturns(datalist: any) {

		const result = datalist.filter((item: any) => {
			return (item.PICK < 0);

		});

		return result;

	}

	getFilteredLatesForced(datalist, fromdays: number, todays: number) {

		const result = datalist.filter((item: any) => {

			const subject = new Date(item.DATE_ORDERED);
			subject.setDate(subject.getDate());

			const before = new Date('1999-01-01');
			before.setDate(before.getDate());

			const until = new Date();
			until.setDate(until.getDate() - todays);
			return (subject >= before && subject <= until);

		});

		return result;

	}

	getFilteredLates(datalist, fromdays: number, todays: number) {

		const result = datalist.filter((item: any) => {
			const subject = new Date(item.DATE_ORDERED);

			subject.setDate(subject.getDate());

			const before = new Date();
			before.setDate(before.getDate() - fromdays);

			const until = new Date();
			until.setDate(until.getDate() - todays);

			return (subject >= before && subject <= until);

		});

		return result;

	}

	getOverdues(datalist, fromdays?:number, todays?:number){
		const result = datalist.filter((item: any) => {

			let date_used = '';

			if(item.DATE_WANTED != undefined){
				var wanted = new Date(item.DATE_WANTED);
				date_used = 'DATE_WANTED';
			}else{
				var wanted = new Date(item.DATE_ORDERED);
				date_used = 'DATE_ORDERED';
			}

			wanted.setDate(wanted.getDate() + 1); //whyyy are you a day behind? >:(


			//ignoring time of order placement, only care about date.
			const now = new Date();
			now.setMinutes(0);
			now.setHours(0);
			now.setSeconds(0);


			if(now > wanted){

				const over_by = now.getTime() - wanted.getTime();
				const days_overdue = Math.ceil(over_by / (1000 * 3600 * 24));

				if(!isNaN(fromdays + todays)){
					if(days_overdue >= fromdays && days_overdue <= todays){
						return item;
					}
				}else{ //all overdues
					if(days_overdue >= 1){
						return item;
					}
				}
			}
		});


		switch(todays){
		case 5:
			this.overdue1_length = new Set(result.map((i) => {
				return i.ORDER_NUMBER
			})).size;
			break;
		case 10:
			this.overdue2_length = new Set(result.map((i) => {
				return i.ORDER_NUMBER
			})).size;
			break;
		case 60:
			this.overdue3_length = new Set(result.map((i) => {
				return i.ORDER_NUMBER
			})).size;
			break;
		default:
			this.overdues_length = new Set(result.map((i) => {
				return i.ORDER_NUMBER
			})).size;
			break;
		}


		//this sorts but there is a secondary sort overriding somewhere later on.. leaving for now incase of odering by dates is requested
		const sorted = result.sort(
			(objA, objB) => Number( new Date(objA.DATE_WANTED != '' ? objA.DATE_WANTED : objA.DATE_ORDERED).getTime() ) - Number( new Date(objB.DATE_WANTED != '' ? objB.DATE_WANTED : objB.DATE_ORDERED).getTime()),
		);

		return sorted;
	}

	addDays(date: Date, days: number): Date {
		date.setDate(date.getDate() + days);
		return date;
	}

	getHeader() {
		return this.header
	}

	getData() {
		return this.results
	}

	toggleFlipTest(viewing: any) {

		this.viewing_data = viewing.data
		this.viewing_title = viewing.title
		//this.flip = (this.flip == 'active') ? 'inactive':'active';
	}

	toggleFlip(dataset, setvalue, forcedata = false, list = []) {
		if (setvalue != '') {
			this.flip = setvalue;
		}
		///this is a bad idea. needs fixed
		switch (dataset) {
		case 'na':
			break;
		case 'refused':
			this.fliptitle = 'Refused Delivery';
			this.data = this.refused
			break;
		case 'quotes':
			this.fliptitle = 'Open Quotes';
			this.data = this.quotes
			break;
		case 'billable':
			this.fliptitle = 'Needs Invoices';

			this.data = this.picking.billable
			this.type = 'billable';
			break;
		case 'inpick':
			this.fliptitle = 'In Route Builder';
			this.data = this.picking.inpick
			this.type = 'inpick';
			break;
		case 'snoozed':
			this.fliptitle = 'Snoozed';
			this.type = 'snoozed';
			this.data = this.picking.snoozed
			break;
		case 'returns':
			this.fliptitle = 'Return to Bin';
			this.type = 'returns';
			this.data = this.returns
			break;
		case 'notfillablethree':
			this.fliptitle = '7+ Not Fillable Orders';
			this.type = 'notfillablethree';
			this.data = this.notfillablethree;
			break;
		case 'notfillabletwo':
			this.fliptitle = '5-7 Not Fillable Orders';
			this.type = 'notfillabletwo';
			this.data = this.notfillabletwo;
			break;
		case 'notfillableone':
			this.fliptitle = '3+ Not Fillable Orders';
			this.type = 'notfillableone';
			this.data = this.notfillableone;
			break;
		case 'allthree':
			this.fliptitle = 'All 7+ Orders';
			this.type = 'allthree';
			this.data = this.allthree;
			break;
		case 'alltwo':
			this.fliptitle = 'All 5-7 Orders';
			this.type = 'alltwo';
			this.data = this.alltwo;
			break;
		case 'allone':
			this.fliptitle = 'All 3-5 Orders';
			this.type = 'allone';
			this.data = this.allone;
			break;
		case 'late':
			this.fliptitle = '7+ Late Orders';
			this.type = 'late';
			this.data = this.picklatethree;
			break;
		case 'adrs':
			this.router.navigateByUrl('orders/adrs');
			break;
		case 'lateone':
			this.fliptitle = '3-5 Orders';
			this.type = 'latetwo';
			this.data = this.picklateone;
			break;
		case 'latetwo':
			this.fliptitle = '5-7 Orders';
			this.type = 'latetwo';
			this.data = this.picklatetwo;
			break;
		case 'partialpicked':
			this.fliptitle = 'Parital Picks';
			this.type = 'partialpicked';
			this.data = this.picking.partialpicked;
			break;
		case 'dispatched':
			this.fliptitle = 'Dispatched';
			this.type = 'dispatched';
			this.data = this.picking.dispatched;
			break;
		case 'picked':
			this.fliptitle = 'Picked Orders';
			this.type = 'picked';
			this.data = this.picking.picked;
			break;
		case 'parital':
			this.fliptitle = 'Partially Fillable Orders';
			this.type = 'parital';
			this.data = this.picking.parital;
			break;
		case 'notfillablecount':
			this.fliptitle = 'Non Fillable Orders';
			this.type = 'notfillablecount';
			this.data = this.picking.notfillable;
			break;
		case 'fillable':
			this.fliptitle = 'Fillable Orders';
			this.type = 'fillable';
			this.data = this.picking.fillable;
			break;
		case 'credits':
			this.fliptitle = 'Open Credits';
			this.type = 'credits';
			this.data = this.credits;
			break;
		case 'openorders':
			this.fliptitle = 'Open Orders';
			this.type = 'openorders';
			this.data = [...this.picking.notfillable, ...this.picking.fillable, ...this.picking.partialpicked];
			break;
		case 'overdues':
			this.fliptitle = 'All Overdue Orders';
			this.type = 'overdues';
			this.data = this.overdues;
			break;
		case 'overdue1':
			this.fliptitle = '1-5 Overdue Orders';
			this.type = 'overdue1';
			this.data = this.overdue1;
			break;
		case 'overdue2':
			this.fliptitle = '5-10 Overdue Orders';
			this.type = 'overdue2';
			this.data = this.overdue2;
			break;
		case 'overdue3':
			this.fliptitle = '>10 Overdue Orders';
			this.type = 'overdue3';
			this.data = this.overdue3;
			break;
		default:
			this.fliptitle = dataset;

			if (forcedata) {
				this.data = list;
			} else {
				this.data = this.picking[dataset];
			}

			this.type = 'late';

			break;
		}
		switch (this.flip) {
		case 'inactive':
			this.flip = 'active';
			this.parseData(this.data);
			//this.updateData();
			//this.focusInput();
			break;
		default:

			this.flip = 'inactive';
			break;
		}

	}

	selectItemLookup(event: any) {
		this.router.navigate(['./orders/entry/'+event.header.debtorno+'/'+event.header.branchcode]);
		//http://localhost:4200/#/orders/entry/107/00
	}

	invoiceOrder(event: any) {
		this.router.navigate(['orders/invoice/'+event+'/dispatch']);
	}

	parseData(listin) {
		const newheader = [];
		(Object.keys(Object.values(listin)[0])).forEach((keys: any, vals: any) => {
			if (keys) {
				if (keys != 'sort') {
					newheader.push({ title: keys, data: keys })
				}
			}
		});
		const newvalues = []
		listin.forEach((keys: any, vals: any) => {
			newvalues.push(keys);
		});



		this.header = newheader;
		this.results = newvalues;

	}

	back(): void {
		this.location.back()
	}

	focusInput() {

	}

	exportExcel(): void {
		/* table id is passed over here */
		const element = document.getElementById('excel-table');
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		/* save to file */
		XLSX.writeFile(wb, this.fileName);

	}
}

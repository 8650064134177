import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { InventoryService } from '../../../services/inventory.service';

@Component({
	selector: 'app-inventory-edit-setup',
	templateUrl: './inventory-edit-setup.component.html',
	styleUrls: ['./inventory-edit-setup.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0',width: '0', overflow: 'hidden' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 , overflow: 'hidden' })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class InventoryEditSetupComponent implements OnInit {
	@Input() item;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newitemdata = new EventEmitter<any>();
	itemForm: any = false;
	properties: any = [];
	formEditAllowed = [
		'stockid',
		'barcode',
		'categoryid',
		'taxcatid',
		'lineid',
		'description',
		'longdescription',
		'controlled',
		'decimalplaces',
		'discontinued',
		'mbflag',
		'glclass',
		'units',
		'dwo',
		'enabled',
		'manufacturer_id',
		'winter',
		'designation'
	]
	config: any = false;
	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService, private globalSearchService:GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	onSubmit() {
		if(this.itemForm.valid) {
			this.inventoryService.updateItem(this.itemForm.value).subscribe( (result: any) => {
				this.newitemdata.emit(result);
			})
		}
	}

	ngOnInit(): void {

		this.globalsService.getItemSetup().subscribe( (results: any) => {
			this.properties = results
		});
		const details = this.item
		const controls = [];
		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
		Object.keys(details).forEach(key => {
			if(this.formEditAllowed.includes(key)) {
				const value = (details[key]) ? details[key] : '';
				controls.push({ name: key, control: new UntypedFormControl(value, []) })
			}
		});
		controls.forEach(f => {
			formGroup.addControl(f.name, f.control, {})
		});
		this.itemForm = formGroup;
	}
}

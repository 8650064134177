<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Vendors</h4>
						<p class="card-category text-right">Vendor Information</p>
					</div>
					<div class="card-body">
						<div class="table table-sm ">
							<div class="table table-responsive text-center" *ngIf="!vendors">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
							<div class="row">
								<div class="col-2 mt-3" >
									<a [routerLink]="'create'"  mat-stroked-button color="danger">New Vendor</a>
								</div>
								<div class="col-2" [formGroup]="searchForm" *ngIf="user.user.user_group == 1">
									<mat-slide-toggle formControlName="disabled" class="mt-3">Include Disabled?</mat-slide-toggle>
								</div>
								<div class="col-8">

									<mat-form-field class="full-width" appearance="outline">
										<mat-label>Search</mat-label>
										<input #vendorsearch class="text-right" matInput  (input)="filterVendors($event)">
										<span matSuffix>
										<button mat-icon-button >
											<mat-icon>search</mat-icon>
										</button>
										</span>
									</mat-form-field>
								</div>
							</div>
							<mat-table [dataSource]="vendors" matSort (matSortChange)="announceSortChange($event)" >
								<ng-container matColumnDef="actions">
									<mat-header-cell class="d-none d-md-inline" *matHeaderCellDef ></mat-header-cell>
									<mat-cell class="d-none d-md-inline" *matCellDef="let vendor">
										<a [routerLink]="vendor.supplierid" mat-stroked-button class="mt-1">View</a></mat-cell>
								</ng-container>

								<ng-container matColumnDef="supplierid">
									<mat-header-cell  *matHeaderCellDef mat-sort-header >
										Vendor Id
									</mat-header-cell>
									<mat-cell *matCellDef="let vendor"> {{vendor.supplierid}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="suppname">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
									<mat-cell *matCellDef="let vendor" class="mt-1"> {{ vendor.suppname}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="address1">
									<mat-header-cell class="" *matHeaderCellDef mat-sort-header> Address</mat-header-cell>
									<mat-cell class=" " *matCellDef="let vendor" [innerHTML]= "vendor.address1"></mat-cell>
								</ng-container>
								<ng-container matColumnDef="telephone">
									<mat-header-cell class="" *matHeaderCellDef mat-sort-header> Phone#</mat-header-cell>
									<mat-cell class="" *matCellDef="let vendor" [innerHTML]= "vendor.telephone"></mat-cell>
								</ng-container>
								<ng-container matColumnDef="disabled">
									<mat-header-cell class="" *matHeaderCellDef mat-sort-header> Disabled</mat-header-cell>
									<mat-cell class="" *matCellDef="let vendor" [innerHTML]= "vendor.disabled"></mat-cell>
								</ng-container>
								<mat-header-row  *matHeaderRowDef="headercolumns; sticky: true;"></mat-header-row>
								<mat-row *matRowDef="let row; columns: headercolumns;" (click)="selectVendor(row)"></mat-row>


							</mat-table>
							<mat-paginator [pageSizeOptions]="[25, 50, 100]"
											showFirstLastButtons
											aria-label="select page">
							</mat-paginator>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

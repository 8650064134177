import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appNumericField]'
})
export class NumericFieldDirective {


	acceptedCharacters: string[] = ['-','.', '', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'ArrowRight','ArrowLeft','Shift','Meta','c','v','a'];

	constructor() { }

	@HostListener('keydown', ['$event'])
	onKeyDown(event: any) {

		if (!this.acceptedCharacters.includes(event.key)) {
			event.preventDefault();
		}
	}
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit, Directive, HostListener } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription, Observable } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { CustomerService } from '../../services/customer.service';
import { VendorService } from '../../services/vendor.service';
import { PrintService } from '../../services/print.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as Chartist from 'chartist';

@Component({
	selector: 'app-vendor-reports',
	templateUrl: './vendor-reports.component.html',
	styleUrls: ['./vendor-reports.component.scss']
})
export class VendorReportsComponent implements OnInit {
	@Input() vendordata;
	@Input() pos: any = false;
	@Input() menuapp: any = true;
	displayCustomerLookup: any = false;
	private sort: MatSort;
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;
	@ViewChild('print_history') print_historyRef: ElementRef;
	@ViewChild('print_items') print_itemsRef: ElementRef;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	config: any = [];
	results: any = false;
	filtered_results: any = [];
	all_results: any = [];
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	search = new UntypedFormControl('');
	hideshowunits = new UntypedFormControl(true);
	hideshowsales = new UntypedFormControl(true);
	hideshowcost = new UntypedFormControl(false);
	consolidated = new UntypedFormControl(true);
	brands = new UntypedFormControl('');
	defaultlocation = new UntypedFormControl('');
	ordersource = new UntypedFormControl('');
	subsToView = new UntypedFormControl([2]);

	categoryid = new UntypedFormControl('');
	lineid = new UntypedFormControl('');

	locations: any = false;

	user: any = false;
	ref_location : any = false;
	loactions: any = false;
	todate = new Date();
	fromdate = new Date();
	today = new Date();
	sending = false;
	searchsubscripiton: any = false;
	displaydetails: any = false;
	monthdetails: any = false;
	monthtitle: any = '';
	cdetLink: any = '';
	filename = 'Customer Item History';

	properties: any = [];
	displayLookup = true;
	color = 'blue';
	sources: any = [];
	viewableSubs: any = [
		{ viewValue: 'Sales', value: 1 },
		{ viewValue: 'Units', value: 2 }
	]

	dataSource: MatTableDataSource<any>;
	  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	  dataObs: Observable<any>;

	 items_per_page = [25, 50, 100, 500];

	  constructor(private vendorService: VendorService ,private _changeDetectorRef: ChangeDetectorRef,private activatedRoute:ActivatedRoute, public printService: PrintService, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private location: Location, private globalsService: GlobalsService, private modalService: NgbModal) {
	  	this.globalSearchService.configsubscription.subscribe(r => {
	  		this.config = r;
	  		this.cdetLink = r.apiServer.baseUrl + r.apiServer.cdetLink

	  		if(this.config.env.package == 'beauty'){
	  			this.sources = [
	  				{ viewValue: 'BACK OFFICE', value: 1 },
	  				{ viewValue: 'POS', value: 2 },
	  				{ viewValue: 'SALES PERSON', value: 3 },
	  				{ viewValue: 'WEB', value: 4 }
	  			];
	  		} else{
	  			this.sources = [
	  				{ viewValue: 'BACK OFFICE', value: 1 },
	  				{ viewValue: 'AMI', value: 2 },
	  				{ viewValue: 'WEB', value: 4 }
	  			];

	  		}
	  	})

	  	this.color = this.globalSearchService.getColor();

	  	this.globalsService.getItemSetup().subscribe((results: any) => {
	  		this.properties = results
	  	});

	  	this.globalSearchService.user.subscribe(results => {
	  		//only run if user is definied
	  		if (this.user) {
	  			if (results.user.defaultlocation.loccode != this.ref_location) {
	  				this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
	  				this.ref_location = false;
	  			}
	  		}

	  		this.user = results
	  		if (!this.ref_location) {
	  			this.ref_location = this.user.user.defaultlocation.loccode;
	  		}
	  	});
	  }

	  ngOnInit(): void {
	  	this.config = this.globalsService.getApiConfig();
	  	this.setPagination([]);
	  	const today = new Date();
	  	const priorDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
	  	this.fromdate = priorDate;

	  	this.fromdateCtrl.setValue(this.fromdate);
	  	this.todateCtrl.setValue(this.todate);

	  	this.globalsService.getLocations().subscribe(async (results: any) => {
	  		this.locations = results;
	  		const allloc =[];
	  		this.locations.forEach(r => {
	  			allloc.push(r.loccode)
	  		});

	  		if (results) {
	  			const val = [
	  				this.user.user.defaultlocation.loccode
	  			];
	  			this.defaultlocation.setValue(allloc)

	  		}
	  	});
	  }

	  loadData() {

	  	if(this.vendordata){

	  		const data = {
	  			debtorno: this.vendordata.debtorno,
	  			search: this.search.value,
	  			from: this.fromdateCtrl.value,
	  			to: this.todateCtrl.value,
	  			consolidated: this.consolidated.value,
	  			location: this.defaultlocation.value,
	  			brands: this.brands.value,
	  			categories: this.categoryid.value,
	  			pline: this.lineid.value,
	  			sources: this.ordersource.value,
	  			supplierid: this.vendordata.supplierid

	  		}

	  		this.sending = true;

	  		if (this.searchsubscripiton) {
	  			this.searchsubscripiton.unsubscribe();
	  		}

	  		//forms being used twice force update back
	  		this.defaultlocation.setValue(this.defaultlocation.value);
	  		this.brands.setValue(this.brands.value);
	  		this.categoryid.setValue(this.categoryid.value);
	  		this.lineid.setValue(this.lineid.value);
	  		this.ordersource.setValue(this.ordersource.value);

	  		this.searchsubscripiton = this.vendorService.searchVendorHistory(data).subscribe(async (results: any) => {

	  			if(results) {
	  				this.results = results;
	  				this.filtered_results = results;
	  				this.all_results = results;
	  				this.sending = false;
	  				if(results.length) {
	  					this.items_per_page = [25, 50, 100, 500, results.length];
	  				}
	  				this.setPagination(results);
	  			}
	  		});
	  	}
	  }

	  viewHistory(month: any, year: any) {

	  	const data = {
	  		debtorno: this.vendordata.debtorno,
	  		search: this.search.value,
	  		from: this.fromdateCtrl.value,
	  		to: this.todateCtrl.value,
	  		month: month,
	  		year: year,
	  		location: this.defaultlocation.value,
	  		brands: this.brands.value,
	  		categories: this.categoryid.value,
	  		pline: this.lineid.value,
	  		sources: this.ordersource.value
	  	}

	  	this.customerService.searchCustomerHistory(data).subscribe((results: any) => {
	  		this.monthdetails = results.items;
	  		this.monthtitle = results.title;
	  		this.modalService.open(this.monthDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	  	});
	  }

	  getCostTotalYear(year: any) {
	  	const value = year.value.cost.reduce(function(accumulator: number, items: any) {
	  		const addon = parseFloat(items.value);
	  		return accumulator + addon;
	  	}, 0);

	  	return value;
	  }

	  getSaleTotalYear(year: any) {
	  	const value = year.value.sales.reduce(function(accumulator: number, items: any) {
	  		const addon = parseFloat(items.value);
	  		return accumulator + addon;
	  	}, 0);

	  	return value;
	  }

	  getQtyTotalYear(year: any) {
	  	const value = year.value.qty.reduce(function(accumulator: number, items: any) {
	  		const addon = parseFloat(items.value);
	  		return accumulator + addon;
	  	}, 0);

	  	return value;
	  }

	  openDocument(transaction: any, content: any, display: string) {
	  	switch (transaction.type) {
	  	case '11':
	  		this.globalsService.getCreditNote(transaction.id, display).subscribe((r: any) => {
	  			this.displaydetails = r;
	  			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	  		})
	  		break;
	  	case '10':
	  		this.globalsService.getInvoice(transaction.id, display).subscribe((r: any) => {
	  			this.displaydetails = r;
	  			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	  		})

	  		break;
	  	}
	  }

	  creditInvoice(trans: any) {
	  	this.modalService.dismissAll();
	  	this.router.navigate(['/receivables/credit-invoice/' + trans.id]);
	  }

	  exportMPdf() {
	  	this.sending = true;
	  	const encoded: string = this.globalSearchService.base64encode(this.print_historyRef.nativeElement.innerHTML);
	  	const today = new Date();
	  	const data = {
	  		content: encoded,
	  		filename: this.filename,
	  		title: this.vendordata.debtorno+'.'+this.vendordata.branchcode+' ' +this.vendordata.customer.brname+' Monthly History',
	  		subtitle: ' Locations:'+this.defaultlocation.value.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
	  	}

	  	this.printService.pdf(data).subscribe((result: any) => {
	  		this.globalSearchService.downloadPdf(result.content, data.filename);
	  		this.sending = false;
	  	});
	  }

	  exportMXls() {
	  	this.sending = true;

	  	const encoded: string = this.globalSearchService.base64encode(this.print_historyRef.nativeElement.innerHTML);
	  	const today = new Date();
	  	const data = {
	  		content: encoded,
	  		filename: this.filename,
	  		title: this.vendordata.debtorno+'.'+this.vendordata.branchcode+' ' +this.vendordata.customer.brname+' Monthly History',
	  		subtitle: ' Locations:'+this.defaultlocation.value.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
	  	}

	  	this.printService.xls(data).subscribe((result: any) => {
	  		this.globalSearchService.downloadXls(result.content, data.filename);
	  		this.sending = false;
	  	});
	  }

	  exportPdf() {
	  	this.sending = true;
	  	const encoded: string = this.globalSearchService.base64encode(this.print_itemsRef.nativeElement.innerHTML);
	  	const today = new Date();
	  	const data = {
	  		content: encoded,
	  		filename: this.filename,
	  		title: this.vendordata.debtorno+'.'+this.vendordata.branchcode+' ' +this.vendordata.customer.brname+' Item History',
	  		subtitle: ' Locations:'+this.defaultlocation.value.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
	  	}

	  	this.printService.pdf(data).subscribe((result: any) => {
	  		this.globalSearchService.downloadPdf(result.content, data.filename);
	  		this.sending = false;
	  	});
	  }

	  exportXls() {
	  	this.sending = true;

	  	const encoded: string = this.globalSearchService.base64encode(this.print_itemsRef.nativeElement.innerHTML);
	  	const today = new Date();
	  	const data = {
	  		content: encoded,
	  		filename: this.filename,
	  		title: this.vendordata.debtorno+'.'+this.vendordata.branchcode+' ' +this.vendordata.customer.brname+' Item History',
	  		subtitle: ' Locations:'+this.defaultlocation.value.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
	  	}

	  	this.printService.xls(data).subscribe((result: any) => {
	  		this.globalSearchService.downloadXls(result.content, data.filename);
	  		this.sending = false;
	  	});
	  }

	  assignCopy(input) {
	  	if (input != '') {
	  		this.filtered_results = Object.assign([], input);
	  	} else {
	  		this.filtered_results = this.all_results
	  	}
	  }

	  filterWarehouse(input:any) {
	  	this.loadData();
	  }

	  filterItem(input, term, keys) {
	  	if (!term) {
	  		this.assignCopy(input);
	  	}
	  	const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
	  	return result
	  }

	  selectCustomer(event: any){
	  	this.vendordata = event;
	  	this.displayLookup = false;
	  	this.router.navigate(['/customers/itemHistory', event.debtorno]);
	  	this.loadData();
	  }

	  showCustomerLookup(){
	  	this.displayLookup == true ? this.displayLookup = false : this.displayLookup = true;
	  }

	  getItemTotals() {
	  	let total = 0
	  	if(this.results) {
	  		total = this.results.reduce(function(accumulator, c) {
	  			return accumulator + parseFloat(c.qty);
	  		}, 0);
	  	}
	  	return total;
	  }

	  getLineTotal(item:any) {
	  	return this.financial(item.price * item.qty);
	  }

	  getDollarTotals() {
	  	let total = 0
	  	if(this.results) {
	  		total = this.results.reduce(function(accumulator, c) {
	  			return accumulator + parseFloat(c.discountedlinetotal);
	  		}, 0);
	  	}
	  	return total;
	  }

	  setPagination(tableData) {
	  	this.dataSource = new MatTableDataSource<any>(tableData);
	  	this._changeDetectorRef.detectChanges();
	  	this.dataSource.paginator = this.paginator;
	  	this.dataObs = this.dataSource.connect();
	  }

	  ngOnChanges(changes:any) {

	  	if(changes.vendordata) {
	  		this.loadData();
	  	}
	  }

	  financial(num: number): number {
	  	const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
	  	return parseFloat(rounded.toFixed(2));
	  }
}

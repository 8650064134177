<div class="row m-0 p-0">
	<div class="col-md-6">
		<div class="card">
			<h1 class="card-header text-center">
				<span *ngIf="getBalance() < 0 && change_due < 0" class="text-success">
					CHANGE DUE {{ getBalance() | abs | currency }}
				</span>
				<span *ngIf="getBalance() < 0 && change_due >= 0" class="text-danger">
					OVER PAYMENT {{ getBalance() | abs | currency }}
				</span>
				<span *ngIf="getBalance() > 0" class="text-danger">
					DUE {{ getBalance() | abs | currency }}
				</span>
			</h1>
			<div class="card-body">
				<table class="ml-auto mr-0 table table-totals w-100" *ngIf="totals.length">
					<tbody>
						<tr *ngFor="let t of totals">
							<td class="text-left dotted"><b>{{ t.title }}:</b></td>
							<td class="text-right dotted">{{ t.text | currency }}</td>
						</tr>
						<tr>
							<th class="border-bottom"><b>PAYMENTS</b></th>
							<th class="border-bottom"></th>
						</tr>
						<tr *ngIf="!payments.length">
							<td class="border-bottom"></td>
							<td class="border-bottom text-right"><b>NO PAYMENTS</b></td>
						</tr>
						<tr *ngFor="let pay of payments">
							<td class="text-right dotted">
								<button mat-icon-button color="accent" class="mt-4 ml-1" (click)="removePayment(pay.payment_id)">
									<mat-icon color="accent">cancel</mat-icon>
								</button>
								<b>{{ pay.payment_type }}</b>
								<span *ngIf="pay.options" class="d-inline">{{ lastFour(pay.options.number) }}</span>
							</td>
							<td class="text-right dotted">{{ pay.amount | currency }}</td>
						</tr>
					</tbody>
				</table>
				<div class="item-box">
					<table class="ml-auto mr-0 table table-totals w-100 table-sticky">
						<tbody>
							<tr>
								<th class="normal-semilarge">ITEMS</th>
								<th class="normal-semilarge text-right">QTY@PRICE</th>
							</tr>
							<tr *ngFor="let i of items">
								<td class="text-left dotted normal"><b>{{ i.description }}:</b></td>
								<td class="text-right dotted normal">{{ i.dispatch }}&nbsp;@&nbsp;{{ i.discountedprice | currency }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<ng-container *ngIf="paymentForm">
			<form [formGroup]="paymentForm" class="payment-form text-dark" (ngSubmit)="addPayment()">
				<div class="row text-right text-dark m-0 p-0 border">
					<div class="text-right d-none" *ngIf="bankaccounts">
						<mat-form-field appearance="outline">
							<mat-label>Bank Account</mat-label>
							<mat-select formControlName="bankaccount">
								<mat-option *ngFor="let method of bankaccounts" [value]="method.accountcode">
									{{ method.bankaccountname }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="ml-auto mr-auto col-12 g-primary">
						<mat-button-toggle-group #charge="matButtonToggleGroup" aria-label="Charge" (change)="setChargeType($event)" formControlName="type" color="accent">
							<ng-container *ngFor="let method of paymentmethods.slice(0, 6)">
								<mat-button-toggle [value]="method.paymentname" [ngClass]="method.paymentname === charge.value ? 'text-light btn-success btn-strong-text' : 'text-dark btn-light btn-strong-text'" *ngIf="(pos && method.pos_enabled) || !pos">{{ method.paymentname }}</mat-button-toggle>
							</ng-container>
						</mat-button-toggle-group>
					</div>

					<div class="ml-auto mr-auto col-12 g-primary" *ngIf="paymentmethods.length > 6">
						<mat-button-toggle-group #charge="matButtonToggleGroup" aria-label="Charge" (change)="setChargeType($event)" formControlName="type" color="accent">
							<ng-container *ngFor="let method of paymentmethods.slice(6)">
								<mat-button-toggle [value]="method.paymentname" [ngClass]="method.paymentname === charge.value ? 'text-light btn-success btn-strong-text' : 'text-dark btn-light btn-strong-text'" *ngIf="(pos && method.pos_enabled) || !pos">{{ method.paymentname }}</mat-button-toggle>
							</ng-container>
						</mat-button-toggle-group>
					</div>
					<div class="text-center" *ngIf="preselected_payment && loadingcards">
						<h5 class="text-danger">Pre Selected Payment Loading Profile</h5>
						<span>
							<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</span>
					</div>
					<div class="rainbowboard mt-0 mb-0">
						<mat-form-field appearance="outline" class="amount-text">
							<mat-label>Amount</mat-label>
							<input type="text" #amountId class="text-center mt-0 pt-0 amount-text" placeholder="Amount" aria-label="Amount" formControlName="amount" (keydown.enter)="runKey('add')" matInput appSelectOnFocus appNumericField />
						</mat-form-field>
						<div class="rainbowboard-inner">
							<div class="key" *ngFor="let key of [7, 8, 9]" (click)="runKey(key)">
								<div class="rainbow-content">{{ key }}</div>
							</div>
						</div>
						<div class="rainbowboard-inner">
							<div class="key" *ngFor="let key of [4, 5, 6]" (click)="runKey(key)">
								<div class="rainbow-content">{{ key }}</div>
							</div>
						</div>
						<div class="rainbowboard-inner">
							<div class="key" *ngFor="let key of [1, 2, 3]" (click)="runKey(key)">
								<div class="rainbow-content">{{ key }}</div>
							</div>
						</div>
						<div class="rainbowboard-inner">
							<div class="key" (click)="runKey('0')">
								<div class="rainbow-content">0</div>
							</div>
							<div class="key" (click)="runKey('.')">
								<div class="rainbow-content">.</div>
							</div>
							<div class="key" (click)="runKey('backspace')">
								<div class="rainbow-content">
									<mat-icon>keyboard_backspace</mat-icon>
								</div>
							</div>
							<div class="key" (click)="runKey('minus')" *ngIf="getBalance() < 0">
								<div class="rainbow-content minus">
									<span *ngIf="!isNegative()">-</span>
									<span *ngIf="isNegative()">+</span>
								</div>
							</div>
							<div class="key" (click)="runKey('add')">
								<div class="rainbow-content add">ADD</div>
							</div>
						</div>
					</div>
					<div class="text-center" *ngIf="card_required && !pos">
						<span *ngIf="loadingcards">
							<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</span>
						<mat-form-field appearance="outline" *ngIf="profile_cards.length">
							<mat-label>Charge Credit Card</mat-label>
							<mat-select formControlName="card_selected" (selectionChange)="changeCard($event.value)">
								<mat-option [value]="false">DO NOT CHARGE</mat-option>
								<mat-option *ngFor="let method of profile_cards" [value]="method" [ngClass]="preselected_payment.card_id === method.card_id ? 'text-danger' : ''">
									{{ method.number }} {{ method.expiry_month }}/{{ method.expiry_year }}
									<span *ngIf="preselected_payment.card_id === method.card_id">Requested</span>
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
		</ng-container>
	</div>
</div>

import { Injectable } from '@angular/core';
import { catchError , retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpClientModule,HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { HttpParams } from '@angular/common/http';


@Injectable({
	providedIn: 'root'
})

export class ReportsService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';
	color: any = 'blue';
	constructor(private http: HttpClient,private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe( (result: any) => {
			this.user = result;
		});
	}
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getReports() {
		const method = 'reports';
		return this.http.get(this.setEndPoint(method));
	}

	public getReport(file, type) {
		const method = 'reports/report&file=' + file + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getDaySales() {
		const method = 'reports/days';
		return this.http.get(this.setEndPoint(method));
	}

	public getHourSales() {
		const method = 'reports/day';
		return this.http.get(this.setEndPoint(method));
	}


	public loadGapCustomers() {
		const method = 'reports/getgapcustomers';
		return this.http.get(this.setEndPoint(method));
	}

	public loadGapBrands() {
		const method = 'reports/getgapbrands';
		return this.http.get(this.setEndPoint(method));
	}

	public buildGapBrands() {
		const method = 'reports/buildgapbrands';
		return this.http.get(this.setEndPoint(method));
	}

	public loadGapBrandCategories(mfg) {
		const method = 'reports/getgapbrandcategories&mfg=' +mfg;
		return this.http.get(this.setEndPoint(method));
	}

	public loadGapBrandTopProds(mfg) {
		const method = 'reports/getgapbrandtopprods&mfg=' +mfg;
		return this.http.get(this.setEndPoint(method));
	}


	public runReport(variables) {
		const params = new HttpParams();


		return this.http.get(this.runReportURL() + variables);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}


	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private setTelegramEndPoint(method: any){
		return this.getBaseUrl() +'?route=' + method + this.getApiKey();
	}

	private getBaseUrl(){
		return this.config.apiServer.baseUrl;
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}


	public getTelegramReports(){
		const method = 'telegram/report/list';
		return this.http.get(this.setTelegramEndPoint(method))
	}

	public runTelegramReport(data){
		const method = 'telegram/report/' + data;
		return this.http.get(this.setTelegramEndPoint(method));
	}

	public getReportData(file, type) {
		const method = 'reports/report&file=' + file + '&type=' + type;
		return this.http.get(this.getApi() + method);
	}

	public getJpms(data: any) {
		const method = 'reports/jpms';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getPmm(data: any) {
		const method = 'reports/pmm';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getJpmsPerks(data: any) {
		const method = 'reports/jpms/perks';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}



	public getCRPT(data: any) {
		const method = 'reports/crpt';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCRPTDrill(data: any) {
		const method = 'reports/crptdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCSRPT(data: any) {
		const method = 'reports/csrpt';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCSRPTDrill(data: any) {
		const method = 'reports/csrptdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getSRPT(data: any) {
		const method = 'reports/srpt';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getSRPTDrill(data: any) {
		const method = 'reports/srptdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getSPSLS(data: any) {
		const method = 'reports/spsls';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getSPSLSDrill(data: any) {
		const method = 'reports/spslsdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	public getVRPT(data: any) {
		const method = 'reports/vrpt';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getVRPTDrill(data: any) {
		const method = 'reports/vrptdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCPSLS(data: any) {
		const method = 'reports/cpsls';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCPSLSDrill(data: any) {
		const method = 'reports/cpslsdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCPDET(data: any) {
		const method = 'reports/cpdet';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCPDETDrill(data: any) {
		const method = 'reports/cpdetdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCVSLS(data: any) {
		const method = 'reports/cvsls';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getCVSLSDrill(data: any) {
		const method = 'reports/cvslsdrill';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}
	public getBranches(){
		const method = 'reports/branches';
		return this.http.get(this.setEndPoint(method));
	}

	public getSalesPeople(){
		const method = 'reports/salespeople';
		return this.http.get(this.setEndPoint(method));
	}

	private runReportURL() {
		return this.config.apiServer.reportEndpoint;
	}
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}
}

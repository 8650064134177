<ng-container>
	<form @grow [formGroup]="customerForm" (ngSubmit)="onSubmit()" class="needs-validation product-add">
		<div class="card-header">
			<div class="col-12 text-right">
				<button class="ml-auto mr-0" mat-button mat-raised-button [color]="customerForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>

			</div>
		</div>

		<div class="row">
			<ng-container *ngIf="config && config.env.package == 'beauty'">
				<div class="col-md-12 text-center">
					<mat-slide-toggle formControlName="signed_mor">Signed Contract</mat-slide-toggle>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>License</mat-label>
						<input matInput formControlName="cosm_license">
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Expiration</mat-label>
						<input matInput formControlName="licExpDate" [matDatepicker]="licExpDatedatepicker">
						<mat-datepicker-toggle matSuffix [for]="licExpDatedatepicker"></mat-datepicker-toggle>
						<mat-datepicker #licExpDatedatepicker></mat-datepicker>
					</mat-form-field>
				</div>
			</ng-container>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Name</mat-label>
					<input matInput required value="" formControlName="name">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Address 1</mat-label>
					<input matInput required value="" formControlName="address1">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Address 2</mat-label>
					<input matInput value="" formControlName="address2">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>City</mat-label>
					<input matInput required value="" formControlName="address3">
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="">
					<mat-label>Region</mat-label>
					<mat-select formControlName="address4">
						<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
							{{zone.code }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>Postal</mat-label>
					<input matInput required value="" formControlName="address5">
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Country</mat-label>
					<mat-select formControlName="address6">
						<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
							{{ zone.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>




		</div>

		<ng-container *ngIf="user.user.customeradmin == '1'">
			<div class="col-12 text-center">
				<button mat-raised-button (click)="removeCustomer()">
					Delete Customer
				</button>
			</div>
		</ng-container>
		<!--
		<div class="card-body"  @grow *ngIf="customerForm">
			<div class="row card-deck">
				<div class="col-md-12 card-group">
					<div class="card">
							<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="outline">
				 						<mat-label>Name</mat-label>
				 						<input matInput required value="" formControlName="name">
				 					</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
								<mat-form-field appearance="outline">
			 						<mat-label>Address 1</mat-label>
			 						<input matInput required value="" formControlName="address1" >
		 						</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
								<mat-form-field appearance="outline">
			 						<mat-label>Address 2</mat-label>
			 						<input matInput value="" formControlName="address2">
		 						</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
								<mat-form-field appearance="outline">
		 						<mat-label>City</mat-label>
		 						<input matInput required value="" formControlName="address3">
		 						</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline" class="ml-2 mr-2">
									<mat-label>Region</mat-label>
									<mat-select formControlName="address4">
									<mat-option *ngFor="let zone of zones.zones"
									[value]="zone.code">
									{{zone.code }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline">
			 					<mat-label>Postal</mat-label>
			 					<input matInput required value="" formControlName="address5">
			 					</mat-form-field>
							</div>
							<div class="col-md-12">
								<mat-form-field appearance="outline" class="ml-2 mr-2">
									<mat-label>Country</mat-label>
									<mat-select formControlName="address6" >
									<mat-option *ngFor="let zone of zones.countries"
									[value]="zone.name">
									{{ zone.name }} </mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 mt-2 card-group">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-3">
									<mat-form-field appearance="outline">
								 	<mat-label>Credit Limit</mat-label>
								 	<input matInput required value="" formControlName="creditlimit">
								 	</mat-form-field>
								</div>
								<div class="col-3">
									<mat-form-field appearance="outline">
									<mat-label>Sale Discount</mat-label>
										<input matInput value="0" formControlName="discount">
									</mat-form-field>
								</div>
								<div class="col-3">
									<mat-form-field appearance="outline">
										<mat-label>Payment Discount</mat-label>
										<input matInput value="0" formControlName="pymtdiscount">
									</mat-form-field>
								</div>
								<div class="col-3">
									<mat-form-field appearance="outline">
										<mat-label>Interest</mat-label>
										<input matInput value="0" formControlName="arintrate">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<mat-form-field appearance="outline" class="ml-2 mr-2" >
										<mat-label>Payment Terms</mat-label>
										<mat-select formControlName="paymentterms">
											<mat-option *ngFor="let va of terms"
											[value]="va?.termsindicator">
											{{va.terms}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-6">
									<mat-form-field appearance="outline" class="ml-2 mr-2" >
										<mat-label>Price List</mat-label>
										<mat-select formControlName="salestype">
											<mat-option *ngFor="let tpe of salestypes"
											[value]="tpe?.typeabbrev">
											{{tpe.sales_type}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

							</div>
							<div class="row">
								<div class="col-4">
									<mat-form-field appearance="outline" class="ml-2 mr-2" >
										<mat-label>Customer Type</mat-label>
										<mat-select formControlName="typeid">
											<mat-option *ngFor="let tpe of customertypes"
											[value]="tpe?.typeid">
											{{tpe.typename}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
									<mat-form-field appearance="outline" class="ml-2 mr-2" >
										<mat-label>Credit Status</mat-label>
										<mat-select formControlName="holdreason">
											<mat-option *ngFor="let va of holdreasons"
											[value]="va?.reasoncode">
											{{va.reasondescription}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
									<mat-form-field appearance="outline" class="ml-2 mr-2" >
										<mat-label>Currency</mat-label>
										<mat-select formControlName="currcode">
											<mat-option *ngFor="let currency of currencies" [value]="currency?.currabrev">
												{{currency.currency}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-md-4">
									<mat-checkbox formControlName="emailstatement">Email Statement</mat-checkbox>

									<mat-form-field appearance="outline">
									 	<mat-label>Statement Email Address</mat-label>
									 	<input matInput value="" formControlName="statementemailaddress">
								 	</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-checkbox formControlName="ediinvoices">Email Invoices</mat-checkbox>
									<mat-form-field appearance="outline">
									 	<mat-label>Invoice Email Address</mat-label>
									 	<input matInput value="" formControlName="invoiceemailaddress">
								 	</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-checkbox formControlName="customerpoline">Require PO#</mat-checkbox>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		-->
	</form>
</ng-container>

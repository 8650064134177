<h4>Cards On File</h4>

<button class="btn btn-sm" (click)="toggleAddCard()" >
	<i class="fa fa-plus"></i>&nbsp;&nbsp;New Card
</button>

<span *ngIf="loadingcards">
	<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
</span>
<ng-container *ngIf="addingcard">
	<app-credit-card-form [customer]="customer" [type]="'orderentry'" (updatePayments)="loadProfile()"></app-credit-card-form>
</ng-container>
<ng-container *ngIf="!addingcard">
	<ul class="list-group">
		<li class="list-group-item" *ngIf="profile_cards && !profile_cards.length && !loadingcards">No Cards On File</li>
		<div class="list-group-item text-left" *ngFor="let c of profile_cards">
			<button mat-icon-button color="accent" class="btn btn-danger btn-sm maxwidthbutton p-0 m-0" (click)="removeCard(c)" >
				<mat-icon>remove</mat-icon>
			</button>
			{{ c.card_type }} {{ c.number }} {{ c.expiry_month }}/{{ c.expiry_year }}
		</div>
	</ul>
</ng-container>

<form [formGroup]="itemForm" (ngSubmit)="updateItem()" *ngIf="itemForm">
  <div class="row">
	<div class="col-12">
	  <div class="card border">
		<div class="card-body">
		  <div class="card-title text-right mb-3">
			<button mat-raised-button color="accent">Next</button>
		  </div>

		  <!-- Brand and Name Fields -->
		  <div class="row mb-3">
			<div class="col-md-6">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Brand</mat-label>
				<mat-select required formControlName="brand">
				  <mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">
					{{ k.name }}
				  </mat-option>
				</mat-select>
			  </mat-form-field>
			</div>
			<div class="col-md-6">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Name</mat-label>
				<input matInput required formControlName="name">
			  </mat-form-field>
			</div>
		  </div>

		  <!-- Meta Title and Meta Description Fields -->
		  <div class="row mb-3">
			<div class="col-md-6">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Meta Title</mat-label>
				<input matInput formControlName="meta_title">
			  </mat-form-field>
			</div>
			<div class="col-md-6">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Meta Description</mat-label>
				<input matInput formControlName="meta_description">
			  </mat-form-field>
			</div>
		  </div>

		  <!-- Description and Features Editors -->
		  <div class="row mb-3">
			<div class="col-md-6">
			  <label>Description</label>
			  <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
			</div>
			<div class="col-md-6">
			  <label>Features</label>
			  <angular-editor formControlName="features" [config]="editorConfig"></angular-editor>
			</div>
		  </div>

		  <!-- Benefits and Ingredients Editors -->
		  <div class="row mb-3">
			<div class="col-md-6">
			  <label>Benefits</label>
			  <angular-editor formControlName="benefits" [config]="editorConfig"></angular-editor>
			</div>
			<div class="col-md-6" *ngIf="config.env.package == 'beauty'">
			  <label>Ingredients</label>
			  <angular-editor formControlName="ingredients" [config]="editorConfig"></angular-editor>
			</div>
		  </div>

		  <!-- Product Image Upload -->
		  <div class="col-12 mt-3">
			<div class="card border">
			  <div class="card-header card-header-info card-header-icon">
				<div class="card-icon">
				  <i class="material-icons">add_a_photo</i>
				</div>
				<h4 class="card-title bold">Product Image</h4>
			  </div>
			  <div class="card-body mb-4">
				<ng-container *ngIf="inputdata.image">
				  <img [src]="inputdata.image" class="img img-responsive mb-3">
				</ng-container>
				<ngx-dropzone (change)="onSelect($event)">
				  <ngx-dropzone-label>Drop file, or click to upload!</ngx-dropzone-label>
				  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
					<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
				  </ngx-dropzone-preview>
				</ngx-dropzone>
			  </div>
			</div>
		  </div>

		</div>
	  </div>
	</div>
  </div>
</form>

<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="item_details.descriptions.name"></h3>
						<section class="section-small" [innerHTML]="toHTML(item_details.descriptions.description)"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="toHTML(item_details.descriptions.features)"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="toHTML(item_details.descriptions.benefits)"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="outline" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{color}}-6">
				<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Query </h4>
				<p class="card-category">Scan or Type</p>
			</div>
			<div class="card-body">
				<div class="digital-add needs-validation">
							<div class="row">
								<div class="col-md-2 mb-0 mt-4">
									<mat-slide-toggle [formControl]="include_disabled">Include Discontinued</mat-slide-toggle>
								</div>

								<div class="col-md-2">
									<mat-form-field appearance="outline">
										<mat-label>Category</mat-label>
										<mat-select [formControl]="categoryControl" multiple="true">
											<!--<mat-option [value]="''">All</mat-option> -->
											<mat-option *ngFor="let c of categories" [value]="c.categoryid">
												{{ c.categorydescription }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field appearance="outline">
										<mat-label>Product Line</mat-label>
										<mat-select [formControl]="productLineControl" multiple="true">
											<mat-option *ngFor="let p of productlines" [value]="p.line_field">
												{{ p.line_description }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="outline" class="smaller-font-form notop text-right" color="accent">
										<mat-spinner matPrefix diameter="30" *ngIf="searching"></mat-spinner>
										<span matPrefix *ngIf="allitems.length && !searching">
											<button mat-icon-button class="p-0" (click)="clearForm()">
												<mat-icon class="p-0">clear</mat-icon>
											</button>
										</span>
										<input #itemSearch matInput [formControl]="itemSearchControl" placeholder="Search By PartNo/Description..." autofocus="true" class="w-100 m-0 p-0 notop text-right" (input)="searchItems()" (keyup.enter)="searchItems()">
										<span matSuffix>
											<button mat-icon-button color="white" (click)="searchItems()">
												<mat-icon class="p-0">search</mat-icon>
											</button>
										</span>
									</mat-form-field>
								</div>
							</div>
						</div>
				<div class="col-md-12" id="payrep" *ngIf="!running" >
					<ng-container>
						<mat-paginator
						  [pageSizeOptions]="items_per_page"
						  [pageSize]="pagesizedefault"
						></mat-paginator>

						<div  class="table" >
						<div class="bg-white mt-2 rounded">


							<div id="receipts" >
								<!-- <h4 class="mt-2 mb-4 noprint">{{ title }} {{ getLocationName() }}</h4> -->
								<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed" *ngIf="allitems && allitems.length">
									<thead>
										<tr>
											<th class="text-center"></th>
											<th class="text-left">Item#</th>
											<th class="text-left">Category</th>
											<th class="text-left">Line</th>
											<th class="text-left">Item</th>
											<th class="text-center">QOH</th>
											<th class="text-center">Price</th>

											<!-- <ng-container *ngFor="let p of salestypes">
												<th class="text-right" *ngIf="p.typeabbrev != 'O' && p.typeabbrev !='M'">{{ p.typeabbrev }}: {{ p.sales_type }}</th>
											</ng-container> -->
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let item of dataObs | async">
										<tr>
											<td class="text-center"><img src="{{item.thumb}}" class="img img-fluid"></td>
											<td class="text-left" >
												<!-- <a [routerLink]="'/inventory/view/'+item.stockid">{{ item.stockid }}</a> -->
												<a href="#" (click)="$event.preventDefault();openModal(itemdetails, item)">{{ item.stockid }}</a>
											</td>
											<td class="text-left">{{ item.categoryid }}</td>
											<td class="text-left">{{ item.lineid }}</td>
											<td class="text-left">{{ item.description }}</td>
											<td class="text-center"> {{ item.qty }} </td>
											<td class="text-center"> {{ item.price }} </td>

											<!-- <td class="text-right"  *ngFor="let z of item.allprices">
												{{z.price | currency }}
											</td> -->
										</tr>
										</ng-container>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					</ng-container>
					<ng-container *ngIf="allitems && !allitems.length">
						<h4 class="text-center">No Items</h4>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
